import { Typography } from "@mui/material";
import { LocationOn, ContentCut } from "@mui/icons-material";

export const renderDetailedItem = (fields, event) => {
  return (
    <>
      {fields.map((field, i) => {
        return (
          <div>
            <Typography
              key={`loc-${event.event_id}`}
              className="d-flex align-items-center"
              color="textSecondary"
              variant="caption"
              noWrap
            >
              <LocationOn />
              <span className="pl-2">
                {event.location?.location_name || ""}
              </span>
            </Typography>
            <Typography
              key={`services-${event.event_id}`}
              className="d-flex align-items-center"
              color="textPrimary"
              variant="caption"
              noWrap
            >
              <ContentCut className="p-1" />
              <div>
                {event.uniqueServices.map((service, i) => (
                  <div key={`${service.service_id}${i}`} className="pl-2 bold">
                    {service.title}
                  </div>
                ))}
              </div>
            </Typography>
          </div>
        );
      })}
    </>
  );
};
