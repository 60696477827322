import React, { useEffect, useRef } from "react";
import "./Manufacturers.scss";
import TreeList, {
  Column,
  ColumnChooser,
  HeaderFilter,
  SearchPanel,
  SpeedDialAction,
  Lookup,
  Paging,
  Pager,
  Scrolling,
} from "devextreme-react/tree-list";
import CustomStore from "devextreme/data/custom_store";
import { baseAxios } from "../../utils/config";

const expandedKeys = [2];
const selectedKeys = [1];

// const api = "gateway/kywewrh4ti";
const api = "gateway/m4vjjp9nh5";

const store = new CustomStore({
  load: function () {
    return baseAxios.post(api).then((result) => {
      return result?.data?.Items;
    });
  },

  update: function (key, values) {},
});

function addDollarSign(data) {
  var cost = data.value;
  if (cost == "undefined" || cost == undefined || cost < 0.01) {
    cost = 0.0;
  }
  return `$${cost}`;
}

export default () => {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Product Catalogue</h2>
      <p className="content-block">List of recognized products.</p>
      <TreeList
        //dataSource = {products.getProducts()}
        dataSource={store}
        showBorders={true}
        columnAutoWidth={true}
        wordWrapEnabled={true}
        defaultExpandedRowKeys={expandedKeys}
        defaultSelectedRowKeys={selectedKeys}
        rootValue={"-1"}
        keyExpr={"product_id"}
        parentIdExpr={"parent_id"}
        id="products"
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Scrolling mode={"standard"} />
        <Paging enabled={true} defaultPageSize={40} />
        <SearchPanel visible={true} width={250} />
        <HeaderFilter visible={true} />
        {/* <Selection mode='multiple'/> */}
        <ColumnChooser enabled={false} />

        <Column dataField={"manufacturer_name"} fixed={true} />
        <Column dataField={"brand_name"} fixed={true} />
        <Column dataField={"category_name"} fixed={true} />
        <Column dataField={"product_type_name"} caption={"Type"} />
        <Column dataField={"product_name"} caption={"Product Name"} />
        <Column dataField={"process_name"} caption={"Process"} />
        <Column
          dataField={"product_container_description"}
          caption={"Container Description"}
        />
        <Column
          dataField={"product_container_size"}
          caption={"Container Size"}
        />
        <Column
          dataField={"product_cost"}
          caption={"Product Cost"}
          customizeText={addDollarSign}
        />
        <Column dataField={"product_waste_ratio"} caption={"Waste ratio"} />
        <Column
          dataField={"product_upc_code"}
          caption={"UPC"}
          alignment={"right"}
        />
        <Column
          dataField={"product_unit_sale_only"}
          caption={"Unit Sale Only"}
        />
      </TreeList>
    </React.Fragment>
  );
};
