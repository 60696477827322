import { useState, useEffect } from "react";
import { Chart, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  registerables
} from 'chart.js';
import "./bar5.style.scss";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ...registerables
);

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  borderRadius: 10,
};

export default function Bar5({ onClick, totalSpent, averageSpent }) {
  const [data, setData] = useState({ datasets: [] });
  const [options, setOptions] = useState(defaultOptions);
  const comp = [12, 1.2, 1.5];
  const delta = [12, 2, 3];
  const multiplier = [1, 6, 3];

  useEffect(() => {
    const diff = Array(3).fill(0).map((_, i) =>
      Math.abs(totalSpent[i] - averageSpent[i]) < comp[i] ? delta[i] * Math.abs(totalSpent[i] - averageSpent[i]) / (totalSpent[i] - averageSpent[i]) : 0
    );
    const newData = {
      labels: ["TOTAL SPENT", "AVERAGE $$", "# TRANSACTIONS"],
      datasets: [
        {
          type: 'bar',
          barPercentage: 1.25,
          categorySpacing: 0,
          data: [totalSpent[0] * multiplier[0], (totalSpent[1] * multiplier[1]).toFixed(2), (totalSpent[2] * multiplier[2]).toFixed(2)],
          fill: false,
          backgroundColor: ["#0dcae7", "#6ee60b", "#ed0f7e"],
        },
        {
          type: 'line',
          barPercentage: 1.25,
          categorySpacing: 0,
          data: [(averageSpent[0] - diff[0]) * multiplier[0], ((averageSpent[1] - diff[1]) * multiplier[1]).toFixed(2), ((averageSpent[2] - diff[2]) * multiplier[2]).toFixed(2)],
          fill: false,
          backgroundColor: ["#0dcae700", "#6ee60b00", "#ed0f7e00"],
          borderColor: '#00000000',
        },
      ],
    };

    const newOptions = {
      ...defaultOptions,
      plugins: {
        title: {
          display: true,
          text: 'Total Cost Per Transaction',
          position: 'left',
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          callbacks: {
            label: function (data) {
              if (data.datasetIndex === 0) {
                const dataIndex = data.dataIndex;
                const value = data.formattedValue;

                if (dataIndex !== 2) {
                  return `$${value}`
                } else {
                  return `${value}`
                }
              } else {
                return "";
              }
            },
          },
        },
        datalabels: {
          display: true,
          color: "black",
          formatter: (value, context) => {
            var width = context.chart.chartArea.width;
            var count = Math.round(width / 26);
            if (context.datasetIndex === 0) {
              return `${context.dataIndex !== 2 ? "$" : ""}${value % 1 === 0 ? (value / multiplier[context.dataIndex]) : (value / multiplier[context.dataIndex]).toFixed(2)} TOTAL`;
            } else {
              const v = newData.datasets[0]?.data[context.dataIndex] || "";
              const pad = Array(count - v.toString().length).fill("-").join("");
              return `|${pad} ${context.dataIndex !== 2 ? "$" : ""}${(value / multiplier[context.dataIndex] + diff[context.dataIndex]).toFixed(2)} AVG ${pad}|`;
            }
          },
          font: (context) => {
            var width = context.chart.width;

            return {
              weight: '600',
              size: width > 320 ? (context.datasetIndex === 1 ? "10" : '11') : (context.datasetIndex === 1 ? "8" : '10')
            };
          },
          anchor: "end",
          offset: (context) => {
            const dataIndex = context.dataIndex;
            return dataIndex !== 2 ? 0 : 0;
          },
          align: "start",
        },
      },
      scales: {
        y: {
          min: 0,
          ticks: {
            stepSize: 20,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },

      },
    };

    setData(newData);
    setOptions(newOptions);
  }, [totalSpent, averageSpent]);

  return (
    <div className="bar5-body" onClick={onClick}>
      <div className="bar5-container">
        <Chart
          data={data}
          options={options}
          type='bar'
        />
      </div>
    </div>
  );
}
