import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import Stick from "../../components/charts/stick";

export default function StylistDetailDashboard({ location }) {
  const history = useHistory();
  const cups = location.state.cups;
  const data_index = location.state.index;
  const colors = ["#ed0f7e", "#0dcae7", "#6ee60b"];

  useEffect(() => {
    const content = document.getElementsByClassName("dx-drawer-panel-content")[0];
    content.style.display = "none";
    return () => {
      content.style.display = "block";
    }
  }, []);

  return (
    <>
      <h2 className={"content-block"}>Clients</h2>
      <div className={"responsive-paddings"}>
        <Grid container>
          {
            cups && cups?.length > 0 ? cups.map((cup, index) => {
              return (
                <Grid item lg={4} md={6} xs={12} key={index}>
                  <Stick color={colors[data_index]} data={cup} onClick={() => history.push("/rolled-reports-sql")} />
                </Grid>
              )
            })
              :
              <Grid item xs={12}>
                <h5>No data</h5>
              </Grid>
          }
        </Grid>
      </div>
    </>
  );
}
