import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React, { useState } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";
import { Context } from "./utils/config";
import { Toaster } from "react-hot-toast";

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  // if(admin){
  //   return <AdminContent />;
  // }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  const [token, setToken] = useState(null);
  const [dataset, setDataset] = useState({});
  const [location, setLocation] = useState();

  return (
    <Router>
      <Context.Provider value={{ token, setToken, dataset, setDataset, location, setLocation }}>
        <AuthProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
              <Toaster/>
            </div>
          </NavigationProvider>
        </AuthProvider>
      </Context.Provider>
    </Router>
  );
}
