import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";

export default function SearchStylists(props) {
  const [dataLoaded, setDataLoaded] = useState(false);

  const history = useHistory();

  const searchStylistsApi = "gateway/igf3twd3c3";

  const { user } = useAuth();

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  const location = userCredentials.Location;

  const dataSource = useMemo(() => {
    return new CustomStore({
      load: function () {
        const requestData = {
          location: location,
        };
        return baseAxios.post(searchStylistsApi, requestData).then((result) => {
          let data = result.data;
          if (Array.isArray(data)) {
            setDataLoaded(true);
            return data;
          } else {
            console.error("Data is not an array:", data);
            return [];
          }
        });
      },
      key: ["user_id"],
    });
  }, []);

  const handleRowClick = (e) => {
    const userId = e.data.user_id;

    history.push({
      pathname: "stylist-services",
      search: `?param=${userId}`,
    });
  };

  const handleAddNewService = () => {
    history.push("create-account?role=stylist");
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: "Add New Stylist",
        onClick: handleAddNewService,
      },
    });
  };

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Manage Stylists</h2>
      <h4 className={"content-block text"}>
        Click a stylist to manage their assigned services.
      </h4>

      <DataGrid
        dataSource={dataSource}
        onToolbarPreparing={onToolbarPreparing}
        className={"dx-card wide-card"}
        showBorders={true}
        onRowClick={handleRowClick}
        focusedRowEnabled={dataLoaded}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Paging defaultPageSize={25} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <SearchPanel
          visible={true}
          highlightCaseSensitive={false}
          highlightSearchText={false}
          width="500px"
        />
        <HeaderFilter visible={true} />
        <Scrolling mode="standard" />
        <Column
          dataField="user_first_name"
          caption="First Name"
          allowSearch={true}
        />
        <Column
          dataField="user_last_name"
          caption="Last Name"
          allowSearch={true}
        />
        <Column dataField="user_email" caption="Email" allowSearch={true} />
      </DataGrid>
    </React.Fragment>
  );
}
