import React, { useCallback, useRef, useEffect, useState } from "react";
import "./Edit-A-Client.scss";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  Button as GridButton,
} from "devextreme-react/data-grid";
import { Popup, Button } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";

export default function ManageClients() {
  const history = useHistory();
  const dataRef = useRef(null);
  const { user } = useAuth();
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);
  const [clientFirstName, setClientFirstName] = useState([]);
  const [clientLastName, setClientLastName] = useState([]);
  const [clientID, setClientID] = useState([]);

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  const api = "gateway/ctfdkk8xi0"; // Uses Development API
  // const api = "gateway/bii6ihtrq4"; // Uses the live production API
  const dataSource = new DataSource({
    store: new CustomStore({
      load: function () {
        return baseAxios.post(api, userCredentials).then((result) => {
          return result?.data?.Items;
        });
      },
      key: "client_id",
    }),
  });

  const handleRowClick = (e) => {
    const formClientFirstName = e.data.client_first_name;
    const formClientLastName = e.data.client_last_name;
    const formClientID = e.data.client_id;
    setClientFirstName(formClientFirstName);
    setClientLastName(formClientLastName);
    setClientID(formClientID);
    setIsConfirmationPopupVisible(true);
  };

  const handleCancel = () => {
    setIsConfirmationPopupVisible(false);
  };

  const handleConfirm = async () => {
    history.push({
      pathname: "/edit-client-form",
      search: `?clientId=${clientID}`, // Add the query parameter
    });
    setIsConfirmationPopupVisible(false);
  };

  const onCreateClient = useCallback(async (e) => {
    history.push("/create-client");
  }, []);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Edit Clients</h2>

      <Popup
        visible={isConfirmationPopupVisible}
        onHiding={handleCancel}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit Client"
        showCloseButton={true}
        width={"30%"} // Adjust width as needed
        height={"30%"} // Adjust height as needed
      >
        <p>
          Are you sure you want to edit{" "}
          <strong>
            {clientFirstName} {clientLastName}
          </strong>
          ?
        </p>
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            text="Cancel"
            onClick={handleCancel}
            style={{ marginRight: "10px" }}
          />
          <Button text="Confirm" type="default" onClick={handleConfirm} />
        </div>
      </Popup>
      {/* <Button text={"Add New Client"} onClick={onCreateClient} /> */}
      <DataGrid
        ref={dataRef}
        dataSource={dataSource}
        className={"dx-card wide-card"}
        showBorders={true}
        onRowClick={handleRowClick}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        {/* <Editing mode="row" allowUpdating={true} allowAdding={false} allowDeleting={false}></Editing> */}
        <Scrolling mode={"standard"} />

        {/* <Column
          dataField={'location_name'}
          caption={'Salon'}
          hidingPriority={4}
        /> */}
        <Column
          dataField={"client_first_name"}
          caption={"First Name"}
          hidingPriority={9}
        />

        <Column
          dataField={"client_last_name"}
          caption={"Last Name"}
          hidingPriority={10}
        />
        {/* 
        <Column
          dataField={'role_name'}
          caption={'Role'}
          hidingPriority={6}
        /> */}

        {/* <Column
          dataField={'user_barcode'}
          caption={'Barcode'}
          hidingPriority={5}
        /> */}
        {/* 
        <Column
          dataField={'org_name'}
          caption={'Organization'}
          hidingPriority={5}
        /> */}

        <Column
          dataField={"client_mobile_phone_number"}
          caption={"Phone #"}
          hidingPriority={7}
        />

        <Column dataField={"client_email"} caption={"Email"} />
      </DataGrid>
    </React.Fragment>
  );
}
