import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  IconButton,
  TextField,
  Box,
  List,
  ListItemButton,
  ListItemText,
  CircularProgress,
  ListItem,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import { ScrollView } from "devextreme-react";
import "./autocomplete.scss";
import Button from "@mui/material/Button";

export default function AsyncAutocompleteAddClient({
  label = "Label",
  onChange,
  datasets,
  className,
  keyField,
  sortField,
  titleField,
  subtitleField,
  phoneField,
  emailField,
  disabled = false,
  value,
  selectedLocation,
  clientIdFromURL,
  pageCount = 20,
}) {
  const history = useHistory();

  const [openList, setOpenList] = useState(false);
  const [clipData, setClipData] = useState([]);
  const [showClear, setShowClear] = useState(false);
  const [endPage, setEndPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [text, setText] = useState("");
  const [sortData, setSortData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [error, setError] = useState(false);
  const listRef = useRef(null);
  const textRef = useRef(null);

  const handleScroll = (e) => {
    const scrollViewInstance = listRef.current.instance;
    // Scroll has reached the end
    if (
      scrollViewInstance.scrollHeight() ===
      scrollViewInstance.clientHeight() + e.component.scrollTop()
    ) {
      if (endPage * pageCount >= datasets.length) {
        return;
      }

      setEndPage((prev) => prev + 1);
    }


    // check if scroll has reached the top
    if (e.component.scrollTop() === 0) {
      if (startPage === 0) {
        return;
      }

      setStartPage((prev) => Math.max(1, prev - 1));
    }
  };

  // const handleCreateClient = () => {
  //   history.push("/create-client");
  // };

  const handleCreateClient = () => {

    // Check if selectedLocation is defined and has a location_id
    if (selectedLocation && selectedLocation.location_id !== undefined) {
      // Construct query parameters with the selected location's location_id
      const queryParams = new URLSearchParams({ location_id: selectedLocation.location_id }).toString();
      history.push(`/create-client?${queryParams}`);
    } else {
      // Handle the case when no location is selected
      history.push(`/create-client`)
    }
  };
  

  const handleBlur = (e) => {
    const relatedTarget = e.relatedTarget || document.activeElement;
    const autocompleteList = listRef.current?.instance.element();

    if (autocompleteList && autocompleteList.contains(relatedTarget)) {
      return;
    }

    checkValidation();
  };

  const handleChange = (e, data) => {
    if (data) {
      setText(data[titleField]);
      setSelectedData(data);
      onChange(data);
      setShowClear(true);
      setTimeout(() => {
        setOpenList(false);
      }, 100);
    } else {
      const enteredValue = e.target.value;
      setText(enteredValue);
      setShowClear(enteredValue !== "");
      setSelectedData(null);

      if (enteredValue !== "") {
        const _datasets = [...datasets].filter(data =>
          Object.values(data).some(value =>
            typeof value === 'string' &&
            value !== null &&
            value.toLowerCase() !== "<null>" &&
            value.toLowerCase().includes(enteredValue.toLowerCase())
          )
        );
        
        setSortData(_datasets);
      } else {
        refreshData();
      }
      setStartPage(0);
      setEndPage(1);
    }
  };

  const refreshData = () => {
    const _datasets = [...datasets].sort((a, b) =>
      a[sortField] > b[sortField] ? 1 : -1
    );
    setSortData(_datasets);
  };

  const resetList = () => {
    setStartPage(0);
    setEndPage(1);
    setOpenList(false);
    setError(false);
  };

  const checkValidation = () => {
    if (selectedData) {
      setStartPage(0);
      setEndPage(1);
      setError(false);
      setOpenList(false);
    } else {
      resetList();
      setError(true);
    }
  };

  const handleListItemClick = (data) => {
    handleChange({ target: { value: data[titleField] } }, data);
  };

  useEffect(() => {
    if (sortData.length > 0) {
      setClipData(
        sortData.filter(
          (data, index) =>
            index < endPage * pageCount && index >= startPage * pageCount
        )
      );
    } else {
      setClipData([]);
    }
  }, [sortData, pageCount, sortField, endPage, startPage]);

  useEffect(() => {
    refreshData();

  }, [datasets, sortField]);

  useEffect(() => {
    if (value && value[titleField]) {
      setSelectedData(value);
      setText(value[titleField]);
      setShowClear(true);
    } else {
      setSelectedData(null);
      setText("");
      setShowClear(false);
    }
  }, [value]);

  useEffect(() => {
    if (clientIdFromURL) {
      const clientObject = datasets.find(client => client[keyField].toString() === clientIdFromURL);
      if (clientObject) {
        setSelectedData(clientObject);
        setText(clientObject[titleField]);
        setShowClear(true);
      }
    }
  }, []);

  return (
    <div
      className={`async-autocomplete-container ${className} ${
        disabled ? "disabled-box" : ""
      }`}
    >
      {error && !selectedData && (
        <Typography className="error" variant="body2">
          Please select one of {label}.
        </Typography>
      )}
      <TextField
        className={`autocomplete-textfield ${selectedData ? "success" : ""}`}
        size="small"
        variant="outlined"
        label={label}
        value={text}
        error={error && !selectedData}
        inputRef={textRef}
        onFocus={() => setOpenList(true)}
        onBlur={handleBlur}
        disabled={disabled}
        onChange={handleChange}
      />
      <Box className="button-box">
        {showClear && (
          <IconButton
            className="iconbtn"
            size="small"
            aria-label="close"
            disabled={disabled}
            onClick={() => {
              setText("");
              setShowClear(false);
              resetList();
              refreshData();
              setSelectedData(null);
              onChange(null);
              textRef.current.focus();
            }}
          >
            <CloseIcon className="icon" />
          </IconButton>
        )}
        <IconButton
          className="iconbtn"
          size="small"
          aria-label="open"
          disabled={disabled}
          onClick={() => {
            setOpenList(!openList);
          }}
        >
          <KeyboardArrowDownIcon className="icon" />
        </IconButton>
      </Box>
      {openList && (
        <ScrollView
          ref={listRef}
          className="autocomplete-list"
          height={220}
          onScroll={handleScroll}
        >
          <List component="nav">
            {clipData.map((data) => {
              let isSelected = false;
              if (selectedData) {
                isSelected = data[keyField] === selectedData[keyField];
              }
              return (
                <ListItemButton
                  key={data[keyField]}
                  className="list-item"
                  selected={isSelected}
                  disabled={disabled}
                  onClick={() => handleListItemClick(data)}
                >
                  <ListItemText
                    className="item-text"
                    primary={data[titleField] || " "}
                    secondary={
                      <>
                        <div>{data[subtitleField] || ""}</div>
                        <div>{(data[emailField] !== null && data[emailField] !== undefined && data[emailField] !== "<null>") ? data[emailField] : ""}</div>
                        <div>{(data[phoneField] !== null && data[phoneField] !== undefined && data[phoneField] !== "<null>" ) ? data[phoneField] : ""}</div>
                      </>
                  }
                  />
                  {isSelected && <CheckIcon className="icon-check" />}
                </ListItemButton>
              );
            })}
            {/* {clipData.length === 0 && (
              <ListItemButton
                className="list-item"
                onClick={() => textRef.current.focus()}
              >
                <ListItemText
                  className="item-text no-data"
                  primary="No Data"
                  secondary="Please search by other keywords"
                />
              </ListItemButton>
            )} */}
            {clipData.length === 0 && (
              <ListItem className="list-item">
                <ListItemText
                  className="item-text no-data"
                  primary="No Clients Found"
                  secondary="Would you like to add a new client?"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateClient}
                >
                  Add Client
                </Button>
              </ListItem>
            )}
          </List>
        </ScrollView>
      )}
    </div>
  );
}
