import { createContext } from "react";
import axios from "axios";
import { getAccessToken } from "../api/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const baseAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

baseAxios.interceptors.request.use(
  async (request) => {
    if (!request.headers.common["Authorization"]) {
      const token = await getAccessToken();
      request.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

baseAxios.interceptors.response.use(
  (response) => response, // Directly return successful responses.
  async (error) => {
    if (error.response) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          delete baseAxios.defaults.headers["Authorization"];
          return baseAxios(originalRequest);
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          sessionStorage.removeItem("quser");
          window.location.href = "/login";
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject({
        status: error.response.status,
        data: error.response.data,
      });
    }
    return Promise.reject(error);
  }
);

export const BASE_URL = "https://d18devmarketplace.meevodev.com";
export const PAGE_SIZE = 200;
export const BASE_ENDPOINT = "https://d18devpub.meevodev.com/publicapi/v1";
export const APP_ID = "50add9b8-c523-40c5-b74c-961b6da3e45f";
export const APP_SECRET = "e6298efc-cb30-45ad-b7d6-3123e0b30b97";

export const COGNITO_POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

export const Context = createContext(null);
