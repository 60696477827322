import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

export const renderCell = (item, time) => {
  const formatTime = (date) => {
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedTime;
  };
  return (
    <div
      className={`schedule-item`}
      style={{
        padding: "0px",
        backgroundImage: `repeating-linear-gradient(
  45deg,
  #d3d3d3,
  #d3d3d3 10px,
  #FFFFFF 10px,
  #FFFFFF 20px
)`,
        display: "flex",
      }}
      data-start={formatTime(item.start)}
      data-end={formatTime(item.end)}
    >
      <div
        style={{
          width: "10px",
          minWidth: "10px",
          backgroundColor:
            item.services[0].service_color ||
            item.services[0].color ||
            item.color,
        }}
      ></div>
      <div
        style={{
          width: "100%",
        }}
      >
        {time && (
          <Typography noWrap>
            {formatTime(item.start)} - {formatTime(item.end)}
          </Typography>
        )}
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          {item?.services?.map((service, index) => (
            <div
              key={`${service.service_id}-${service.event_id}`}
              style={{
                position: "absolute",
                top: `${index === 0 ? "0" : `${service.topOffset}%`}`,
                backgroundColor: "white",
                border: "1px solid black",
                padding: "2px",
                height: `${service.size}%`,
                maxHeight: `${service.size}%`,
                width: "100%",
              }}
            >
              {index === 0 && (
                <Typography
                  sx={{
                    fontWeight: "800",
                    textTransform: "capitalize",
                    marginLeft: "2px",
                  }}
                  fontSize={12}
                  noWrap
                >
                  {item.client?.client_name || ""}
                </Typography>
              )}
              <Typography noWrap>{service.title}</Typography>
              {service.service_shortcut && (
                <Typography>{service.service_shortcut}</Typography>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
