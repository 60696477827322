import React from "react";
import "./Salon-reports.scss";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { useState, useRef, useCallback, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import DataGrid, {
  Export,
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  TotalItem,
  Summary,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "../../contexts/auth";
import { DropDownBox, Form, List, DateBox } from "devextreme-react";
import { baseAxios } from "../../utils/config";

export default function SalonReports() {
  const getLocationsAPI = `gateway/3lp5nnrkw8`; // Just get's the user's locations

  const api = `gateway/cs86dhgzsi`; //Development API
  //const api = 'gateway/qcwiey5h1j' //Production API

  //let salonList = ['Fairfax', 'Rosslyn', 'Lee Highway']
  //const [salonListDataSource, setSalonListDataSource] = useState(salonList)
  const listRef = useRef(null);
  //const [selectedSalon, setSelectedSalon] = useState(salonList[0])
  const [salon, setSalon] = useState(null);
  const [locations, setLocations] = useState([]);
  const { user } = useAuth();

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
  };

  useEffect(async () => {
    if (userCredentials.Role.toLowerCase().includes("system admin")) {
      const res = await baseAxios.post(getLocationsAPI, userCredentials);
      setLocations(res.data);
    } else {
      var users_salon = {
        location_id: user.location_id,
        location_name: user.location_name,
        organization_id: parseInt(user.org),
      };
      var locations_array = [];
      locations_array.push(users_salon);
      setLocations(locations_array);
    }
  }, []);

  var now = new Date();
  var oneWeekAgo = new Date();
  oneWeekAgo.setDate(now.getDate() - 7);

  const dropDownBoxRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const dataGridRef = useRef(null);

  // const changeDropDownBoxValue = useCallback((arg) => {
  //   setSelectedSalon(arg.addedItems[0]);
  //   dropDownBoxRef.current.instance.close()
  // }, []);

  // const onStartDateValueChanged = useCallback((e) => {
  //   setStartDate(e.value)
  // }, [])

  // const onEndDateValueChanged = useCallback((e) => {
  //   setEndDate(e.value)
  // }, [])

  // const onSalonValueChanged = useCallback((e) => {
  //   setSelectedSalon(e.value);
  // }, []);

  const onClick = useCallback(() => {
    dataGridRef.current.instance.refresh();
  });

  const gridDataSource = new CustomStore({
    load: function () {
      var startDate = startDateRef.current.instance.option("value");
      var endDate = endDateRef.current.instance.option("value");
      console.log(startDate, endDate, salon);
      let apiArguments = {
        Barcode: user.barcode,
        Role: user.role,
        Salon: salon,
        StartDate: startDate,
        EndDate: endDate,
      };
      JSON.stringify(apiArguments);
      console.log(apiArguments);
      return baseAxios.post(api, apiArguments).then((result) => {
        console.log(
          "-------------- SalonContainerUsage dataset from lambda -------------"
        );
        console.log("Salon Container Usage:", result.data);
        return result.data;
      });
    },
  });

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Salon Container Usage</h2>
      <p className="content-block">
        Total amount of product used at a given salon over specified date range,
        broken down by product.
      </p>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginBottom: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "1rem",
                }}
              >
                <label htmlFor="barcode">Salon: </label>
                <Autocomplete
                  sx={{
                    minWidth: "200px",
                    marginLeft: "0.5rem",
                  }}
                  value={salon}
                  getOptionLabel={(option) => option.location_name || ""}
                  onChange={(event, newValue) => {
                    setSalon(newValue);
                  }}
                  size="small"
                  options={locations}
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />
              </Box>
            </Box>
            <DateBox
              type="date"
              value={oneWeekAgo}
              label="Start Date"
              labelMode="static"
              ref={startDateRef}
              // onValueChanged={onStartDateValueChanged}
            />
            <label htmlFor="barcode"> to </label>
            <DateBox
              type="date"
              value={now}
              label="End Date"
              labelMode="floating"
              max={now}
              ref={endDateRef}
              //onValueChanged={onEndDateValueChanged}
            />

            <Box>
              <Button variant="contained" onClick={onClick}>
                Run Report
              </Button>
            </Box>
          </Box>

          {/* <Suspense fallback={<LoadIndicator />} > */}
          <DataGrid
            //dataSource={service.getSales()}
            dataSource={gridDataSource}
            ref={dataGridRef}
            //keyExpr="id"
            //keyExpr="transaction_id"
            className={"dx-card wide-card"}
            showBorders={true}
            //focusedRowEnabled={true}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnResizing={true}
          >
            <Paging defaultPageSize={25} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            {/* <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true}></Editing> */}
            <Scrolling mode="standard" />

            {/* <Column dataField={'company'} fixed={true} visible={true}/> */}
            <Column dataField={"location_name"} caption={"Salon"} />
            {/* <Column dataField={'user_id'} fixed={true} /> */}
            {/* <Column dataField={'transaction_start_time'} hidingPriority={5} caption={'Start'} sortOrder={'desc'} sortIndex={2}/> */}
            {/* <Column dataField={'recipe_id'} hidingPriority={0} /> */}
            {/* <Column dataField={'transaction_id'} hidingPriority={1} /> */}
            <Column
              dataField={"product_alias"}
              caption={"Product ID"}
              hidingPriority={6}
            />
            {/* <Column dataField={'transaction_start'}
            caption={'Date'} 
            dataType={'date'} 
            hidingPriority={2} 
            fixed={true} 
            sortOrder ={'desc'}
            sortIndex ={1}
            format={orderDateFormat}
            /> */}
            {/* <Column
            dataField={'transaction_end'}
            caption={'End'}
          /> */}

            {/* <Column dataField={'amount'} hidingPriority={7} caption={"Amount (g)"} dataType={'number'} alignment={'left'}>
            <format type="fixedPoint" precision={2} />
          </Column> */}
            <Column
              dataField={"container_used"}
              sortOrder={"desc"}
              alignment={"left"}
            >
              {/* <format type="fixedPoint" precision={2} /> */}
            </Column>
            {/* <Column
            dataField={'device'}
          /> */}
            <Export enabled={true} allowExportSelectedData={false} />
            {/* <Summary>
            <TotalItem
              column={'cost'}
              summaryType={"sum"}
              alignment={"left"}/>
          </Summary> */}
          </DataGrid>
          {/* </Suspense> */}
        </div>
      </div>
    </React.Fragment>
  );
}
