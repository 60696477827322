import { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./donuts.style.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultOptions = {
  cutoutPercentage: 60,
  responsive: true,
  maintainAspectRatio: false,
};

export default function Donuts({
  color,
  value,
  label,
  title,
  total,
  noValue,
  onClick,
}) {
  const [data, setData] = useState({ datasets: [] });
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    const newData = {
      labels: [title, ""],
      datasets: [
        {
          data: [value, total - value],
          backgroundColor: [color, "#000000"],
          borderWidth: 0,
        },
      ],
    };

    const newOptions = {
      ...defaultOptions,
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (data) {
              if (data.dataIndex === 1) return;

              const label = data.label;
              const value = data.dataset.data[data.dataIndex];

              if (noValue) return value + "% " + label;

              return "$" + value + " " + label;
            },
          },
        },
      },
    };

    setData(newData);
    setOptions(newOptions);
  }, [value, label, total]);

  const getPercent = (value, total) => {
    // if value / total has not remainder, don't set tofixed
    if ((value * 100) % total === 0) return ((value / total) * 100).toFixed(0);

    return ((value / total) * 100).toFixed(1);
  };

  return (
    <div className="donuts-body" onClick={onClick}>
      <div className="donuts-container">
        <div className="donuts-percent">{getPercent(value, total)}%</div>
        <Doughnut data={data} options={options} onElementsClick={onClick} />
      </div>
      <div className="donuts-label">{label}</div>
      <div className="donuts-title">{title}</div>
    </div>
  );
}
