import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Selection } from "devextreme-react/data-grid";

import { useLocation } from "react-router-dom";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import notify from "devextreme/ui/notify";

export default function StylistServices() {
  const { search } = useLocation();

  const history = useHistory();

  const queryParams = new URLSearchParams(search);
  const userId = parseInt(queryParams.get("param"), 10);

  const [stylist, setStylist] = useState();

  useEffect(() => {
    loadStylistData();
  }, []);

  const loadStylistData = async () => {
    try {
      const res = await baseAxios.get(`stylists/${userId}`);
      const data = res.data;
      setStylist(data);
    } catch (error) {
      console.error("Error fetching stylists: ", error);
    }
  };

  const dataSource = useMemo(() => {
    return new CustomStore({
      load: function () {
        return baseAxios
          .get(`/services/getUserServices/${userId}`)
          .then((data) => {
            return data;
          })
          .catch((e) => {
            notify("Error loading data.", "Error", 3000);
          });
      },
      key: ["service_id"],
    });
  }, []);

  const handleAddNewServices = () => {
    const queryParams = new URLSearchParams(search);
    const userId = parseInt(queryParams.get("param"), 10);

    history.push({
      pathname: "/add-service-to-Stylist",
      search: `?param=${userId}`,
    });
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      // 'before' positions the button before other toolbar items (to the left)
      // 'after' would place it after other items (to the right)
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: `Add new services to ${
          stylist
            ? stylist.user_first_name + " " + stylist.user_last_name
            : "stylist"
        }`,
        onClick: handleAddNewServices,
      },
    });
  };

  const handleRowClick = (e) => {
    const userId = e.data.user_id;
    const serviceId = e.data.service_id;

    history.push({
      //pathname: "/add-service-to-Stylist",
      pathname: "edit-stylist-service",
      search: `?user=${userId}&service=${serviceId}`,
    });
  };

  return (
    <React.Fragment>
      <div className="content-block">
        <h2>
          Manage Services:
          {stylist
            ? ` ${stylist.user_first_name} ${stylist.user_last_name}`
            : " stylist"}
        </h2>
        <h4 className="text">Click on a service to edit.</h4>
      </div>
      <DataGrid
        dataSource={dataSource}
        onToolbarPreparing={onToolbarPreparing}
        className={"dx-card wide-card"}
        showBorders={true}
        onRowClick={handleRowClick}
        // focusedRowEnabled={dataLoaded}
        // defaultFocusedRowIndex={0}
        noDataText={`No services have been assigned to ${
          stylist
            ? `${stylist.user_first_name} ${stylist.user_last_name}`
            : "stylist"
        }. Add new services by clicking on the “Add Services” button.`}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Paging defaultPageSize={25} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <SearchPanel
          visible={true}
          highlightCaseSensitive={false}
          highlightSearchText={false}
          width="500px"
        />
        <HeaderFilter visible={true} />
        {/* <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true}></Editing> */}
        <Scrolling mode="standard" />
        <Column
          dataField="service_name"
          caption="Service Name"
          allowSearch={true}
        />
        <Column
          dataField="service_shortcut"
          caption="Service Shortcut"
          allowSearch={true}
        />
        <Column
          dataField="description"
          caption="Description"
          allowSearch={false}
        />
        <Column
          dataField="service_status"
          caption="Service Status"
          allowSearch={false}
        />
      </DataGrid>
    </React.Fragment>
  );
}
