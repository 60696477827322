import React, { useState, useEffect, useRef, useMemo } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Selection } from "devextreme-react/data-grid";

import { useLocation } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import DataSource from "devextreme/data/data_source";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { baseAxios } from "../../utils/config";
import notify from "devextreme/ui/notify";
import { UserServiceForm } from "../../components/user-service-form/user-service-form";

export default function AddServiceToStylist(props) {
  const [dataLoaded, setDataLoaded] = useState(false);

  const [selectingServices, setSelectingServices] = useState(true);
  const [stylistInfo, setStylistInfo] = useState();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userIdParam = parseInt(queryParams.get("param"), 10);

  useEffect(() => {
    loadStylistData();
  }, []);

  const loadStylistData = async () => {
    try {
      const res = await baseAxios.get(`stylists/${userIdParam}`);
      const data = res.data;
      setStylistInfo(data);
    } catch (error) {
      console.error("Error fetching stylists: ", error);
    }
  };

  const dataSource = useMemo(
    () =>
      new DataSource({
        store: new CustomStore({
          load: function () {
            return baseAxios
              .get(`/services/getServicesToAdd/${userIdParam}`)
              .then((result) => {
                setDataLoaded(true);
                return result;
              });
          },
          key: ["service_id"],
        }),
      }),
    []
  );

  const [servicesToAdd, setServicesToAdd] = useState([]);

  const handleSelectionChanged = ({ selectedRowsData }) => {
    setServicesToAdd(selectedRowsData);
  };

  const handleSubmit = () => {
    setSelectingServices(false);
  };

  const onToolbarPreparing = (e) => {
    if (!stylistInfo || servicesToAdd?.length < 1) return;
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: `Add services to: ${stylistInfo.user_first_name} ${stylistInfo.user_last_name}`,
        onClick: handleSubmit,
      },
    });
  };

  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const dataRef = useRef(null);

  const saveAllServices = () => {
    const data = Object.entries(formData.current);
    if (data.length === servicesToAdd.length) {
      if (
        data.some(
          (x) =>
            !x[1].servicing ||
            x[1].servicing < 15 ||
            (!x[1].priceLevel &&
              (servicesToAdd.service_price_level_1 > 0 ||
                servicesToAdd.service_price_level_2 > 0 ||
                servicesToAdd.service_price_level_3 > 0 ||
                servicesToAdd.service_price_level_4 > 0 ||
                servicesToAdd.service_price_level_5 > 0))
        )
      ) {
        notify(
          "All services need to have Price level and Servicing time set.",
          "Error",
          3000
        );
        return;
      }

      setLoading(true);
      saveServices();

      setLoading(false);
    } else {
      notify("All forms need to be filled to save!", "Error", 3000);
    }
  };

  const saveServices = async () => {
    const servicesData = Object.entries(formData.current);

    const payload = servicesData.map((s) => {
      console.log(s);
      return {
        userId: userIdParam,
        serviceId: s[0],
        priceLevel: s[1].priceLevel || 1,
        servicing: s[1].servicing,
        gap: s[1].gap || 0,
        servicing2: s[1].servicing2 || 0,
      };
    });

    console.log("Payload:", payload);

    try {
      const response = await baseAxios.post(
        "/services/addServicesToStylists",
        payload
      );

      console.log(response.data);
      if (response.status === 204) {
        history.back();
      } else {
        console.error(
          "Update not successful or unknown status:",
          response.data
        );
        setLoading(false);
      }
    } catch (e) {
      console.log("An error occurred:");
      console.log(e);
    }
  };

  const goBackToServiceSelection = () => {
    setServicesToAdd([]);
    setSelectingServices(true);
  };

  const deleteService = (id) => {
    const currServices = servicesToAdd.filter((x) => x.service_id != id);
    setServicesToAdd(currServices);
    if (currServices?.length < 1) {
      setSelectingServices(true);
    }
  };

  return (
    <React.Fragment>
      {stylistInfo && (
        <div
          className={"content-block"}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <button
            hidden={selectingServices}
            onClick={goBackToServiceSelection}
            style={{
              border: "none",
              background: "transparent",
              borderRadius: "20px",
              cursor: "pointer",
              height: "40px",
              width: "40px",
              marginBlock: "auto",
              placeContent: "center",
              padding: "0px",
            }}
          >
            <i
              className="dx-icon dx-icon-arrowleft"
              style={{
                color: "#333",
                fontSize: "20px",
                padding: "0.5rem",
              }}
            />
          </button>
          <h2>
            Add Services for {stylistInfo.user_first_name}{" "}
            {stylistInfo.user_last_name}
          </h2>
        </div>
      )}
      {selectingServices ? (
        <>
          <DataGrid
            ref={dataRef}
            dataSource={dataSource}
            onToolbarPreparing={onToolbarPreparing}
            className={"dx-card wide-card"}
            showBorders={true}
            focusedRowEnabled={dataLoaded}
            onSelectionChanged={handleSelectionChanged}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnResizing={true}
          >
            <Selection
              mode="multiple"
              showCheckBoxesMode={"always"}
              selectAllMode={"page"}
              allowSelectAll={true}
            />
            <Paging defaultPageSize={25} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />

            <SearchPanel
              visible={true}
              highlightCaseSensitive={false}
              highlightSearchText={false}
              width="500px"
            />
            <HeaderFilter visible={true} />
            <Scrolling mode="standard" />
            <Column
              dataField="service_name"
              caption="Service Name"
              allowSearch={true}
            />
            <Column
              dataField="service_shortcut"
              caption="Service Shortcut"
              allowSearch={true}
            />
            <Column
              dataField="description"
              caption="Description"
              allowSearch={false}
            />
            <Column
              dataField="service_status"
              caption="Service Status"
              allowSearch={false}
            />
          </DataGrid>
        </>
      ) : (
        <React.Fragment>
          <div
            style={{
              width: "80%",
              marginLeft: "40px",
            }}
          >
            {servicesToAdd.map((service, index) => (
              <UserServiceForm
                service={service}
                index={index}
                formData={formData}
                loading={loading}
                deleteService={deleteService}
              />
              // <div
              //   key={service.service_id}
              //   className={"salon-conf dx-card"}
              //   style={{
              //     marginBottom: "30px",
              //     padding: "20px",
              //     borderRadius: "8px",
              //     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              //     backgroundColor: "white",
              //     width: "80%",
              //     margin: "auto",
              //     marginTop: index === 0 ? "20px" : "30px",
              //   }}
              // >
              //   <div className={"header"}>
              //     <div
              //       className={"title"}
              //       style={{
              //         fontSize: "24px",
              //         fontWeight: "bold",
              //         marginBottom: "10px",
              //         padding: "10px",
              //       }}
              //     >
              //       Service: {service.service_name}
              //     </div>
              //   </div>
              //   <form>
              //     <Form
              //       ref={formRef}
              //       formData={formData.current[service.service_id]}
              //       disabled={loading}
              //       colCount={3}
              //     >
              //       {service.service_price_level_1 === 0 &&
              //       service.service_price_level_2 === 0 &&
              //       service.service_price_level_3 === 0 &&
              //       service.service_price_level_4 === 0 &&
              //       service.service_price_level_5 === 0 ? (
              //         <Item colSpan={3} label={{ visible: false }}>
              //           <p className="label">Free Service</p>
              //         </Item>
              //       ) : (
              //         <Item
              //           colSpan={3}
              //           dataField={"priceLevel"}
              //           editorType={"dxSelectBox"}
              //           editorOptions={{
              //             items: [
              //               {
              //                 value: "1",
              //                 text: `Price Level 1: ${service.service_price_level_1} $`,
              //               },
              //               {
              //                 value: "2",
              //                 text: `Price Level 2: ${service.service_price_level_2} $`,
              //               },
              //               {
              //                 value: "3",
              //                 text: `Price Level 3: ${service.service_price_level_3} $`,
              //               },
              //               {
              //                 value: "4",
              //                 text: `Price Level 4: ${service.service_price_level_4} $`,
              //               },
              //               {
              //                 value: "5",
              //                 text: `Price Level 5: ${service.service_price_level_5} $`,
              //               },
              //             ],
              //             placeholder: "Select price level",
              //             displayExpr: "text",
              //             valueExpr: "value",
              //             onValueChanged: (e) => {
              //               formData.current[service.service_id] = {
              //                 ...formData.current[service.service_id],
              //                 priceLevel: e.value,
              //               };
              //             },
              //           }}
              //           label={{ visible: false }}
              //         />
              //       )}
              //       <GroupItem caption="Servicing">
              //         <Item
              //           dataField="servicing"
              //           editorType={"dxSelectBox"}
              //           editorOptions={{
              //             items: servicingTimesOptions,
              //             placeholder: "0",
              //             displayExpr: "text",
              //             valueExpr: "value",
              //             onValueChanged: (e) => {
              //               formData.current[service.service_id] = {
              //                 ...formData.current[service.service_id],
              //                 servicing: e.value,
              //               };
              //             },
              //           }}
              //         >
              //           <Label visible={false} />
              //         </Item>
              //       </GroupItem>
              //       <GroupItem caption="Gap">
              //         <Item
              //           dataField="gap"
              //           editorType="dxSelectBox"
              //           editorOptions={{
              //             items: servicingTimesOptions,
              //             placeholder: "0",
              //             displayExpr: "text",
              //             valueExpr: "value",
              //             onValueChanged: (e) => {
              //               formData.current[service.service_id] = {
              //                 ...formData.current[service.service_id],
              //                 gap: e.value,
              //               };
              //             },
              //           }}
              //         >
              //           <Label visible={false} />
              //         </Item>
              //       </GroupItem>
              //       <GroupItem
              //         caption="Servicing after gap"
              //         validationRules={[
              //           { type: "required", message: "Required" },
              //         ]}
              //       >
              //         <Item
              //           dataField="servicing2"
              //           editorType="dxSelectBox"
              //           editorOptions={{
              //             items: servicingTimesOptions,
              //             placeholder: "0",
              //             displayExpr: "text",
              //             validationRules: [
              //               { type: "required", message: "Required" },
              //             ],

              //             valueExpr: "value",
              //             onValueChanged: (e) => {
              //               formData.current[service.service_id] = {
              //                 ...formData.current[service.service_id],
              //                 servicing2: e.value,
              //               };
              //             },
              //           }}
              //         >
              //           <Label visible={false} />
              //         </Item>
              //       </GroupItem>
              //     </Form>
              //   </form>
              // </div>
            ))}
            <button
              type="submit"
              className="primaryBtn"
              onClick={saveAllServices}
              disabled={loading}
              style={{
                opacity: loading ? 0.7 : 1,
                pointerEvents: loading ? "none" : "initial",
              }}
            >
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                `Save Services`
              )}
            </button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
