import React, { useEffect } from "react";
import "./Stylist-Spend.scss";
import {
  Chart,
  CommonSeriesSettings,
  ConstantLine,
  Export,
  Label,
  Series,
  ValueAxis,
} from "devextreme-react/chart";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "../../contexts/auth";
import { useState, useRef, useCallback } from "react";
import { DropDownBox, Form, List, DateBox } from "devextreme-react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  TotalItem,
  Summary,
} from "devextreme-react/data-grid";
import {
  Autocomplete,
  Box,
  Button,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { baseAxios } from "../../utils/config";

function addDollarSign(data) {
  var cost = data.value;
  if (cost == "undefined" || cost == undefined || cost < 0.01) {
    cost = 0.0;
  }
  return `$${cost.toFixed(2)}`;
}

export default function StylistSpend(props) {
  const getLocationsAPI = `gateway/3lp5nnrkw8`; // Just get's the user's locations
  const { user } = useAuth();
  // const api = 'gateway/n1m3ydd6g8' // Not sure what this one is. Old?
  // const api = 'gateway/eg3p9wnqfb' //Production API
  const api = `gateway/oevsb6eif9`; // Development API
  const { location_name } = props?.location?.state || {};

  // let salonList = ['Fairfax', 'Rosslyn', 'Lee Highway', 'Demo Location']
  // const [salonListDataSource, setSalonListDataSource] = useState(salonList)
  // const listRef = useRef(null)
  // const [selectedSalon, setSelectedSalon] = useState(location_name || salonList[0])
  const [chartDataSource, setChartDataSource] = useState([]);
  const [chartState, setChartState] = useState(null);
  const [maxTotal, setMaxTotal] = useState(0);
  const [maxAverage, setMaxAverage] = useState(25);
  const [salon, setSalon] = useState(null);
  const [locations, setLocations] = useState([]);

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
  };

  var now = new Date();
  var oneWeekAgo = new Date();
  oneWeekAgo.setDate(now.getDate() - 7);
  const startDateRef = useRef(now);
  const endDateRef = useRef(oneWeekAgo);

  const dropDownBoxRef = useRef(null);
  const AverageStylistRef = useRef(null);
  const TotalStylistRef = useRef(null);
  const AverageStylistGridRef = useRef(null);

  // const changeDropDownBoxValue = useCallback((arg) => {
  //   setSelectedSalon(arg.addedItems[0]);
  //   dropDownBoxRef.current.instance.close()
  // }, []);

  // const onSalonValueChanged = useCallback((e) => {
  //   setSelectedSalon(e.value);
  // }, []);

  const onClick = async () => {
    await fetchData();
    // AverageStylistRef.current.instance.refresh()
    TotalStylistRef.current.instance.refresh();
    AverageStylistGridRef.current.instance.refresh();
  };

  const fetchData = async () => {
    var startDate = startDateRef.current.instance.option("value");
    var endDate = endDateRef.current.instance.option("value");
    let apiArguments = {
      Barcode: user.barcode,
      Role: user.role,
      Salon: salon,
      StartDate: startDate,
      EndDate: endDate,
      Organization: parseInt(user.org),
    };
    console.log(apiArguments);
    const data = await baseAxios.post(api, apiArguments).then((result) => {
      console.log(result.data);
      return result.data;
    });

    const maxTotalValue = data.reduce((max, item) => {
      if (item.total_spent > max) {
        return item.total_spent;
      } else {
        return max;
      }
    }, 0);

    const maxAverageValue = data.reduce((max, item) => {
      if (item.average_spent > max) {
        return item.average_spent;
      } else {
        return max;
      }
    }, 0);

    setMaxTotal(Math.ceil(maxTotalValue));
    // setMaxAverage(Math.ceil(maxAverageValue * 3.5));

    setChartDataSource(data);
  };

  useEffect(async () => {
    if (userCredentials.Role.toLowerCase().includes("system admin")) {
      const res = await baseAxios.post(getLocationsAPI, userCredentials);
      setLocations(res.data);
    } else {
      var users_salon = {
        location_id: user.location_id,
        location_name: user.location_name,
        organization_id: parseInt(user.org),
      };
      var locations_array = [];
      locations_array.push(users_salon);
      setLocations(locations_array);
    }
  }, []);

  const onContentReady = () => {
    const state = AverageStylistGridRef.current.instance.state();
    if (JSON.stringify(chartState) !== JSON.stringify(state)) {
      const sortOption = state.columns.find((c) =>
        c.hasOwnProperty("sortOrder")
      );
      if (sortOption) {
        const sortField = sortOption.dataField;
        const sortOrder = sortOption.sortOrder.toLowerCase();

        const dataSourceCopy = [...chartDataSource];
        dataSourceCopy.sort((a, b) => {
          if (sortOrder === "asc") {
            return a[sortField] - b[sortField];
          } else {
            return b[sortField] - a[sortField];
          }
        });

        setChartDataSource(dataSourceCopy);
      }
      setChartState(state);
    }
  };

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Stylist Spendings </h2>
      <p className="content-block">
        Total amounts each stylist spent over a given period of time, along with
        averages.
      </p>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginBottom: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "1rem",
                }}
              >
                <label htmlFor="barcode">Salon: </label>
                <Autocomplete
                  sx={{
                    minWidth: "200px",
                    marginLeft: "0.5rem",
                  }}
                  value={salon}
                  getOptionLabel={(option) => option.location_name || ""}
                  onChange={(event, newValue) => {
                    setSalon(newValue);
                  }}
                  size="small"
                  options={locations}
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                />
              </Box>
            </Box>
            <DateBox
              type="date"
              value={oneWeekAgo}
              label="Start Date"
              labelMode="static"
              ref={startDateRef}
              // onValueChanged={onStartDateValueChanged}
            />
            <label htmlFor="barcode"> to </label>
            <DateBox
              type="date"
              value={now}
              label="End Date"
              labelMode="floating"
              max={now}
              ref={endDateRef}
              //onValueChanged={onEndDateValueChanged}
            />

            <Box>
              <Button variant="contained" onClick={onClick}>
                Run Report
              </Button>
            </Box>
          </Box>

          {/* <div id='input-box'>
            <h2 className={'content-block'}>Salon: </h2>
            <DropDownBox
              dataSource={salonListDataSource}
              placeholder='Select a Salon...'
              onValueChanged={onSalonValueChanged}
              value={selectedSalon}
              ref={dropDownBoxRef}
            >
              <List
                selectionMode='single'
                ref={listRef}
                dataSource={salonListDataSource}
                onSelectionChanged={changeDropDownBoxValue}
              />
            </DropDownBox>
            <div id='date-selection-box'>
              <h2 className={'content-block'}>Start Date: </h2>
              <DateBox
                type='date'
                value={startDate}
                label='Start Date'
                labelMode='floating'
                onValueChange={(data) => setStartDate(data)}
              />
              <h2 className={'content-block'}>End Date: </h2>
              <DateBox
                type='date'
                value={endDate}
                label='End Date'
                labelMode='floating'
                max={now}
                onValueChange={(data) => setEndDate(data)}
              />
            </div>
            <Button text="Run Report" onClick={onClick}></Button>
          </div> */}
          <br></br>
          <Chart
            id="chart"
            dataSource={chartDataSource}
            ref={TotalStylistRef}
            title={"Total Stylist Spending"}
          >
            <Export enabled={true} fileName={"Total Stylist Spending"} />
            <Series
              valueField="total_spent"
              argumentField="Stylist"
              name="Total spent"
              type="bar"
              color="#0dcae7"
            >
              <Label
                font={{ color: "black" }}
                backgroundColor="transparent"
                visible={true}
                customizeText={addDollarSign}
              ></Label>
            </Series>
            <Series
              valueField="total_number"
              argumentField="Stylist"
              name="Number of Transactions"
              type="bar"
              color="#ed0f7e"
            >
              <Label
                font={{ color: "black" }}
                backgroundColor="transparent"
                visible={true}
              ></Label>
            </Series>

            <Series
              axis={"average"}
              valueField="average_spent"
              argumentField="Stylist"
              name="Average per transaction"
              type="bar"
              color="#6ae609"
            >
              <Label
                font={{ color: "black" }}
                backgroundColor="transparent"
                visible={true}
                customizeText={addDollarSign}
              ></Label>
            </Series>
            <ValueAxis
              name={"Total Spent"}
              title="Total Spent"
              visualRange={[0, maxTotal]}
            ></ValueAxis>
            <ValueAxis
              name={"average"}
              title="Average Spent per Transaction"
              position="right"
              visualRange={[0, maxAverage]}
            ></ValueAxis>

            <ConstantLine
              width={2}
              //value={}
            ></ConstantLine>
          </Chart>
          <br></br>
          {/* <Chart 
          id='chart' 
          dataSource={chartDataSource} 
          ref={AverageStylistRef} 
          title={'Average Stylist Spending'}
          >
            <Export
            enabled={true}
            fileName={'Average Stylist Spending'}
            />
            <Series 
              valueField='average_spend'
              argumentField='Stylist'
              name='Average per transaction'
              type='bar'
              color='#03A9F4'
            >
              <Label visible={true}>
              </Label>
            </Series>
            <ConstantLine
              width={2}
              //value={}
            ></ConstantLine>
          </Chart>
          <br></br> */}

          <DataGrid
            dataSource={chartDataSource}
            className={"dx-card wide-card"}
            showBorders={true}
            ref={AverageStylistGridRef}
            //focusedRowEnabled={true}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnResizing={true}
            onContentReady={onContentReady}
          >
            <Paging defaultPageSize={25} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Scrolling mode="standard" />
            <Export enabled={true} allowExportSelectedData={false} />
            <Column dataField={"Stylist"} caption={"Stylist"} fixed={true} />
            <Column
              dataField={"average_spent"}
              caption={"Average Spent per Transaction"}
              dataType={"currency"}
              customizeText={addDollarSign}
            >
              <format type="fixedPoint" precision={2} />
            </Column>
            <Column
              dataField={"total_spent"}
              sortOrder={"desc"}
              caption={"Total Spent"}
              dataType={"currency"}
              customizeText={addDollarSign}
            >
              <format type="fixedPoint" precision={2} />
            </Column>
            <Column
              dataField={"total_number"}
              caption={"Number of Transactions"}
              alignment={"left"}
            />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
