import react from "react";
import { getUser } from "./api/auth";

export const navigation = [
  { text: "Salon Dashboard", path: "/salon-dashboard", icon: "home" },
  { text: "Stylist Dashboard", path: "/stylist-dashboard", icon: "user" },
  { text: "Device Status", path: "/home", icon: "home" },
  {
    text: "Teams",
    path: "/manage-users",
    icon: "group",
  },
  {
    text: "Scheduler",
    path: "/scheduler",
    icon: "event",
  },
  {
    text: "Report Center",
    icon: "chart",
    items: [
      {
        text: "Transactions",
        path: "/reports",
        //visible: false,
      },
      {
        text: "Rolled Transactions",
        path: "/rolled-reports",
      },
      {
        text: "Salon Container Usage",
        path: "/salon-reports",
      },
      {
        text: "Stylist Spending",
        path: "/stylist-spend",
      },
    ],
    visible: false,
  },
  {
    text: "Admin Panel",
    icon: "cut",
    items: [
      {
        text: "Add a Salon",
        path: "/create-salon",
        //visible: false,
      },
      {
        text: "Salon Settings",
        path: "/configure-salon",
        //visible: false,
      },
      {
        text: "Create Service Category",
        path: "/create-category",
      },
      {
        text: "Create Service",
        path: "/create-service",
        visible: false,
      },
      {
        text: "Manage Services",
        path: "/search-services",
      },
      {
        text: "Add a stylist",
        path: "/create-account?role=stylist",
        visible: false,
      },
      {
        text: "Manage Stylists",
        path: "/search-stylists",
      },
      {
        text: "Add Client",
        path: "/create-client",
      },
      {
        text: "Edit Client",
        path: "/edit-client",
      },
      // {
      //   text: "Rolled Transactions",
      //   path: "/rolled-reports-sql",
      // },
      // {
      //   text: "Salon Container Usage",
      //   path: "/salon-reports-sql",
      // },
      // {
      //   text: "Stylist Spending",
      //   path: "/stylist-spend-sql",
      // },
    ],
  },
  {
    text: "Report Center",
    icon: "chart",
    items: [
      {
        text: "Transactions",
        path: "/reports-sql",
        //visible: false,
      },
      {
        text: "Rolled Transactions",
        path: "/rolled-reports-sql",
      },
      {
        text: "Salon Container Usage",
        path: "/salon-reports-sql",
      },
      {
        text: "Stylist Spending",
        path: "/stylist-spend-sql",
      },
    ],
  },
  {
    text: "Inventory",
    icon: "product",
    items: [
      {
        text: "Current Inventory - Full List",
        path: "/inventory",
      },
      {
        text: "Reorder Inventory",
        path: "/reorder",
      },
      {
        text: "Inventory Receiving",
        path: "/inventory-receiving",
      },
      {
        text: "Product Usage",
        path: "/reporting",
        //icon: 'fill',
      },
      {
        text: "Product Catalogue",
        path: "/manufacturers",
        visible: false,
        //icon: 'hierarchy'
      },
      {
        text: "Product Catalogue",
        path: "/manufacturers-sql",
        //icon: 'hierarchy'
      },
      {
        text: "Item Check/Update",
        visible: false,
      },
      {
        text: "Perform Inventory",
        visible: false,
      },
      {
        text: "Generate New Order List",
        visible: false,
      },
      {
        text: "Inventory/Order History",
        visible: false,
      },
    ],
  },
  {
    text: "Community",
    icon: "group",
    items: [
      {
        text: "Interact with Community",
      },
      {
        text: "Unlimited Members",
      },
      {
        text: "Special Announcements",
      },
      {
        text: "Documents",
      },
      {
        text: "Photos",
      },
      {
        text: "Formulas",
      },
      {
        text: "Online Payments",
      },
    ],
    visible: false,
  },

  {
    text: "Recipes",
    path: "/recipes",
    icon: "fill",
    visible: false,
  },
  {
    text: "Pivotgrid Class",
    path: "/pivotgrid-class",
    icon: "folder",
    visible: false,
  },
];
