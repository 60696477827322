import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import { useContext } from "react";
import { Context } from "../../utils/config";
import { useScheduler } from "@aldabil/react-scheduler";

function ViewMode({ locations, handleChange, fetchRemote }) {
    const { location } = useContext(Context);
    const { resourceViewMode, setResourceViewMode } = useScheduler();

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Autocomplete
                    style={{ minWidth: "220px" }}
                    size="small"
                    options={locations}
                    getOptionLabel={(option) => option.location_name || "All Locations"}
                    defaultValue={location || { location_name: "All Locations", location_id: -1 }}
                    renderInput={(params) => <TextField {...params} label="Select a location" />}
                    onChange={(e, value) => handleChange(value)}
                />
                <Button
                    sx={{ ml: 1 }}
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={fetchRemote}
                >
                    Refresh
                </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ ml: 2, mr: 1 }}>View Mode: </Typography>
                <Button
                    color={resourceViewMode === "default" ? "primary" : "inherit"}
                    variant={resourceViewMode === "default" ? "contained" : "text"}
                    size="small"
                    onClick={() => setResourceViewMode("default")}
                >
                    Default
                </Button>
                <Button
                    color={resourceViewMode === "tabs" ? "primary" : "inherit"}
                    variant={resourceViewMode === "tabs" ? "contained" : "text"}
                    size="small"
                    onClick={() => setResourceViewMode("tabs")}
                >
                    Tabs
                </Button>
            </Box>
        </Box>
    );
};

export default ViewMode;