import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TextField,
  Button,
  DialogActions,
  Typography,
  Autocomplete,
  ListItemText,
  Box,
  Grid,
  Divider,
  ListItem,
} from "@mui/material";
import dayjs from "dayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SchedulerAPI, groupedSchedules } from "./config";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AsyncAutocomplete from "../../components/autocomplete/autocomplete";
import AsyncAutocompleteAddClient from "../../components/autocompleteAddClient/autocompleteAddClient";
import stringToColor from "string-to-color";
import { toast } from "react-hot-toast";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";

dayjs.extend(utc);
dayjs.extend(timezone);

export default ({ scheduler, dataset, setDataset }) => {
  const location = JSON.parse(localStorage.getItem("location"));
  const urlLocation = useLocation();
  const queryParams = new URLSearchParams(urlLocation.search);
  const locationIdFromURL = queryParams.get("location_id");
  const clientIdFromURL = queryParams.get("client_id");
  const history = useHistory();
  const [userToServiceData, setUserToServiceData] = useState([]);
  const [filteredServicesOptions, setFilteredServicesOptions] = useState([]);

  const searchAllUserToServiceAPi = "gateway/9q0j9rk17f";

  const createAppointmentAPI = "scheduler/createAppointment";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resServices = await baseAxios.post(searchAllUserToServiceAPi);
        const servicesData = resServices.data;
        const normalizedData = Array.isArray(servicesData)
          ? servicesData
          : servicesData.user_to_services || [];
        setUserToServiceData(normalizedData);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {}, [scheduler.state.user_id, userToServiceData]);

  const resetFilteredServices = (id) => {
    const stylistServicesIds = userToServiceData
      .filter((mapping) => mapping.user_id.toString() === id)
      .map((mapping) => mapping.service_id);

    const filteredServices = dataset.services.filter((service) =>
      stylistServicesIds.includes(service.service_id.toString())
    );

    setFilteredServicesOptions(
      filteredServices?.length < 1
        ? [{ title: "Add new service", service_id: -1 }]
        : filteredServices
    );
  };

  useEffect(() => {
    const user = dataset.users?.find(
      (user) => user.user_id === scheduler.state.user_id.value
    );

    if (!user) {
      return;
    }

    resetFilteredServices(scheduler.state.user_id.value);

    handleChange(
      { user_id: scheduler.state.user_id.value, user_name: user?.user_name },
      "user"
    );
  }, [userToServiceData]);

  const schedulesData = dataset.schedules;

  const clearURLParams = () => {
    history.replace(history.location.pathname);
  };

  const [data, setData] = useState(() => {
    let event = scheduler.edited;

    if (!event) {
      const user = dataset.users?.find(
        (user) => user.user_id === scheduler.state.user_id.value
      );
      event = {
        start: scheduler.state.start.value,
        end: scheduler.state.end.value,
        title: scheduler.state.title.value,
        event_id: scheduler.state.event_id.value,
        user_id: scheduler.state.user_id.value,
        user_name: user?.user_name,
        location: location,
      };
    }

    return event;
  });

  const getLocationFromUserId = (user_id) => {
    const selectedUser = dataset?.users.filter(
      (user) => user.user_id === user_id
    );
    return selectedUser?.length > 0
      ? selectedUser[0].location_name
      : "Stylist is not selected";
  };

  const handleSubmit = async () => {
    if (!data.location || data.location?.location_id === -1) {
      toast.error("Please select a location.");
      return;
    }
    if (!data.client) {
      toast.error(
        `Select a client in ${
          data.location.location_name ?? "the selected location"
        }.`
      );
      return;
    }
    if (!data.user_id) {
      toast.error(
        `Select a stylist in ${
          data.location.location_name ?? "the selected location"
        }.`
      );
      return;
    }
    if (!data.start) {
      toast.error("Select a start time for the appointment.");
      return;
    }
    if (!data.uniqueServices || data.uniqueServices.length === 0) {
      toast.error("Please choose at least one service.");
      return;
    }

    let user_id = data.user_id.toString();

    const serviceIds = data.uniqueServices.map((service) => service.service_id);

    const userId = user_id;

    const serviceSteps = userToServiceData
      .filter(
        (entry) =>
          entry.user_id.toString() === userId.toString() &&
          serviceIds.includes(Number(entry.service_id))
      )
      .map((entry) => ({
        service_id: entry.service_id,
        service_step1_minutes: entry.service_step1_minutes,
        service_step2_minutes: entry.service_step2_minutes,
        service_step3_minutes: entry.service_step3_minutes,
      }));

    const servicesWithSteps = data.uniqueServices.map((service) => {
      const matchingSteps = serviceSteps.find(
        (step) => step.service_id === service.service_id.toString()
      );
      if (matchingSteps) {
        return {
          ...service,
          service_step1_minutes: matchingSteps.service_step1_minutes,
          service_step2_minutes: matchingSteps.service_step2_minutes,
          service_step3_minutes: matchingSteps.service_step3_minutes,
        };
      }
      return service;
    });

    const filteredServicesWithSteps = servicesWithSteps.filter(
      (x) =>
        x.service_step1_minutes > 0 ||
        x.service_step2_minutes > 0 ||
        x.service_step3_minutes > 0
    );

    if (filteredServicesWithSteps?.length === 0) {
      toast.error(
        "Please choose at least one service with total step duration of at least 1 minute."
      );
      return;
    }

    //check if overlap
    const newScheduleStart = dayjs(data.start).tz("Africa/Abidjan", true);
    let cumulativeDuration = 0;

    data.uniqueServices.forEach((service) => {
      const stepDetails = serviceSteps.find(
        (step) => step.service_id.toString() === service.service_id.toString()
      );
      if (stepDetails) {
        cumulativeDuration += parseInt(stepDetails.service_step1_minutes, 10);
        if (stepDetails.service_step2_minutes) {
          cumulativeDuration += parseInt(stepDetails.service_step2_minutes, 10);
        }
        if (stepDetails.service_step3_minutes) {
          cumulativeDuration += parseInt(stepDetails.service_step3_minutes, 10);
        }
      }
    });

    const newScheduleEnd = newScheduleStart.add(cumulativeDuration, "minute");

    let overlapStart;

    schedulesData
      .filter(
        (x) =>
          new Date(
            x.start.getFullYear(),
            x.start.getMonth(),
            x.start.getDate()
          ).getTime() ===
          new Date(
            data.start.getFullYear(),
            data.start.getMonth(),
            data.start.getDate()
          ).getTime()
      )
      .forEach((schedule) => {
        if (schedule.user_id.toString() !== userId.toString()) {
          return false;
        }

        const existingStart = new Date(schedule.start).getTime();
        const existingEnd = new Date(schedule.end).getTime();
        const newStart = newScheduleStart.toDate().getTime();
        const newEnd = newScheduleEnd.toDate().getTime();

        if (newStart <= existingStart && newEnd > existingStart) {
          if (
            !overlapStart ||
            existingStart < overlapStart.toDate().getTime()
          ) {
            overlapStart = dayjs(schedule.start).utc(false);
          }
        } else if (newStart > existingStart && newStart < existingEnd) {
          if (!overlapStart || schedule.start < overlapStart) {
            overlapStart = newScheduleStart;
          }
        }
      });

    if (overlapStart) {
      const confirmResult = window.confirm(
        `Upon scheduling this service, the stylist will be double-booked starting at ${dayjs(
          overlapStart
        )
          .utc(true)
          .format("h:mm A")}. Would you like to proceed?`
      );

      if (!confirmResult) {
        return;
      }
    }

    if (scheduler.edited) {
      await modifySubmit(filteredServicesWithSteps);
    } else {
      await createSubmit(filteredServicesWithSteps);
    }

    const res2 = await baseAxios.post(SchedulerAPI.get, userCredentials);

    const result = {
      ...res2.data,
      stylists: res2.data?.stylists.map((stylist) => ({
        ...stylist,
        color: stringToColor(stylist.user_name),
      })),
      schedules: groupedSchedules(res2.data?.schedules),
      locations: [
        { location_id: -1, location_name: "All Locations" },
        ...res2.data?.locations,
      ],
    };

    setDataset(result);
  };
  const { user } = useAuth();
  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  const createSubmit = async (filteredServicesWithSteps) => {
    let toastId;
    try {
      toastId = toast.loading("Creating appointment...");
      scheduler.loading(true);

      const res = await baseAxios.post(createAppointmentAPI, {
        location_id: data.location.location_id,
        user_id: data.user_id,
        client_id: data.client.client_id,
        services: filteredServicesWithSteps,
        start: dayjs(data.start).format("YYYY-MM-DD HH:mm:ss"),
      });
      if (res.status === 200) {
        toast.success("Appointments created successfully.");
      }
    } catch (err) {
      console.log("error creating appointment");
      toast.error(err.response?.data?.message || "Something went wrong.");
    } finally {
      toast.dismiss(toastId);
      scheduler.close();
      scheduler.loading(false);
    }
  };

  const modifySubmit = async (filteredServicesWithSteps) => {
    const toastId = toast.loading("Updating appointment...");
    try {
      scheduler.loading(true);
      scheduler.close();
      console.log(data.start);

      const res = await baseAxios.put(SchedulerAPI.modify, {
        location_id: data.location.location_id,
        user_id: data.user_id,
        client_id: data.client.client_id,
        services: filteredServicesWithSteps,
        start: dayjs(data.start).format("YYYY-MM-DD HH:mm:ss"),
        schedule_entry_id: data.appointmentId,
      });

      if (res.status === 200) {
        toast.success("Appointments updated successfully.");

        clearURLParams();
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "Something went wrong.");
    } finally {
      scheduler.loading(false);
      toast.dismiss(toastId);
    }
  };

  const handleChange = (value, name) => {
    if (name === "location") {
      setData((prev) => {
        return {
          ...prev,
          client: null,
          user_id: null,
          user_name: "",
          location: value,
        };
      });
    } else if (name === "user") {
      const selectedStylistId = value?.user_id.toString();

      resetFilteredServices(selectedStylistId);

      setData((prev) => ({
        ...prev,
        user_id: value?.user_id,
        user_name: value?.user_name || "",
        services: !scheduler.edited ? [] : prev.services,
      }));
    } else {
      setData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  //handle default values from create client form
  useEffect(() => {
    // Set default location
    if (locationIdFromURL) {
      const foundLocation = dataset.locations.find(
        (loc) => loc.location_id.toString() === locationIdFromURL
      );
      if (foundLocation) {
        handleChange(foundLocation, "location");
      }
    }

    // Set default client
    if (clientIdFromURL) {
      const foundClient = dataset.clients.find(
        (client) => client.client_id.toString() === clientIdFromURL
      );
      if (foundClient) {
        handleChange(foundClient, "client");
      }
    }
  }, [locationIdFromURL, clientIdFromURL, dataset.locations, dataset.clients]);

  const addNewServiceToStylist = () => {
    history.push({
      pathname: "/add-service-to-Stylist",
      search: `?param=${data.user_id}`,
    });
  };

  return (
    <Box px={5} pt={4} pb={2} sx={{ minHeight: "500px", position: "relative" }}>
      <Grid container rowGap={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <Typography mb={3} variant="h6" textAlign="center">
                Make an appointment
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AsyncAutocomplete
                label="Locations"
                datasets={
                  dataset.locations?.filter(
                    (location) => location.location_id !== -1
                  ) || []
                }
                keyField="location_id"
                titleField="location_name"
                subtitleField="organization_name"
                sortField="location_name"
                value={data.location}
                onChange={(value) => handleChange(value, "location")}
                locationIdFromURL={locationIdFromURL}
              />
            </Grid>
            <Grid item xs={12}>
              <AsyncAutocompleteAddClient
                label="Clients"
                datasets={
                  dataset.clients?.filter(
                    (client) =>
                      client.client_organization_id ===
                      data.location?.organization_id
                  ) || []
                }
                keyField="client_id"
                titleField="client_name"
                subtitleField="org_name"
                phoneField="client_mobile_phone_number"
                emailField="client_email"
                sortField="client_name"
                value={data.client}
                onChange={(value) => handleChange(value, "client")}
                disabled={!data.location || data.location?.location_id === -1}
                // disabled={isClientDisabled}
                selectedLocation={data.location} // Passing selected location
                clientIdFromURL={clientIdFromURL}
              />
            </Grid>
            <Grid item xs={12}>
              <AsyncAutocomplete
                label="Stylists"
                datasets={
                  dataset?.users.filter(
                    (user) =>
                      user.user_location_id === data.location?.location_id
                  ) || []
                }
                keyField="user_id"
                titleField="user_name"
                subtitleField="location_name"
                sortField="user_name"
                value={{
                  user_id: data.user_id,
                  user_name: data.user_name,
                  location_name: data.location?.location_name,
                }}
                onChange={(value) => handleChange(value, "user")}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                className={`autocomplete-servicefield ${
                  data.services?.length > 0 ? "success" : ""
                }`}
                options={filteredServicesOptions}
                size="small"
                filterSelectedOptions
                defaultValue={data.uniqueServices}
                getOptionLabel={(option) =>
                  option.title || option.service_name || ""
                }
                isOptionEqualToValue={(option, value) =>
                  option.service_id === value.service_id
                }
                renderOption={(props, data) => {
                  return (
                    <ListItem {...props}>
                      {data.service_id === -1 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "8px",
                          }}
                        >
                          <ListItemText
                            primary="No Services Found"
                            secondary="Would you like to add a new service?"
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={addNewServiceToStylist}
                          >
                            Add Service
                          </Button>
                        </Box>
                      ) : (
                        data.service_name
                      )}
                    </ListItem>
                  );
                }}
                onChange={(e, value) => handleChange(value, "uniqueServices")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Services"
                    placeholder="Select Multiple Services"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  className={`autocomplete-datefield ${
                    data.start ? "success" : ""
                  }`}
                  slotProps={{ textField: { size: "small" } }}
                  label="Start Time"
                  format="MMM D, h:mm A"
                  defaultValue={dayjs(data.start)}
                  onChange={(value) => handleChange(value.toString(), "start")}
                />
                {/* <Typography pl={2} pr={1}>To:</Typography>
                        <Typography>{dayjs(data.end).format('MM/DD/YYYY hh:mm A')}</Typography> */}
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <Typography mb={3} variant="h6" textAlign="center">
                Preview
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    color: "white",
                    // background: stringToColor(data.user_name),
                    background:
                      data.services?.[0]?.color ||
                      stringToColor(data.user_name),
                    marginRight: "10px",
                  }}
                >
                  {data.user_name?.length > 0 ? data.user_name[0] : "?"}
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "14px", marginBottom: "0px" }}>
                    {data.user_name || "Not specified"}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#909090" }}>
                    {getLocationFromUserId(data.user_id)}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ marginBottom: "5px" }} />
              <Box
                sx={{
                  paddingLeft: "13px",
                  paddingBottom: "3px",
                  // background: stringToColor(data.user_name),
                  background:
                    data.services?.[0]?.color || stringToColor(data.user_name),
                }}
              >
                <Box
                  sx={{
                    paddingTop: "5px",
                    paddingLeft: "10px",
                    background: "#e0e0e0",
                    height: "100%",
                    minHeight: "100px",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                  >
                    {data.client?.client_name}
                  </Box>
                  <Box sx={{ fontSize: "12px", marginTop: "5px" }}>
                    {dayjs(data.start).format("MMM D, h:mm A")}
                  </Box>
                  <Box sx={{ fontSize: "12px", marginTop: "5px" }}>
                    {data.uniqueServices?.map((service) => (
                      <Box sx={{ marginTop: "5px" }}>
                        <Box>{service.service_name}</Box>
                        <Box>{service.service_shortcut}</Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions sx={{ marginTop: "60px" }}>
        <Button variant="contained" onClick={handleSubmit}>
          Confirm
        </Button>
        <Button onClick={scheduler.close}>Cancel</Button>
      </DialogActions>
    </Box>
  );
};
