import { baseAxios } from "../../utils/config";
const userList = require("./User List.json");

var transactions = [];
var loaded = false;

// function CheckNames(data) {
//   data.forEach(obj => {
//     var userName = userList[obj.user_id];
//     if(typeof userName == "undefined") {
//       obj.user_id = "unrecognized user";
//     }
//     else {
//       obj.user_id = userName;
//     }
//   });
// }

export default function GetTransactions() {
  if (!loaded) {
    const api = "gateway/ixinslynhh"; // Legacy GetTransactions
    //const api =  'gateway/6f8ahwntp0';  // TransactionScanAPI

    baseAxios.post(api).then((result) => {
      console.log("Raw: ", result);
      let resultBody = result.data;
      console.log("ResultBody: ", resultBody);

      //console.log("Replaced: ");
      //let replacedBody = resultBody.replace(/'/g, "\"").replace(/Decimal\(/g, "").replace(/\)/g, "").replace(/{/g, "{\"location\": \"Springfield\", ");
      //console.log(replacedBody);

      //console.log("Parsed: ");
      let parsedBody = resultBody;
      //CheckNames(parsedBody);

      //let parsedBody = JSON.parse(sales)
      console.log("ParsedBody: ", parsedBody);

      transactions = parsedBody;
      //transactions = resultBody;
      loaded = true;
    });
  }

  return transactions;
}
