import React, { useState, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { resetPassword, confirmPassword } from "../../api/auth";
import "./reset-password-form.scss";

const notificationText = "Password successfully updated.";

export default function ResetPasswordForm() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const formData = useRef({});
  const formDataCode = useRef({});

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const { email } = formData.current;
      setLoading(true);

      const result = await resetPassword(email);

      if (!result.isOk) {
        setLoading(false);
        notify(result.message, "error", 2000);
      }
    },
    [history]
  );

  const onSubmitCode = useCallback(
    async (e) => {
      e.preventDefault();

      const { email } = formData.current;
      const { code, password } = formDataCode.current;

      setLoadingCode(true);

      const result = await confirmPassword(email, code, password);
      setLoading(false);
      setLoadingCode(false);

      if (result.isOk) {
        history.push("/login");
        notify(notificationText, "success", 2500);
      } else {
        notify(result.message, "error", 2000);
      }
    },
    [history]
  );
  return (
    <>
      {loading == false ? (
        <form
          className={"reset-password-form"}
          onSubmit={onSubmit}
          disabled={loading}
        >
          <Form formData={formData.current}>
            <Item
              dataField={"email"}
              editorType={"dxTextBox"}
              editorOptions={emailEditorOptions}
            >
              <RequiredRule message="Email is required" />
              <EmailRule message="Email is invalid" />
              <Label visible={false} />
            </Item>

            <ButtonItem>
              <ButtonOptions
                elementAttr={submitButtonAttributes}
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Reset my password"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
            <Item>
              <div className={"login-link"}>
                Return to <Link to={"/login"}>Sign In</Link>
              </div>
            </Item>
          </Form>
        </form>
      ) : (
        <form
          className={"reset-password-form"}
          onSubmit={onSubmitCode}
          disabled={!loading}
        >
          <Form formData={formDataCode.current}>
            <Item
              dataField={"code"}
              editorType={"dxTextBox"}
              editorOptions={codeEditorOptions}
            >
              <Label visible={false} />
            </Item>

            <Item
              dataField={"password"}
              editorType={"dxTextBox"}
              editorOptions={passEditorOptions}
            >
              <Label visible={false} />
            </Item>

            <ButtonItem>
              <ButtonOptions
                elementAttr={submitButtonAttributes}
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loadingCode ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Change my password"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
            <Item>
              <div className={"login-link"}>
                Return to <Link to={"/login"}>Sign In</Link>
              </div>
            </Item>
          </Form>
        </form>
      )}
    </>
  );
}

const emailEditorOptions = {
  stylingMode: "filled",
  placeholder: "Email",
  mode: "email",
};
const codeEditorOptions = {
  stylingMode: "filled",
  placeholder: "Please input verification code",
  mode: "text",
  inputAttr: {
    autocomplete: "code",
  },
};
const passEditorOptions = {
  stylingMode: "filled",
  placeholder: "Enter new password",
  mode: "password",
  inputAttr: {
    autocomplete: "new-password",
  },
};
const submitButtonAttributes = { class: "submit-button" };
