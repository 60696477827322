import React, { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";
import { UserServiceForm } from "../../components/user-service-form/user-service-form";
import { Popup, Button } from "devextreme-react";

export default function EditStylistService(props) {
  const formRef = useRef(null);
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const formData = useRef({});
  const [service, setService] = useState();
  const [userToServiceData, setUserToserviceData] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const userId = parseInt(queryParams.get("user"), 10);
  const serviceId = parseInt(queryParams.get("service"), 10);
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);

  const searchStylistsApi = "gateway/igf3twd3c3";
  const updateUserToServiceAPI = "gateway/msyn4krb7c";

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataLoaded(false);
        const resServices = await baseAxios.get(
          `services/${userId}/getServiceToEdit/${serviceId}`
        );
        const service = resServices.data;
        setService(service);
        console.log(service);

        const initialFormData = {};
        initialFormData[service.service_id] = {
          priceLevel: service.price_level,
          servicing: Number(service.service_step1_minutes) || 0,
          gap: Number(service.service_step2_minutes) || 0,
          servicing2: Number(service.service_step3_minutes) || 0,
        };
        formData.current = initialFormData;
        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching services: ", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const location = userCredentials.Location;
    const requestData = {
      location: location,
    };
    const fetchData = async () => {
      try {
        const res = await baseAxios.post(searchStylistsApi, requestData);
        const data = res.data;
        const stylist = data.find((stylist) => stylist.user_id === userId);
        if (stylist) {
          setFirstName(stylist.user_first_name);
          setLastName(stylist.user_last_name);
        } else {
          console.log("Stylist not found");
        }
      } catch (error) {
        console.error("Error fetching stylists: ", error);
      }
    };
    fetchData();
  }, [userId]);

  const handleConfirm = async () => {
    deleteService(service.service_id);
    setIsConfirmationPopupVisible(false);
  };

  const handleCancel = () => {
    setIsConfirmationPopupVisible(false);
  };

  const handleSubmit = async (serviceId) => {
    setLoading(true);

    const serviceData = formData.current[serviceId];
    console.log(serviceData);
    if (
      !serviceData.servicing ||
      serviceData.servicing < 15 ||
      (!serviceData.priceLevel &&
        (service.service_price_level_1 > 0 ||
          service.service_price_level_2 > 0 ||
          service.service_price_level_3 > 0 ||
          service.service_price_level_4 > 0 ||
          service.service_price_level_5 > 0))
    ) {
      notify(
        "Service need to have Price level and Servicing time set.",
        "Error",
        3000
      );
      return;
    }

    const payload = {
      userId: userId,
      serviceId: serviceId,
      priceLevel: serviceData.priceLevel || 1,
      servicing: serviceData.servicing,
      gap: serviceData.gap || 0,
      servicing2: serviceData.servicing2 || 0,
    };

    try {
      const response = await baseAxios.post(updateUserToServiceAPI, payload);

      if (response.status === 200) {
        history.goBack();
      } else {
        notify("Error updating service.", "Error", 3000);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      notify("Error updating service.", "Error", 3000);
    }
  };

  const deleteService = async (serviceId) => {
    try {
      const response = await baseAxios.post("services/deleteUserService", {
        serviceId,
        userId,
      });

      console.log(response);
      if (response.status === 200) {
        history.goBack();
      } else {
        console.error(
          "Update not successful or unknown status:",
          response.data
        );
        setLoading(false);
      }
    } catch (e) {
      console.e("An error occurred:", e);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Popup
        visible={isConfirmationPopupVisible}
        onHiding={handleCancel}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit service"
        showCloseButton={true}
        width={"30%"} // Adjust width as needed
        height={"30%"} // Adjust height as needed
      >
        <p>
          Are you sure you want to delete{" "}
          <strong>{service?.service_name}</strong> for user{" "}
          <strong>
            {firstName} {lastName}
          </strong>
          ?
        </p>
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            text="Cancel"
            onClick={handleCancel}
            style={{ marginRight: "10px" }}
          />
          <Button text="Confirm" type="default" onClick={handleConfirm} />
        </div>
      </Popup>
      <div style={{ width: "80%", marginLeft: "40px" }}>
        <div className={"header"}>
          <div
            className={"title"}
            style={{
              fontSize: "24px",
              fontWeight: 500,
              marginBottom: "20px",
              marginTop: "10px",
              margin: "20px",
            }}
          >
            Edit Services for {firstName} {lastName}
          </div>
          <div
            className={"description"}
            style={{
              fontSize: "20px",
              fontWeight: 500,
              marginBottom: "20px",
              margin: "20px",
            }}
          >
            Configure {service?.service_name} service settings
          </div>
        </div>
        {dataLoaded && (
          <React.Fragment>
            <div>
              <UserServiceForm
                service={service}
                loading={loading}
                formData={formData}
                index={0}
                deleteService={() => setIsConfirmationPopupVisible(true)}
              />
              <button
                type="submit"
                className="primaryBtn"
                onClick={() => handleSubmit(service.service_id)}
                disabled={loading}
                style={{
                  opacity: loading ? 0.7 : 1,
                  pointerEvents: loading ? "none" : "initial",
                }}
              >
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  `Save Services`
                )}
              </button>
            </div>
            {/* <div
              key={service.service_id}
              className={"salon-conf dx-card"}
              style={{
                marginBottom: "30px",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "white",
                width: "80%",
                margin: "auto",
                marginTop: "20px",
              }}
            >
              <div
                className={"header"}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div
                  className={"title"}
                  style={{
                    flexGrow: 1,
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    padding: "10px",
                  }}
                >
                  Service: {service.service_name}
                </div>
                <button
                  className="deleteBtn"
                  onClick={() => deleteService(service.service_id)}
                >
                  Remove Service
                </button>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(
                    service.service_id,
                    formData.current[service.service_id]
                  );
                }}
              >
                <Form
                  ref={formRef}
                  formData={formData.current[service.service_id]}
                  disabled={loading}
                >
                  <Item
                    // dataField={`dropdown_${service.service_id}`}
                    dataField={"priceLevel"}
                    editorType={"dxSelectBox"}
                    editorOptions={{
                      items: [
                        {
                          value: "1",
                          text: `Price Level 1: ${service.service_price_level_1} $`,
                        },
                        {
                          value: "2",
                          text: `Price Level 2: ${service.service_price_level_2} $`,
                        },
                        {
                          value: "3",
                          text: `Price Level 3: ${service.service_price_level_3} $`,
                        },
                        {
                          value: "4",
                          text: `Price Level 4: ${service.service_price_level_4} $`,
                        },
                        {
                          value: "5",
                          text: `Price Level 5: ${service.service_price_level_5} $`,
                        },
                      ],
                      placeholder: "Select price level",
                      displayExpr: "text",
                      valueExpr: "value",
                      onValueChanged: (e) => {
                        formData.current[service.service_id] = {
                          ...formData.current[service.service_id],
                          priceLevel: e.value,
                        };
                      },
                    }}
                    label={{ visible: false }}
                  />
                  <GroupItem caption="Servicing (minutes)">
                    <Item
                      dataField="servicing"
                      editorType="dxNumberBox"
                      editorOptions={{
                        min: 0,
                        defaultValue: 0,
                        showSpinButtons: true,
                        onValueChanged: (e) => {
                          formData.current[service.service_id] = {
                            ...formData.current[service.service_id],
                            servicing: e.value,
                          };
                        },
                      }}
                    >
                      <Label visible={false} />
                    </Item>
                  </GroupItem>

                  <GroupItem caption="Gap (minutes)">
                    <Item
                      dataField="gap"
                      editorType="dxNumberBox"
                      editorOptions={{
                        min: 0,
                        defaultValue: 0,
                        showSpinButtons: true,
                        onValueChanged: (e) => {
                          formData.current[service.service_id] = {
                            ...formData.current[service.service_id],
                            gap: e.value,
                          };
                        },
                      }}
                    >
                      <Label visible={false} />
                    </Item>
                  </GroupItem>

                  <GroupItem caption="Servicing after gap (minutes)">
                    <Item
                      dataField="servicing2"
                      editorType="dxNumberBox"
                      editorOptions={{
                        min: 0,
                        defaultValue: 0,
                        showSpinButtons: true,
                        onValueChanged: (e) => {
                          formData.current[service.service_id] = {
                            ...formData.current[service.service_id],
                            servicing2: e.value,
                          };
                        },
                      }}
                    >
                      <Label visible={false} />
                    </Item>
                  </GroupItem>
                </Form>
                <button
                  type="submit"
                  disabled={loading}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#5c6bc0",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "16px",
                    cursor: "pointer",
                    outline: "none",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                    transition: "all 0.3s ease",
                    opacity: loading ? 0.7 : 1,
                    pointerEvents: loading ? "none" : "initial",
                  }}
                >
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    `Edit Service ${service.service_name}`
                  )}
                </button>
              </form>
            </div> */}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
