import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Modal,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { useReactToPrint } from "react-to-print";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";
import notify from "devextreme/ui/notify";

//const inventoryApi = "gateway/8le0qd4lmi"; //Production API for Reorder
const inventoryApi = `gateway/jtiik7asyg`; //Development API
const saveOrderApi = "gateway/hxg1vba474";

const steps = [
  "Prepare",
  "Reorder Selection",
  "Proposed Order",
  "Actual Order Confirmation",
];

function customizeNumeric(data) {
  var formattedNum = data.value;
  formattedNum = (Math.round(formattedNum * 100) / 100).toFixed(2);
  return `${isNaN(formattedNum) ? "-" : formattedNum}`;
}

function adjustToLocalTime(data) {
  var formattedTime = data.value;
  if (new Date(data.value).getYear() < 100) {
    return ``;
  }
  formattedTime = new Date(data.value).toLocaleString(`en-us`, {
    year: `2-digit`,
    month: `numeric`,
    day: `numeric`,
    hour: `numeric`,
    minute: `2-digit`,
  });

  return `${formattedTime}`;
}

function getColor(value) {
  if (value < 0) {
    return "#ed1a1a";
  } else if (value > 0) {
    return "#03a300";
  } else {
    return "inherit";
  }
}

export default (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [resellerList, setResellerList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const [reseller, setReseller] = useState(null);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const resultTableRef = useRef();
  const { user } = useAuth();

  const [sortField, setSortField] = useState(""); // initial sort field
  const [sortOrder, setSortOrder] = useState("asc"); // can be 'asc' or 'desc'

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };
  const { location_name } = props?.location?.state || {};

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const resPromise = baseAxios.get(
          `organization/resellers`,
          userCredentials
        );
        const resLocationsPromise = baseAxios.get(
          `organization/${user.org}/locations`
        );

        const res = await resPromise;
        setResellerList(res.data.resellers || []);

        const locationsRes = await resLocationsPromise;
        setLocations(locationsRes.data.locations);
      } catch {
        notify("Error loading data.", "Error", 3000);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleNext = async () => {
    setLoading(true);

    await handleNextInternal();

    setLoading(false);
  };

  const handleNextInternal = async () => {
    if (activeStep === 0) {
      if (!reseller) {
        toast.error("Please select a reseller");
        return;
      }
      if (!location) {
        toast.error("Please select a location");
        return;
      }

      var inventoryRes = await baseAxios.get(
        `inventory/${location.location_id}/${reseller.reseller_id}/listPerLocationAndReseller`
      );

      setDataSource(inventoryRes.data.products);
    } else if (activeStep === 1) {
      if (!orderNumber) {
        toast.error("Please input an order number");
        return;
      }
      if (selected.length === 0) {
        toast.error("Please select at least one item");
        return;
      }

      let items = [...selected];
      items = items.map((item) => {
        item.new_quantity = quantityToOrder(item);
        return item;
      });
      setSelected(items);
    } else if (activeStep === 2) {
      const result = window.confirm(
        `Are you sure you want to reorder ${selected.length} products?`
      );
      if (result) {
        const order = {
          reseller_id: reseller.reseller_id,
          order_items: selected,
          order_number: orderNumber,
        };

        await baseAxios.post(saveOrderApi, order);
      } else {
        return;
      }
    } else if (activeStep === 3) {
      setSelected([]);
    }

    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % 4);
  };

  const handlePrint = useReactToPrint({
    content: () => resultTableRef.current,
  });

  const handleBack = () => {
    if (activeStep === 1) {
      setOrderNumber("");
    }
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleSelectionChanged = (selectedRows) => {
    setSelected(
      selectedRows.selectedRowsData?.sort(
        (a, b) => a.inventory_current_quantity - b.inventory_current_quantity
      ) ?? []
    );
  };

  const quantityToOrder = (item) => {
    let value =
      item.inventory_desired_quantity - item.inventory_current_quantity;

    if (value - Math.floor(value) > 0.25) {
      value = Math.ceil(value);
    } else {
      value = Math.floor(value);
    }

    value = Math.max(value, 1);

    return value;
  };

  const onItemChange = (e, itemId) => {
    const items = [...selected];
    items[itemId].new_quantity = e.target.value;
    setSelected(items);
  };

  const onOrderNumber = (e) => {
    setOrderNumber(e.target.value);
  };
  //sort data based on specific field
  // const sortData = (field, order) => {
  //   const sorted = [...selected].sort((a, b) => {
  //     if (a[field] < b[field]) {
  //       return order === 'asc' ? -1 : 1;
  //     }
  //     if (a[field] > b[field]) {
  //       return order === 'asc' ? 1 : -1;
  //     }
  //     return 0;
  //   });
  //   setSelected(sorted);
  // };

  const sortData = (field, order) => {
    const sorted = [...selected].sort((a, b) => {
      // Check for null or undefined in both values
      if (a[field] == null && b[field] == null) return 0;
      if (a[field] == null) return order === "asc" ? 1 : -1;
      if (b[field] == null) return order === "asc" ? -1 : 1;

      // Normal comparison if neither value is null
      if (a[field] < b[field]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    setSelected(sorted);
  };

  //event handler to sort data
  const handleSort = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(field, isAsc ? "desc" : "asc");
  };

  return (
    <>
      <h2 className={"content-block"}>Reordering</h2>
      <div className={"content-block"}>
        <Modal open={loading}>
          <Backdrop open={loading}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" />
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Loading...
              </Typography>
            </div>
          </Backdrop>
        </Modal>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-b0etween",
            mb: 2,
          }}
        >
          <Button
            variant="contained"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Stepper sx={{ minWidth: "50%" }} activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 1 && (
            <>
              <TextField
                size="small"
                variant="outlined"
                sx={{ ml: 2 }}
                placeholder="Order Number"
                value={orderNumber}
                onChange={(e) => onOrderNumber(e)}
              />
            </>
          )}
          <Button
            variant="contained"
            onClick={handleNext}
            color={
              activeStep === 0
                ? "primary"
                : activeStep === 1
                ? "error"
                : "success"
            }
            sx={{ ml: 3 }}
          >
            {activeStep === 0 || activeStep === 1
              ? "Next"
              : activeStep === 2
              ? "Reorder"
              : "Refresh"}
          </Button>
          {activeStep === 3 && (
            <Button sx={{ ml: 3 }} color="success" onClick={handlePrint}>
              Export to PDF
            </Button>
          )}
        </Box>
        <Box sx={{ display: activeStep === 0 ? "flex" : "none", gap: "24px" }}>
          <Autocomplete
            sx={{
              minWidth: "300px",
              maxWidth: "300px",
            }}
            value={reseller}
            getOptionLabel={(option) => option.reseller_company_name || ""}
            onChange={(event, newValue) => {
              setReseller(newValue);
            }}
            size="small"
            options={resellerList}
            renderInput={(params) => (
              <TextField {...params} label="Distributor" />
            )}
          />
          <Autocomplete
            sx={{
              minWidth: "300px",
              maxWidth: "300px",
            }}
            value={location}
            getOptionLabel={(option) => option.location_name || ""}
            onChange={(event, newValue) => {
              setLocation(newValue);
            }}
            size="small"
            options={locations}
            renderInput={(params) => <TextField {...params} label="Location" />}
          />
        </Box>
        <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
          <DataGrid
            dataSource={dataSource}
            className={"dx-card wide-card"}
            showBorders={true}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnResizing={true}
            selectedRowKeys={selected}
            onOptionChanged={(e) => {
              if (
                e.fullName.substr(e.fullName.indexOf(".") + 1) ===
                  "filterValue" ||
                e.fullName.substr(e.fullName.indexOf(".") + 1) ===
                  "selectedFilterOperation" ||
                e.fullName === "searchPanel.text"
              ) {
                e.component.on("contentReady", () => {
                  e.component.off("contentReady");
                  let grid = e.component;
                  let selectedKeys = grid.getSelectedRowKeys();
                  let rowsToDeselect = [];
                  for (let i = 0; i < selectedKeys.length; i++) {
                    if (grid.getRowIndexByKey(selectedKeys[i]) < 0) {
                      rowsToDeselect.push(selectedKeys[i]);
                    }
                  }
                  if (rowsToDeselect?.length > 0)
                    grid.deselectRows(rowsToDeselect);
                });
              }
            }}
            onSelectionChanged={handleSelectionChanged}
          >
            <Paging defaultPageSize={25} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Scrolling mode="standard" />
            <Column
              dataField={`manufacturer_name`}
              caption={"Manufacturer"}
              dataType={"string"}
            />
            <Column
              dataField={`brand_name`}
              caption={`Brand`}
              dataType={"string"}
            />
            <Column
              dataField={`category_name`}
              caption={`Category`}
              dataType={"string"}
            />
            <Column
              dataField={"product_name"}
              caption={"Item"}
              dataType={"string"}
            />
            <Column
              dataField={`inventory_quantity_needed`}
              caption={`Order Quantity`}
              dataType={`number`}
              sortOrder={`desc`}
              customizeText={customizeNumeric}
            />
            <Column
              dataField={"inventory_current_quantity"}
              caption={"Item Count"}
              dataType={`number`}
              customizeText={customizeNumeric}
            />
            <Selection
              mode="multiple"
              // selectAllMode={allMode}
              showCheckBoxesMode="onClick"
            />
            <Column
              dataField={"inventory_desired_quantity"}
              caption={`Max Count`}
              dataType={`number`}
            />
            <Column
              dataField={"inventory_last_adjustment_quantity"}
              caption={`Last Order Count`}
              dataType={`number`}
              customizeText={customizeNumeric}
            />
            <Column
              dataField={"inventory_last_changed_timestamp"}
              caption={"Last Ordered"}
              customizeText={adjustToLocalTime}
            />
          </DataGrid>
        </Box>
        <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => handleSort("location_name")}
                    className="column-header"
                  >
                    <span className="column-title">Location</span>
                    <span
                      className="sort-icon"
                      style={{
                        visibility:
                          sortField === "location_name" ? "visible" : "hidden",
                      }}
                    >
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  </TableCell>

                  <TableCell
                    onClick={() => handleSort("reseller_company_name")}
                    className="column-header"
                  >
                    <span className="column-title">Reseller</span>
                    <span
                      className="sort-icon"
                      style={{
                        visibility:
                          sortField === "reseller_company_name"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  </TableCell>

                  <TableCell
                    onClick={() => handleSort("manufacturer_name")}
                    className="column-header"
                  >
                    <span className="column-title">Manufacturer</span>
                    <span
                      className="sort-icon"
                      style={{
                        visibility:
                          sortField === "manufacturer_name"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  </TableCell>

                  <TableCell
                    onClick={() => handleSort("brand_name")}
                    className="column-header"
                  >
                    <span className="column-title">Brand</span>
                    <span
                      className="sort-icon"
                      style={{
                        visibility:
                          sortField === "brand_name" ? "visible" : "hidden",
                      }}
                    >
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  </TableCell>

                  <TableCell
                    onClick={() => handleSort("category_name")}
                    className="column-header"
                  >
                    <span className="column-title">Category</span>
                    <span
                      className="sort-icon"
                      style={{
                        visibility:
                          sortField === "category_name" ? "visible" : "hidden",
                      }}
                    >
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  </TableCell>

                  <TableCell
                    onClick={() => handleSort("product_name")}
                    className="column-header"
                  >
                    <span className="column-title">Item</span>
                    <span
                      className="sort-icon"
                      style={{
                        visibility:
                          sortField === "product_name" ? "visible" : "hidden",
                      }}
                    >
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  </TableCell>

                  <TableCell
                    onClick={() => handleSort("inventory_current_quantity")}
                    className="column-header"
                  >
                    <span className="column-title">Item Count</span>
                    <span
                      className="sort-icon"
                      style={{
                        visibility:
                          sortField === "inventory_current_quantity"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  </TableCell>

                  <TableCell
                    onClick={() => handleSort("inventory_desired_quantity")}
                    className="column-header"
                  >
                    <span className="column-title">Max Count</span>
                    <span
                      className="sort-icon"
                      style={{
                        visibility:
                          sortField === "inventory_desired_quantity"
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      {sortOrder === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </span>
                  </TableCell>

                  <TableCell>Order Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selected.map((item, i) => {
                  const labelId = `enhanced-table-checkbox-${i}`;
                  return (
                    <TableRow
                      key={`item.inventory_product_id-${i}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      hover
                      tabIndex={-1}
                    >
                      <TableCell id={labelId} scope="row">
                        {item.location_name}
                      </TableCell>
                      <TableCell>{item.reseller_company_name || "-"}</TableCell>
                      <TableCell>{item.manufacturer_name}</TableCell>
                      <TableCell>{item.brand_name || "-"}</TableCell>
                      <TableCell>{item.category_name || "-"}</TableCell>
                      <TableCell>{item.product_name}</TableCell>
                      <TableCell
                        sx={{
                          color: getColor(item.inventory_current_quantity),
                        }}
                      >
                        {item.inventory_current_quantity?.toFixed(2) || "-"}
                      </TableCell>
                      <TableCell>
                        {item.inventory_desired_quantity || "-"}
                      </TableCell>
                      <TableCell>
                        <Input
                          type="number"
                          size="small"
                          variant="outlined"
                          value={item.new_quantity}
                          onChange={(e) => onItemChange(e, i)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            display: activeStep === 3 ? "block" : "none",
            boxShadow: "1px 3px 10px 1px #00000026",
            width: "fit-content",
            background: "white",
            minHeight: "1000px",
            margin: "auto",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <Box
            ref={resultTableRef}
            sx={{
              padding: "0 2.5rem",
              "& td, th": {
                border: "1px solid black !important",
              },
              "& th": {
                borderBottom: "2px !important",
              },
            }}
          >
            <Typography color="black" variant="h5" gutterBottom component="div">
              {`${location?.org_name} ${location?.location_name} - Order for ${
                reseller?.reseller_company_name || ""
              } Distributor`}
            </Typography>
            <Typography color="black" variant="h7" component="div">
              {`Submitted on ${new Date().toLocaleDateString()}`}
            </Typography>
            <Typography color="black" variant="h7" gutterBottom component="div">
              {`Order Number: ${orderNumber}`}
            </Typography>
            <TableContainer
              sx={{
                minWidth: 650,
                color: "black",
                border: "none",
                borderRadius: 0,
                boxShadow: "none",
              }}
              component={Paper}
            >
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell>Manufacturer</TableCell>
                    <TableCell>Brand</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell>Order Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selected.map((item, i) => {
                    const labelId = `enhanced-table-checkbox-${i}`;
                    return (
                      <TableRow
                        key={`item.inventory_product_id-${i}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        hover
                        tabIndex={-1}
                      >
                        <TableCell id={labelId} scope="row">
                          {item.location_name}
                        </TableCell>
                        <TableCell>{item.manufacturer_name}</TableCell>
                        <TableCell>{item.brand_name || "-"}</TableCell>
                        <TableCell>{item.product_name}</TableCell>
                        <TableCell>{item.new_quantity}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </div>
    </>
  );
};
