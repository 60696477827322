import { Scheduler } from "devextreme-react";

export const weekConfig = {
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  weekStartOn: 0,
  startHour: 9,
  endHour: 20,
  step: 15,
  navigation: true,
  disableGoToDay: false,
};

export const dayConfig = {
  startHour: 9,
  endHour: 20,
  step: 15,
  navigation: true,
};

export const resourceFields = {
  idField: "user_id",
  textField: "user_name",
  subTextField: "location_name",
  avatarField: "user_name",
  colorField: "color",
};

export const getRandomColor = () => {
  const red = Math.floor(Math.random() * 151) + 50;
  const green = Math.floor(Math.random() * 151) + 50;
  const blue = Math.floor(Math.random() * 151) + 50;
  const color = `rgba(${red}, ${green}, ${blue})`;
  return color;
};

export const groupedSchedules = (data) => {
  let nextStartTime;

  const groupedData = data.reduce((acc, schedule) => {
    const total =
      Date.parse(schedule.scheduleEnd) - Date.parse(schedule.scheduleStart);
    const current =
      Date.parse(schedule.start) - Date.parse(schedule.scheduleStart);

    const size = Date.parse(schedule.end) - Date.parse(schedule.start);

    // const key = `${schedule.start}-${schedule.end}-${schedule.user_id}-${schedule.client_id}-${schedule.location_id}`;
    if (schedule.appointmentId === null) {
      acc[`x-${schedule.event_id}`] = {
        appointmentId: schedule.appointmentId,
        start: new Date(schedule.start),
        end: new Date(schedule.end),
        event_id: schedule.appointmentId,
        title: schedule.client_name,
        user_id: schedule.user_id,
        user_name: schedule.user_name,
        client: {
          client_id: schedule.client_id,
          client_name: schedule.client_name,
        },
        location: {
          location_id: schedule.location_id,
          location_name: schedule.location_name,
          organization_id: schedule.organization_id,
        },
        uniqueServices: [
          {
            service_id: schedule.service_id,
            title: schedule.title,
            service_shortcut: schedule.service_shortcut,
            service_color: schedule.service_color,
          },
        ],
        services: [
          {
            event_id: schedule.event_id,
            service_id: schedule.service_id,
            title: schedule.title,
            service_shortcut: schedule.service_shortcut,
            service_color: schedule.service_color,
            serviceStart: schedule.start,
            serviceEnd: schedule.end,
            topOffset: (current / total) * 100,
            size: (size / total) * 100,
          },
        ],
      };
    } else if (acc[schedule.appointmentId]) {
      // If the schedule already exists in the accumulator, add the service to its array
      acc[schedule.appointmentId].services.push({
        event_id: schedule.event_id,
        service_id: schedule.service_id,
        title: schedule.title,
        service_shortcut: schedule.service_shortcut,
        service_color: schedule.service_color,
        serviceStart: schedule.start,
        serviceEnd: schedule.end,
        topOffset: (current / total) * 100,
        size: (size / total) * 100,
      });
      if (
        !acc[schedule.appointmentId].uniqueServices.find(
          (x) => x.service_id === schedule.service_id
        )
      ) {
        acc[schedule.appointmentId].uniqueServices.push({
          service_id: schedule.service_id,
          title: schedule.title,
          service_shortcut: schedule.service_shortcut,
          service_color: schedule.service_color,
        });
      }
    } else {
      // Otherwise, create a new schedule with an array containing the service
      acc[schedule.appointmentId] = {
        appointmentId: schedule.appointmentId,
        start: new Date(schedule.scheduleStart),
        end: new Date(schedule.scheduleEnd),
        event_id: schedule.appointmentId,
        title: schedule.client_name,
        user_id: schedule.user_id,
        user_name: schedule.user_name,
        client: {
          client_id: schedule.client_id,
          client_name: schedule.client_name,
        },
        location: {
          location_id: schedule.location_id,
          location_name: schedule.location_name,
          organization_id: schedule.organization_id,
        },
        uniqueServices: [
          {
            service_id: schedule.service_id,
            title: schedule.title,
            service_shortcut: schedule.service_shortcut,
            service_color: schedule.service_color,
          },
        ],
        services: [
          {
            event_id: schedule.event_id,
            service_id: schedule.service_id,
            title: schedule.title,
            service_shortcut: schedule.service_shortcut,
            service_color: schedule.service_color,
            serviceStart: schedule.start,
            serviceEnd: schedule.end,
            topOffset: (current / total) * 100,
            size: (size / total) * 100,
          },
        ],
      };
    }

    return acc;
  }, {});

  const result = Object.values(groupedData);

  return result;
};

export const SchedulerAPI = {
  //get: "gateway/68nkedd2v8", //Uses production/Live API
  // get: `gateway/fh3c0gu345`, //Uses Development API
  get: "scheduler",
  //create: "gateway/4l0utv3x1g",
  modify: "scheduler/updateAppointment",
  delete: "scheduler/deleteAppointment",
  move: "scheduler/updateAppointment",
};
