import { useState, useEffect } from "react";
import { Chart, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  registerables
} from 'chart.js';
import "./bar3.style.scss";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ...registerables
);

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  borderRadius: 3,
};

export default function Bar3({ value, onClick }) {
  const [data, setData] = useState({ datasets: [] });
  const [options, setOptions] = useState(defaultOptions);
  const [delayed, setDelayed] = useState(false);

  useEffect(() => {
    const values = {
      label: [],
      bar: [],
      line: []
    }

    value.sort((a, b) => b.inventory_current_quantity - a.inventory_current_quantity).map((item) => {
      values.label.push(item.product_name);
      values.bar.push(item.inventory_current_quantity.toFixed(2));
      values.line.push(item.inventory_desired_quantity);
    });

    const newData = {
      labels: values.label,
      datasets: [
        {
          type: 'bar',
          barPercentage: 1.15,
          categorySpacing: 0,
          fill: false,
          data: values.bar,
        },
        {
          type: 'line',
          fill: false,
          data: values.line,
          backgroundColor: ["#00000000"],
          borderColor: '#00000000',
        },
      ],
    };

    newData.datasets[0].backgroundColor = newData.datasets[0].data.map(
      (value) => (value < 0 ? "#ed0f7e" : "#0dcae7")
    );

    const newOptions = {
      ...defaultOptions,
      plugins: {
        title: {
          display: true,
          text: 'Number of Containers',
          position: 'left',
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (data) {
              if (data.datasetIndex === 0) {
                const dataIndex = data.dataIndex;
                const value = data.formattedValue;

                if (dataIndex !== 2) {
                  return `${value}`
                } else {
                  return `${value}`
                }
              } else {
                return "";
              }
            },
          },
        },
        datalabels: {
          display: true,
          color: "white",
          formatter: (value, context) => {
            if (context.datasetIndex === 1) {
              return value;
            }
            else {
              return "";
            }
          },
          anchor: "end",
          offset: -10,
          align: 'center',
          backgroundColor: (context) => {
            return context.datasetIndex === 1 ? "black" : "transparent";
          },
          font: {
            size: 12,
            lineHeight: 0.8
          },
          padding: {
            bottom: 2,
            left: 10,
            right: 10,
          }
        },
      },
      scales: {
        y: {
          ticks: {
            stepSize: 5,
            min: -5,
          },
        },
        x: {
          ticks: {
            callback: function(value, index, ticks) {
              let label = this.getLabelForValue(value);
              return label.length > 6 ? label.slice(0, 6) + '…' : label;
            }
          },
          grid: {
            display: false,
          },
        },
      },
      animation: {
        onComplete: () => {
          setDelayed(true);
        },
        delay: (context) => {
          let delay = 0;
          if (context.type === 'data' && context.mode === 'default' && !delayed) {
            delay = context.dataIndex * 150 + context.datasetIndex * 100;
          }
          return delay;
        },
      },
    };

    setData(newData);
    setOptions(newOptions);
  }, [value]);

  return (
    <div className="bar4-body" onClick={onClick}>
      <div className="bar4-container">
        <Chart type="bar" data={data} options={options} onElementsClick={onClick} />
      </div>
    </div>
  );
}
