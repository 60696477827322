import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
} from "devextreme-react/form";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import "./create-salon-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";

export default function CreateSalonForm(props) {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const [locations, setLocations] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsName, setOrganizationsNames] = useState([]);
  const [salon, setSalon] = useState(null);
  const getOrganizationApi = "gateway/wankbr6e73"; // Just get's the user's locations
  const createLocationApi = "gateway/wp3j5kw9cl";
  const [locationNames, setLocationNames] = useState([]);
  const nameToIdMapRef = useRef({});
  let userCredentials = {
    Barcode: user.barcode,
    Role: user.userRoles,
    Organization: user.org,
    Location: user.location_id,
  };

  //get organization
  useEffect(async () => {
    const res = await baseAxios.post(getOrganizationApi, userCredentials);
    const arrayData = res.data;
    if (Array.isArray(arrayData)) {
      setOrganizations(arrayData);
    }
  }, []);

  //set Organization names
  useEffect(async () => {
    const organizationNames = organizations.map((org) => {
      // Split the string into parts
      const parts = org.split(" ");
      // Remove the first part (the ID) and join the rest back into a string
      const name = parts.slice(1).join(" ");
      return name;
    });
    setOrganizationsNames(organizationNames);
  }, [organizations]);

  const salonNameEditorOptions = {
    stylingMode: "filled",
    placeholder: "Salon Name",
  };
  const organizationEditorOptions = {
    items: organizationsName,
    value: " ",
    searchEnabled: false,
  };
  // const firstNameEditorOptions = { stylingMode: 'filled', placeholder: 'Manage First Name', };
  // const lastNameEditorOptions = { stylingMode: 'filled', placeholder: 'Manager Last Name', };
  // const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Manager Email', mode: 'email' };
  // const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
  // const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };
  // const roleEditorOptions = { items: roles, value: " ", searchEnabled: false}
  // const roles = ["Salon Admin", "Stylist", "Front Desk"];

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      if (!userCredentials.Role.includes(6)) {
        notify("User Not authorized, please check your role", "error", 5000);
        setLoading(false);
        return;
      }

      const { salonName, organization } = formData.current;

      //find org id
      let organizationId = null;
      for (const org of organizations) {
        const [id, ...nameParts] = org.split(" ");
        const name = nameParts.join(" ").trim();

        if (name === organization.trim()) {
          organizationId = id;
          break;
        }
      }

      const data = {
        salonName,
        organizationId,
        role: userCredentials.Role,
      };

      const res = await baseAxios.post(createLocationApi, data);

      if (res.status === 200) {
        notify("Success", "success", 3000);
        formData.current = { salonName: "", organization: "" };
      } else {
        notify("Operation failed", "error", 5000);
      }
      setLoading(false);
    },
    [history, organizations]
  );

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <SingleCard title="Add New Salon">
      <form className={"create-salon-form"} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <Item
            dataField={"salonName"}
            editorType={"dxTextBox"}
            editorOptions={salonNameEditorOptions}
          >
            <Label visible={false} />
          </Item>

          <Item
            dataField={"organization"}
            editorType={"dxSelectBox"}
            editorOptions={organizationEditorOptions}
          >
            <RequiredRule message={"User must be assigned a Organization"} />
          </Item>

          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Create a new Salon"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
          {/* <Item>
          <div className={'login-link'}>
            Have an account? <Link to={'/login'}>Sign In</Link>
          </div>
        </Item> */}
        </Form>
      </form>
    </SingleCard>
  );
}
