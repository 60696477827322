import React, { useState, useEffect, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
  Export,
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  TotalItem,
  Summary,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Popup, Button } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";

function addDollarSign(data) {
  var cost = data.value;
  if (cost == "undefined" || cost == undefined || cost < 0.01) {
    cost = 0.0;
  }
  return `$${cost}`;
}

export default function SearchServices(props) {
  const [organizations, setOrganizations] = useState([]);
  const [organizationsName, setOrganizationsNames] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);
  const [serviceName, setServiceName] = useState([]);
  const [serviceId, setServiceId] = useState([]);

  const history = useHistory();

  const searchServiceAPi = "gateway/ccme69ih27"; //search services under an org id
  const getOrganizationApi = "gateway/wankbr6e73"; // Just get's the user's locations

  const { user } = useAuth();

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  //get organization
  useEffect(async () => {
    const res = await baseAxios.post(getOrganizationApi, userCredentials);
    //transform response into an array
    const arrayData = res.data;
    if (Array.isArray(arrayData)) {
      setOrganizations(arrayData);
    }
  }, []);

  //set user organization name
  useEffect(() => {
    // Find the organization that matches the ID in userCredentials.Organization
    const matchingOrg = organizations.find((org) => {
      const parts = org.split(" ");
      return parts[0] === userCredentials.Organization.toString();
    });

    // Extract the name of the matching organization
    let organizationName = "";
    if (matchingOrg) {
      const parts = matchingOrg.split(" ");
      organizationName = parts.slice(1).join(" ");
    }
    setOrganizationsNames(organizationName);
  }, [organizations, userCredentials.Organization]);

  JSON.stringify(userCredentials);

  const dataSource = useMemo(
    () =>
      new CustomStore({
        load: function () {
          return baseAxios
            .post(searchServiceAPi, userCredentials)
            .then((result) => {
              let data = result.data;
              // Check if data is an array
              if (Array.isArray(data)) {
                // Add organization name to each item
                const dataWithOrganization = data.map((item) => ({
                  ...item,
                  organizationName: organizationsName,
                }));
                setDataLoaded(true);

                return dataWithOrganization;
              } else {
                console.error("Data is not an array:", data);
                return [];
              }
            });
        },
        key: ["service_id"],
      }),
    [organizationsName]
  );

  const handleRowClick = (e) => {
    const formServiceName = e.data.service_name;
    const formServiceId = e.data.service_id;
    setServiceName(formServiceName);
    setServiceId(formServiceId);
    setIsConfirmationPopupVisible(true);
  };

  const handleConfirm = async () => {
    history.push({
      pathname: "/edit-service",
      search: `?serviceId=${serviceId}`, // Add the query parameter
    });
    setIsConfirmationPopupVisible(false);
  };

  const handleCancel = () => {
    setIsConfirmationPopupVisible(false);
  };

  const handleAddNewService = () => {
    history.push("/create-service");
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      // 'before' positions the button before other toolbar items (to the left)
      // 'after' would place it after other items (to the right)
      location: "after",
      widget: "dxButton",
      options: {
        icon: "add",
        text: "Add New Service",
        onClick: handleAddNewService,
      },
    });
  };

  const orderDateFormat = "M/d/yy";

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Services</h2>
      <Popup
        visible={isConfirmationPopupVisible}
        onHiding={handleCancel}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Edit service"
        showCloseButton={true}
        width={"30%"} // Adjust width as needed
        height={"30%"} // Adjust height as needed
      >
        <p>
          Are you sure you want to edit <strong>{serviceName}</strong>?
        </p>
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            text="Cancel"
            onClick={handleCancel}
            style={{ marginRight: "10px" }}
          />
          <Button text="Confirm" type="default" onClick={handleConfirm} />
        </div>
      </Popup>

      <DataGrid
        dataSource={dataSource}
        onToolbarPreparing={onToolbarPreparing}
        className={"dx-card wide-card"}
        showBorders={true}
        onRowClick={handleRowClick}
        focusedRowEnabled={dataLoaded}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Paging defaultPageSize={25} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />

        <SearchPanel
          visible={true}
          highlightCaseSensitive={false}
          highlightSearchText={false}
          width="500px"
        />
        <HeaderFilter visible={true} />
        {/* <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true}></Editing> */}
        <Scrolling mode="standard" />
        <Column
          dataField="organizationName"
          caption="Organization Name"
          allowSearch={false}
        />
        <Column
          dataField="service_name"
          caption="Service Name"
          allowSearch={true}
        />
        <Column
          dataField="service_shortcut"
          caption="Service Shortcut"
          allowSearch={true}
        />
        <Column
          dataField="description"
          caption="Description"
          allowSearch={false}
        />
        <Column
          dataField="service_status"
          caption="Service Status"
          allowSearch={false}
        />
      </DataGrid>
      {/* </Suspense> */}
    </React.Fragment>
  );
}
