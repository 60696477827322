import {
  Autocomplete,
  Box,
  Button,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { baseAxios } from "../../utils/config";

export default () => {
  const barcodeRef = useRef(null);
  const [items, setItems] = useState([]);
  const [salon, setSalon] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [qtyReceived, setQtyReceived] = useState(1);
  const [barcode, setBarcode] = useState("");
  const [locations, setLocations] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && barcodeRef.current) {
        barcodeRef.current.focus();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    (async function () {
      const productsRes = await baseAxios.get(`/inventory`);
      setProducts(productsRes.data.products);

      const locationsRes = await baseAxios.get(`/organization/-/locations`);
      setLocations(locationsRes.data.locations);
    })();
  }, []);

  const addNewItem = () => {
    const product = products.find((item) => item.product_upc_code == barcode);
    if (product) {
      setItems([
        {
          barcode: barcode,
          product: product,
          qtyReceived: qtyReceived,
          note: "",
        },
        ...items,
      ]);
    } else {
      setItems([
        {
          barcode: barcode,
          qtyReceived: qtyReceived,
          note: "New product",
        },
        ...items,
      ]);
    }
  };

  const modifyItem = (itemId) => {
    const item = items[itemId];
    items.splice(itemId, 1);
    setItems([
      {
        barcode: barcode,
        product: item.product,
        qtyReceived: qtyReceived / 1 + item.qtyReceived / 1,
        note: item.note,
      },
      ...items,
    ]);
  };

  const onItemChange = (e, itemId) => {
    const newItems = [...items];
    newItems[itemId].qtyReceived = e.target.value;
    setItems(newItems);
  };

  const onItemNoteChange = (e, itemId) => {
    const newItems = [...items];
    const sanitizedInput = e.target.value
      .toString()
      .replace(/[^a-zA-Z0-9\s]/g, "");
    newItems[itemId].note = sanitizedInput;
    setItems(newItems);
  };

  const onBarcodeChange = (e) => {
    setBarcode(e.target.value);
  };

  const onScan = (e) => {
    const barcode = e.target.value;

    if (e.key === "Enter" && barcode) {
      e.preventDefault();

      const itemId = items.findIndex(
        (item) => item.product_upc_code === barcode
      );
      if (itemId !== -1) {
        // existing Item
        modifyItem(itemId);
      } else {
        // new Item
        addNewItem();
      }

      setBarcode("");
      setQtyReceived(1);
    } else {
      setBarcode(barcode);
    }
  };

  const onComplete = async () => {
    if (items.length === 0) {
      toast.error("Please add items to receive");
      return;
    }
    if (!salon) {
      toast.error("Please select salon");
      return;
    }
    if (!orderNumber) {
      toast.error("Please input order number");
      return;
    }

    try {
      await baseAxios.post("/inventory/order", {
        location: salon,
        order_number: orderNumber,
        order_items: items,
      });

      toast.success("Success");
      setItems([]);
      setQtyReceived(1);
      setBarcode("");
      setOrderNumber("");
      barcodeRef.current.focus();
    } catch (error) {
      toast.error("An error happened. Please try again.");
    }
  };

  const onCancel = () => {
    setItems([]);
    setQtyReceived(1);
    setBarcode("");
  };

  return (
    <>
      <h2 className="content-block">ScanToReceive Inventory</h2>
      <div className="content-block">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginBottom: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <label htmlFor="barcode">Salon: </label>
              <Autocomplete
                sx={{
                  minWidth: "200px",
                  marginLeft: "0.5rem",
                }}
                value={salon}
                getOptionLabel={(option) => option.location_name || ""}
                onChange={(event, newValue) => {
                  setSalon(newValue);
                }}
                size="small"
                options={locations}
                renderInput={(params) => (
                  <TextField {...params} label="Location" />
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label>Order Number: </label>
              <TextField
                sx={{
                  minWidth: "200px",
                  marginLeft: "0.5rem",
                }}
                size="small"
                label="#"
                variant="outlined"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
              />
            </Box>
          </Box>
          <Box>
            <Button variant="contained" onClick={onComplete}>
              Complete
            </Button>
            <Button sx={{ marginLeft: "1rem" }} onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Product Barcode</TableCell>
                <TableCell>Manufacturer</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Qty on Hand</TableCell>
                <TableCell>Qty Received</TableCell>
                <TableCell>Note</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Input
                    inputRef={barcodeRef}
                    autoFocus
                    size="small"
                    label="Input Barcode"
                    variant="outlined"
                    value={barcode}
                    onChange={onBarcodeChange}
                    onKeyDown={onScan}
                  />
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  <Input
                    size="small"
                    label="Input Quantity"
                    variant="outlined"
                    type="number"
                    defaultValue={1}
                    value={qtyReceived}
                    onChange={(e) => {
                      setQtyReceived(e.target.value);
                    }}
                  />
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
              {items.map((item, i) => (
                <TableRow
                  key={item.barcode}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item.barcode}
                  </TableCell>
                  <TableCell>
                    {item.product?.manufacturer_name || "-" || "-"}
                  </TableCell>
                  <TableCell>{item.product?.brand_name || "-"}</TableCell>
                  <TableCell>{item.product?.category_name || "-"}</TableCell>
                  <TableCell>
                    {item.product?.product_type_name || "-"}
                  </TableCell>
                  <TableCell>{item.product?.product_name || "-"}</TableCell>
                  <TableCell>
                    {item.product?.inventory_current_quantity?.toFixed(2) ||
                      "-"}
                  </TableCell>
                  <TableCell>
                    <Input
                      inputProps="description"
                      type="number"
                      value={item.qtyReceived}
                      onChange={(e) => onItemChange(e, i)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Input
                      inputProps="description"
                      value={item.note}
                      onChange={(e) => onItemNoteChange(e, i)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        setItems((prev) => prev.filter((_, j) => j !== i))
                      }
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
