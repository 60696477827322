import React from "react";
import "./rolled-reports.scss";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import CustomStore from "devextreme/data/custom_store";
import TreeList, {
  Column,
  ColumnChooser,
  HeaderFilter,
  SearchPanel,
  SpeedDialAction,
  Lookup,
  Paging,
  Scrolling,
  FilterRow,
  Editing,
} from "devextreme-react/tree-list";

// import DataGrid, {
//   Export,
//   Column,
//   Pager,
//   Paging,
//   FilterRow,
//   Lookup,
//   HeaderFilter,
//   Scrolling,
//   Editing
// } from 'devextreme-react/data-grid';
import { useAuth } from "../../contexts/auth";
import { Export } from "devextreme-react/chart";
import { baseAxios } from "../../utils/config";

const expandedKeys = [];
const selectedKeys = [];
const URL = "gateway/qy5b30mnt5";

function adjustToLocalTime(data) {
  if (data.value == null) {
    return ``;
  }
  var formattedTime = data.value;
  formattedTime = new Date(data.value).toLocaleTimeString(`en-us`, {
    hour: `numeric`,
    minute: `2-digit`,
  });
  return `${formattedTime}`;
}
function addDollarSign(data) {
  var cost = data.value;
  if (cost == "undefined" || cost == undefined || cost < 0.01) {
    cost = 0.0;
  }
  return `$${cost}`;
}

async function sendBatchRequest(url, changes) {
  const result = await baseAxios.post(url, { changes });
  if (result.status !== 200) {
    const json = result.data;
    throw json.Message;
  }
}
async function processBatchRequest(url, changes, component) {
  await sendBatchRequest(url, changes);
  await component.refresh(true);
  component.cancelEditData();
}

export default function RolledReports(props) {
  const { user } = useAuth();
  const { location_name } = props?.location?.state || {};
  // const idToken = JSON.stringify(user.idToken.payload);

  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  if (user.role === "Stylist") {
    var isStylist = true;
  }

  JSON.stringify(userCredentials);
  //console.log(userCredentials);

  //const workingApi = "gateway/ozvhlj9n60"; //This is the live API
  const workingApi = `gateway/gmoboq0m2j`; //This is the development API
  const dataSource = new CustomStore({
    key: "transaction_entry_id",
    load: function () {
      return baseAxios.post(workingApi, userCredentials).then((result) => {
        console.log(userCredentials);
        return result.data;
      });
    },
    cacheRawData: true,
  });
  const orderDateFormat = "M/d/yy";

  function onEditorPreparing(e) {
    // if (e.dataField !== 'client'){
    //   e.cancel = true;
    // }
  }

  // const changesText = React.useMemo(() => JSON.stringify(state.changes.map((change) => ({
  //   type: change.type,
  //   key: change.type !== 'insert' ? change.key : undefined,
  //   data: change.data,
  // })), null, ' '), [state.changes])

  const onSaving = React.useCallback((e) => {
    e.cancel = true;

    if (e.changes.length) {
      e.promise = processBatchRequest(URL, e.changes, e.component);
    }
  }, []);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Rolled Transactions</h2>
      <p className="content-block">
        Transactions per stylist, rolled up to view totals and clients.
      </p>
      {/* <Suspense fallback={<LoadIndicator />} > */}
      {/* <DataGrid
      //dataSource={service.getSales()}
      dataSource={dataSource}
      //keyExpr="id"
      //keyExpr="transaction_id"
      className={'dx-card wide-card'}
      showBorders={true}
      //focusedRowEnabled={true}
      defaultFocusedRowIndex={0}
      columnAutoWidth={true}
      columnHidingEnabled={true} 
      allowColumnResizing = {true}
    >
        <Paging defaultPageSize={25} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={false} />
        <HeaderFilter visible ={true}/>
        <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true}></Editing>
        <Scrolling mode="standard"/>
        <Column dataField={'company'} fixed={true} />
        <Column dataField={'salon'} fixed={true}/>
        <Column dataField={'user_id'} fixed={true} />
        <Column dataField={'transaction_end'} hidingPriority={5} />
        <Column dataField={'recipe_id'} hidingPriority={0} />
        <Column dataField={'transaction_id'} hidingPriority={0} /> */}
      {/* <Column dataField={'product_id'} hidingPriority={6} /> */}
      {/* <Column dataField={'transaction_start'} fixed={true} sortOrder ={'desc'} />
        <Column dataField={'total_amount'} hidingPriority={7}>
          <format type="fixedPoint" precision={2} />
        </Column>
        <Column dataField={'cost'}>
          <format type="fixedPoint" precision={2} />
        </Column>
        <Export enabled={true} allowExportSelectedData={true}/>
    </DataGrid>
    <h2>Rolling reports</h2> */}
      <TreeList
        dataSource={dataSource}
        showBorders={true}
        columnAutoWidth={true}
        wordWrapEnabled={true}
        defaultExpandedRowKeys={expandedKeys}
        defaultSelectedRowKeys={selectedKeys}
        rootValue={"-1"}
        keyExpr="transaction_entry_id"
        parentIdExpr="transaction_entry_transaction_id"
        expandNodesOnFiltering={true}
        onEditorPreparing={onEditorPreparing}
        onSaving={onSaving}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Editing
          allowUpdating={true}
          mode="batch"
          // changes={state.changes}
        />

        <Scrolling mode={"standard"} />
        <SearchPanel visible={true} width={250} />
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />

        {/* <Selection mode='multiple'/> */}
        <ColumnChooser enabled={false} />
        <Paging enabled={true} defaultPageSize={25} />

        <Column dataField={"org_name"} caption={"Org name"} />
        <Column
          dataField={"location_name"}
          caption={"Salon"}
          filterValue={location_name || ""}
        />
        <Column
          dataField={"user_name"}
          caption={"Stylist"}
          visible={!isStylist}
        />
        <Column
          dataField={"transaction_start"}
          caption={"Date"}
          dataType={"date"}
          hidingPriority={10}
          //fixed={true}
          sortOrder={"desc"}
          sortIndex={1}
          format={orderDateFormat}
        >
          {/* <format year="2-digit" month="narrow" day="2-digit"/> */}
        </Column>
        <Column
          dataField={"transaction_entry_start"}
          hidingPriority={9}
          caption={"Start"}
          sortOrder={"desc"}
          sortIndex={2}
          customizeText={adjustToLocalTime}
          //fixed={true}
        />
        <Column dataField={"transaction_end"} caption={"End"} visible={false} />
        <Column
          caption={"Client Name"}
          dataField={"transaction_client_name"}
          hidingPriority={8}
        />
        {/* <Column dataField={'recipe_id'} hidingPriority={0} /> */}
        {/* <Column dataField={'transaction_id'} hidingPriority={1} /> */}
        <Column
          dataField={"product_cost"}
          caption={"Cost"}
          dataType={"currency"}
          hidingPriority={7}
          customizeText={addDollarSign}
        >
          <format type="fixedPoint" precision={2} />
        </Column>
        <Column
          dataField={"product_alias"}
          hidingPriority={6}
          caption={"Product Id"}
        />
        {/* <Column dataField={'org_name'} fixed={true} visible={false} /> */}
        <Column
          dataField={"transaction_entry_product_amount"}
          hidingPriority={5}
          caption={"Amount (g)"}
        >
          <format type="fixedPoint" precision={2} />
        </Column>
      </TreeList>
      {/* </Suspense> */}
    </React.Fragment>
  );
}
