import React from "react";
import "./Salon-reports.scss";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { useState, useRef, useCallback } from "react";

import DataGrid, {
  Export,
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  TotalItem,
  Summary,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "../../contexts/auth";
import { DropDownBox, Form, List, DateBox, Button } from "devextreme-react";
import { baseAxios } from "../../utils/config";

export default function SalonReports() {
  const api = "gateway/ougti7d250"; //Uses new API specifically for this.
  //const api = 'gateway/ixinslynhh'; //Uses TransactionsScan Api - This one should be used most of the time
  //const api = "gateway/47nspi6n58/"; //Uses the RolledTransactionsScan API
  const { user } = useAuth();

  // React.useEffect(() => {

  // }, []);
  let salonList = ["Fairfax", "Rosslyn", "Lee Heights"];
  const [salonListDataSource, setSalonListDataSource] = useState(salonList);
  const listRef = useRef(null);
  const [selectedSalon, setSelectedSalon] = useState(salonList[0]);

  var now = new Date();
  var oneWeekAgo = new Date();
  oneWeekAgo.setDate(now.getDate() - 7);

  const dropDownBoxRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const dataGridRef = useRef(null);

  const changeDropDownBoxValue = useCallback((arg) => {
    setSelectedSalon(arg.addedItems[0]);
    dropDownBoxRef.current.instance.close();
  }, []);

  // const onStartDateValueChanged = useCallback((e) => {
  //   setStartDate(e.value)
  // }, [])

  // const onEndDateValueChanged = useCallback((e) => {
  //   setEndDate(e.value)
  // }, [])

  const onSalonValueChanged = useCallback((e) => {
    setSelectedSalon(e.value);
  }, []);

  const onClick = useCallback(() => {
    dataGridRef.current.instance.refresh();
  });

  const gridDataSource = new CustomStore({
    load: function () {
      var startDate = startDateRef.current.instance.option("value");
      var endDate = endDateRef.current.instance.option("value");
      console.log(startDate, endDate, selectedSalon);
      let apiArguments = {
        Barcode: user.barcode,
        Role: user.role,
        Salon: selectedSalon,
        StartDate: startDate,
        EndDate: endDate,
      };
      JSON.stringify(apiArguments);
      console.log(apiArguments);
      return baseAxios.post(api, apiArguments).then((result) => {
        return result.data;
      });
    },
  });

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Salon Container Usage</h2>
      <p className="content-block">
        Total amount of product used at a given salon over specified date range,
        broken down by product.
      </p>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div id="input-box">
            <h2 className={"content-block"}>Salon: </h2>
            <DropDownBox
              dataSource={salonListDataSource}
              placeholder="Select a Salon..."
              onValueChanged={onSalonValueChanged}
              value={selectedSalon}
              ref={dropDownBoxRef}
            >
              <List
                selectionMode="single"
                ref={listRef}
                dataSource={salonListDataSource}
                onSelectionChanged={changeDropDownBoxValue}
              />
            </DropDownBox>
            <div id="date-selection-box">
              <h2 className={"content-block"}>Start Date: </h2>
              <DateBox
                type="date"
                value={oneWeekAgo}
                label="Start Date"
                labelMode="floating"
                ref={startDateRef}
                // onValueChanged={onStartDateValueChanged}
              />
              <h2 className={"content-block"}>End Date: </h2>
              <DateBox
                type="date"
                value={now}
                label="End Date"
                labelMode="floating"
                max={now}
                ref={endDateRef}
                //onValueChanged={onEndDateValueChanged}
              />
            </div>
            <Button text="Run Report" onClick={onClick}></Button>
          </div>

          {/* <Suspense fallback={<LoadIndicator />} > */}
          <DataGrid
            //dataSource={service.getSales()}
            dataSource={gridDataSource}
            ref={dataGridRef}
            //keyExpr="id"
            //keyExpr="transaction_id"
            className={"dx-card wide-card"}
            showBorders={true}
            //focusedRowEnabled={true}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            allowColumnResizing={true}
          >
            <Paging defaultPageSize={25} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            {/* <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true}></Editing> */}
            <Scrolling mode="standard" />

            {/* <Column dataField={'company'} fixed={true} visible={true}/> */}
            <Column dataField={"salon"} />
            {/* <Column dataField={'user_id'} fixed={true} /> */}
            {/* <Column dataField={'transaction_start_time'} hidingPriority={5} caption={'Start'} sortOrder={'desc'} sortIndex={2}/> */}
            {/* <Column dataField={'recipe_id'} hidingPriority={0} /> */}
            {/* <Column dataField={'transaction_id'} hidingPriority={1} /> */}
            <Column dataField={"product_id"} hidingPriority={6} />
            {/* <Column dataField={'transaction_start'}
            caption={'Date'} 
            dataType={'date'} 
            hidingPriority={2} 
            fixed={true} 
            sortOrder ={'desc'}
            sortIndex ={1}
            format={orderDateFormat}
            /> */}
            {/* <Column
            dataField={'transaction_end'}
            caption={'End'}
          /> */}

            {/* <Column dataField={'amount'} hidingPriority={7} caption={"Amount (g)"} dataType={'number'} alignment={'left'}>
            <format type="fixedPoint" precision={2} />
          </Column> */}
            <Column
              dataField={"containers_used"}
              sortOrder={"desc"}
              alignment={"left"}
            >
              <format type="fixedPoint" precision={2} />
            </Column>
            {/* <Column
            dataField={'device'}
          /> */}
            <Export enabled={true} allowExportSelectedData={false} />
            {/* <Summary>
            <TotalItem
              column={'cost'}
              summaryType={"sum"}
              alignment={"left"}/>
          </Summary> */}
          </DataGrid>
          {/* </Suspense> */}
        </div>
      </div>
    </React.Fragment>
  );
}
