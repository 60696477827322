export const getFields = ({ stylists }) => [
    {
        name: "user_id",
        type: "select",
        default: stylists?.at(0)?.user_id || "",
        options: stylists?.map((res) => {
            return {
                id: res.user_id,
                text: res.location_name,
                value: res //Should match "name" property
            };
        }),
        config: { label: "Stylist", required: true }
    }
];