import React, { useRef } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import Form, { Item, Label, GroupItem } from "devextreme-react/form";

export const UserServiceForm = ({
  service,
  formData,
  loading,
  index,
  deleteService,
}) => {
  const formRef = useRef(null);

  const servicingTimesOptions = [
    {
      value: 15,
      text: `15 minutes`,
    },
    {
      value: 30,
      text: `30 minutes`,
    },
    {
      value: 45,
      text: `45 minutes`,
    },
    {
      value: 60,
      text: `1 hour`,
    },
    {
      value: 75,
      text: `1 hour 15 minutes`,
    },
    {
      value: 90,
      text: `1 hour 30 minutes`,
    },
    {
      value: 105,
      text: `1 hour 45 minutes`,
    },
    {
      value: 120,
      text: `2 hours`,
    },
    {
      value: 135,
      text: `2 hours 15 minutes`,
    },
    {
      value: 150,
      text: `2 hours 30 minutes`,
    },
    {
      value: 165,
      text: `2 hours 45 minutes`,
    },
    {
      value: 180,
      text: `3 hours`,
    },
    {
      value: 195,
      text: `3 hours 15 minutes`,
    },
    {
      value: 210,
      text: `3 hours 30 minutes`,
    },
    {
      value: 225,
      text: `3 hours 45 minutes`,
    },
    {
      value: 240,
      text: `4 hours`,
    },
    {
      value: 255,
      text: `4 hours 15 minutes`,
    },
    {
      value: 270,
      text: `4 hours 30 minutes`,
    },
    {
      value: 285,
      text: `4 hours 45 minutes`,
    },
    {
      value: 300,
      text: `5 hours`,
    },
    {
      value: 315,
      text: `5 hours 15 minutes`,
    },
    {
      value: 330,
      text: `5 hours 30 minutes`,
    },
    {
      value: 345,
      text: `5 hours 45 minutes`,
    },
    {
      value: 360,
      text: `6 hours`,
    },
    {
      value: 375,
      text: `6 hours 15 minutes`,
    },
    {
      value: 390,
      text: `6 hours 30 minutes`,
    },
    {
      value: 405,
      text: `6 hours 45 minutes`,
    },
    {
      value: 420,
      text: `7 hours`,
    },
    {
      value: 435,
      text: `7 hours 15 minutes`,
    },
    {
      value: 450,
      text: `7 hours 30 minutes`,
    },
    {
      value: 465,
      text: `7 hours 45 minutes`,
    },
    {
      value: 480,
      text: `8 hours`,
    },
  ];

  return (
    <div
      key={service.service_id}
      className={"salon-conf dx-card"}
      style={{
        marginBottom: "30px",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        marginTop: index === 0 ? "20px" : "30px",
      }}
    >
      <div
        className={"header"}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div
          className={"title"}
          style={{
            flexGrow: 1,
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
            padding: "10px",
          }}
        >
          Service: {service.service_name}
        </div>
        <button
          className="deleteBtn"
          onClick={() => deleteService(service.service_id)}
        >
          Remove Service
        </button>
      </div>
      <form>
        <Form
          ref={formRef}
          formData={formData.current[service.service_id]}
          disabled={loading}
          colCount={3}
        >
          {service.service_price_level_1 === 0 &&
          service.service_price_level_2 === 0 &&
          service.service_price_level_3 === 0 &&
          service.service_price_level_4 === 0 &&
          service.service_price_level_5 === 0 ? (
            <Item colSpan={3} label={{ visible: false }}>
              <p className="label">Free Service</p>
            </Item>
          ) : (
            <Item
              colSpan={3}
              dataField={"priceLevel"}
              editorType={"dxSelectBox"}
              editorOptions={{
                items: [
                  {
                    value: "1",
                    text: `Price Level 1: ${service.service_price_level_1} $`,
                  },
                  {
                    value: "2",
                    text: `Price Level 2: ${service.service_price_level_2} $`,
                  },
                  {
                    value: "3",
                    text: `Price Level 3: ${service.service_price_level_3} $`,
                  },
                  {
                    value: "4",
                    text: `Price Level 4: ${service.service_price_level_4} $`,
                  },
                  {
                    value: "5",
                    text: `Price Level 5: ${service.service_price_level_5} $`,
                  },
                ],
                placeholder: "Select price level",
                displayExpr: "text",
                valueExpr: "value",
                onValueChanged: (e) => {
                  formData.current[service.service_id] = {
                    ...formData.current[service.service_id],
                    priceLevel: e.value,
                  };
                },
              }}
              label={{ visible: false }}
            />
          )}
          <GroupItem caption="Servicing">
            <Item
              dataField="servicing"
              editorType={"dxSelectBox"}
              editorOptions={{
                items: servicingTimesOptions,
                placeholder: "0",
                displayExpr: "text",
                valueExpr: "value",
                onValueChanged: (e) => {
                  formData.current[service.service_id] = {
                    ...formData.current[service.service_id],
                    servicing: e.value,
                  };
                },
              }}
            >
              <Label visible={false} />
            </Item>
          </GroupItem>
          <GroupItem caption="Gap">
            <Item
              dataField="gap"
              editorType="dxSelectBox"
              editorOptions={{
                items: servicingTimesOptions,
                placeholder: "0",
                displayExpr: "text",
                valueExpr: "value",
                onValueChanged: (e) => {
                  formData.current[service.service_id] = {
                    ...formData.current[service.service_id],
                    gap: e.value,
                  };
                },
              }}
            >
              <Label visible={false} />
            </Item>
          </GroupItem>
          <GroupItem
            caption="Servicing after gap"
            validationRules={[{ type: "required", message: "Required" }]}
          >
            <Item
              dataField="servicing2"
              editorType="dxSelectBox"
              editorOptions={{
                items: servicingTimesOptions,
                placeholder: "0",
                displayExpr: "text",
                validationRules: [{ type: "required", message: "Required" }],

                valueExpr: "value",
                onValueChanged: (e) => {
                  formData.current[service.service_id] = {
                    ...formData.current[service.service_id],
                    servicing2: e.value,
                  };
                },
              }}
            >
              <Label visible={false} />
            </Item>
          </GroupItem>
        </Form>
      </form>
    </div>
  );
};
