import PivotGrid, {
  FieldChooser,
  Export,
  FieldPanel,
  HeaderFilter,
} from "devextreme-react/pivot-grid";
import React from "react";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { sales } from "../../components/pivotgrid-chart/sample-data";
import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Size,
  Tooltip,
} from "devextreme-react/chart";
import get_transactions from "./dynamodb.js";
import GetTransactions from "../reports/dynamodb";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config.js";

//const api = 'gateway/ixinslynhh'; //DynamoDB classic API
const api = `gateway/oixti2bs73/`; // Development SQL API

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
}

function Reporting(props) {
  const chart = React.useRef(null);
  const pivotGrid = React.useRef(null);
  const { user } = useAuth();

  // React.useEffect(() => {

  // }, []);

  const dataSource = new PivotGridDataSource({
    store: new CustomStore({
      load: function () {
        let userCredentials = {
          Barcode: user.barcode,
          Role: user.role,
          Organization: user.org,
        };
        return baseAxios.post(api, userCredentials).then((result) => {
          console.log(result.data);
          return result.data;
        });
      },
    }),

    // filter: [
    //   ['transaction_start', ">=", '2022'],
    //   "and",
    //   ["!", ['salon', '=', null]],
    //   "and",
    //   [
    //     ["!", ['cost', '=', null]],
    //     "or",
    //     ['amount', ">", 5],
    //   ],
    // ],

    fields: [
      {
        caption: "Salon",
        width: 120,
        dataField: "location_name",
        area: "row",
        // sortBySummaryField: "Total",
        visible: true,
      },
      {
        caption: "Stylist",
        dataField: "user_name",
        // width: 150,
        // area: "row"
      },
      {
        caption: "Transaction ID",
        dataField: "transaction_entry_id",
        dataType: "string",
        width: 150,
        area: "row",
        visible: false,
      },
      {
        caption: "Product",
        dataField: "product_alias",
        area: "row",
        width: 100,
      },
      {
        caption: "Transaction Start",
        dataField: "transaction_start",
        dataType: "date",
        area: "column",
      },
      // {
      //   dataField: "date",
      //   dataType: "date",
      //   area: "column"
      // },
      {
        groupName: "transaction_start",
        groupInterval: "quarter",
        visible: false,
      },
      {
        groupIndex: 3,
        groupName: "transaction_start",
        groupInterval: "day",
      },
      {
        groupName: "transaction_start",
        groupIndex: 2,
        caption: "Transaction Start Week",
        dataField: "transaction_start",
        selector: function (data) {
          return "Week " + getWeekNumber(new Date(data.transaction_start));
        },
      },
      {
        groupName: "transaction_start",
        groupInterval: "month",
        groupIndex: 1,
      },
      {
        groupName: "transaction_start",
        groupInterval: "year",
        groupIndex: 0,
      },
      {
        caption: "Product used(g) (per SKU)",
        dataField: "transaction_entry_product_amount",
        dataType: "number",
        summaryType: "sum",
        // format: "currency",
        area: "data",
        tooltip: "Data is per SKU",
      },
      {
        caption: "Cost",
        dataField: "product_cost",
        dataType: "number",
        summaryType: "sum",
        format: "currency",
        area: "data",
      },
      // {
      //   caption: "Containers used",
      //   dataField: "containers_used",
      //   dataType: "number",
      //   summaryType: "sum",
      //   format: {
      //     precision: 2
      //   },
      //   area: "data",
      // }, //Removed until I can figure out how to pull this calculation from the database.
      //{
      //   caption: "Transaction",
      //   dataField: "transaction",
      //   dataType: "number",
      //   area: "row"
      // },
    ],
    // store: GetTransactions(),
  });

  const { location_name } = props?.location?.state || {};

  React.useEffect(() => {
    pivotGrid.current.instance.bindChart(chart.current.instance, {
      dataFieldsDisplayMode: "splitPanes",
      alternateDataFields: false,
    });
  }, [chart, pivotGrid]);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Product Usage</h2>

      <Chart ref={chart}>
        <Size height={320} />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
        <CommonSeriesSettings type="bar" />
        <AdaptiveLayout width={450} />
      </Chart>

      <PivotGrid
        id="pivotgrid"
        dataSource={dataSource}
        allowSortingBySummary={true}
        allowFiltering={true}
        showBorders={true}
        showColumnTotals={false}
        showColumnGrandTotals={false}
        showRowTotals={false}
        showRowGrandTotals={true}
        scrolling={"standard"}
        ref={pivotGrid}
      >
        <FieldPanel allowFieldDragging={true} visible={true} />
        <FieldChooser enabled={true} height={400} />
        {/* <HeaderFilter allowSearch={true}/> */}
        <Export enabled={true} />
      </PivotGrid>
    </React.Fragment>
  );
}

function customizeTooltip(args) {
  const valueText =
    args.seriesName.indexOf("Total") !== -1
      ? new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD",
        }).format(args.originalValue)
      : args.originalValue;

  return {
    html: `${args.seriesName}<div class='currency'>${valueText}</div>`,
  };
}

export default Reporting;
