import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "./bar2.style.scss";
import { data } from '../../pages/dashboard/cups';

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  borderRadius: 2,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  indexAxis: 'y',
};

export default function Bar2({ value, onClick }) {
  const [data, setData] = useState({ datasets: [] });
  const [options, setOptions] = useState(defaultOptions);
  const [delayed, setDelayed] = useState(false);

  useEffect(() => {
    const newData = {
      label: [],
      data: []
    }

    value.map((item) => {
      newData.label.push(item.product_name);
      newData.data.push(item.container_used);
    });

    setData(prev => ({
      ...prev,
      labels: newData.label,
      datasets: [
        {
          barPercentage: 1.11,
          categorySpacing: 0,
          data: newData.data,
          backgroundColor: "#ed0f7e",
        },
      ],
    }));
  }, [value]);

  useEffect(() => {
    const newData = {
      labels: ["-", "-", "-", "-", "-", "-", "-", "-", "-"],
      datasets: [
        {
          barPercentage: 1.11,
          categorySpacing: 0,
          data: [10, 9, 8, 7, 6, 5, 4, 3, 2],
          backgroundColor: "#ed0f7e",
        },
      ],
    };

    const newOptions = {
      ...defaultOptions,
      scales: {
        y: {
          grid: {
            display: false,
          },
        },
        x: {
          ticks: {
            stepSize: 2,
          },
        },
      },
      animation: {
        onComplete: () => {
          setDelayed(true);
        },
        delay: (context) => {
          let delay = 0;
          if (context.type === 'data' && context.mode === 'default' && !delayed) {
            delay = context.dataIndex * 150 + context.datasetIndex * 100;
          }
          return delay;
        },
      },
    };

    setData(newData);
    setOptions(newOptions);
  }, []);

  return (
    <div className="bar2-body" onClick={onClick}>
      <div className="bar2-container">
        <Bar data={data} options={options} onElementsClick={onClick} />
      </div>
    </div>
  );
}
