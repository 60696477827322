import React from "react";
import "./reports.scss";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { Button } from "devextreme-react/button";
import { LoadIndicator } from "devextreme-react/chart";

import DataGrid, {
  Export,
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  HeaderFilter,
  Scrolling,
  Editing,
  TotalItem,
  Summary,
} from "devextreme-react/data-grid";
import service from "./report_data.js";
import GetTransactions from "./dynamodb.js";
import { Suspense } from "react/cjs/react.production.min";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config.js";

// function adjustToLocalDate(data) {
//   var formattedTime = data.value
//   formattedTime = new Date(data.value).toLocaleDateString(`en-us`, {year: `2-digit`, month: `numeric`, day: `numeric`})
//   return `${formattedTime}`
// }
function adjustToLocalTime(data) {
  var formattedTime = data.value;
  formattedTime = new Date(data.value).toLocaleTimeString(`en-us`, {
    hour: `numeric`,
    minute: `2-digit`,
  });
  return `${formattedTime}`;
}
function addDollarSign(data) {
  var cost = data.value;
  if (cost == "undefined" || cost == undefined || cost < 0.01) {
    cost = 0.0;
  }
  return `$${cost}`;
}

export default function Reports(props) {
  const api = "gateway/oixti2bs73"; //Uses Development SQL transaction
  // const api = 'gateway/fflwonwbgf'; //Uses TransactionsScan Api - This one should be used most of the time
  // const api = "gateway/47nspi6n58/"; //Uses the RolledTransactionsScan API
  const { user } = useAuth();
  const { location_name } = props?.location?.state || {};

  // React.useEffect(() => {

  // }, []);
  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  JSON.stringify(userCredentials);

  const dataSource = new CustomStore({
    load: function () {
      return baseAxios.post(api, userCredentials).then((result) => {
        console.log(userCredentials);
        return result.data;
      });
    },
  });
  const orderDateFormat = "M/d/yy";

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Transactions</h2>
      <p className="content-block">Raw transactions dump.</p>
      {/* <Suspense fallback={<LoadIndicator />} > */}
      <DataGrid
        //dataSource={service.getSales()}
        dataSource={dataSource}
        //keyExpr="id"
        //keyExpr="transaction_id"
        className={"dx-card wide-card"}
        showBorders={true}
        //focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
      >
        <Paging defaultPageSize={25} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        {/* <Editing mode="row" allowUpdating={true} allowAdding={true} allowDeleting={true}></Editing> */}
        <Scrolling mode="standard" />

        <Column dataField={"org_name"} fixed={true} visible={true} />
        <Column
          dataField={"location_name"}
          caption={"Salon"}
          fixed={true}
          filterValue={location_name || ""}
        />
        <Column dataField={"user_name"} fixed={true} />
        <Column
          dataField={"transaction_entry_start"}
          caption={"Start"}
          sortOrder={"desc"}
          sortIndex={2}
          customizeText={adjustToLocalTime}
        />
        <Column dataField={"transaction_client_name"} caption={"Client Name"} />
        {/* <Column dataField={'recipe_id'} hidingPriority={0} /> */}
        {/* <Column dataField={'transaction_id'} hidingPriority={1} /> */}

        <Column
          dataField={"transaction_start"}
          caption={"Date"}
          dataType={"date"}
          fixed={true}
          sortOrder={"desc"}
          sortIndex={1}
          format={orderDateFormat}
          //customizeText={adjustToLocalDate}
        />
        <Column
          dataField={"transaction_end"}
          caption={"End"}
          customizeText={adjustToLocalTime}
          hidingPriority={1}
        />
        <Column dataField={"product_alias"} caption={`Product`} />
        <Column
          dataField={"transaction_entry_product_amount"}
          hidingPriority={3}
          caption={"Amount (g)"}
          dataType={"number"}
          alignment={"left"}
        >
          <format type="fixedPoint" precision={2} />
        </Column>
        <Column
          dataField={"product_cost"}
          dataType={"currency"}
          customizeText={addDollarSign}
        >
          <format type="fixedPoint" precision={2} />
        </Column>
        <Column dataField={"device_name"} hidingPriority={2} />
        <Export enabled={true} allowExportSelectedData={false} />
        <Summary>
          <TotalItem
            column={"product_cost"}
            summaryType={"sum"}
            alignment={"left"}
          />
        </Summary>
      </DataGrid>
      {/* </Suspense> */}
    </React.Fragment>
  );
}
