const sales = [
    {
      id: 10248,
      region: "Wilson",
      country: "United States of America",
      city: "Olivia Peyton",
      amount: 1740,
      date: new Date("2019-01-06")
    },
    {
      id: 10249,
      region: "Wilson",
      country: "United States of America",
      city: "Olivia Peyton",
      amount: 850,
      date: new Date("2019-01-13")
    },
    {
      id: 10250,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2235,
      date: new Date("2019-01-07")
    },
    {
      id: 10251,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1965,
      date: new Date("2019-01-03")
    },
    {
      id: 10252,
      region: "Wilson",
      country: "Canada",
      city: "Brett Wade",
      amount: 880,
      date: new Date("2019-01-10")
    },
    {
      id: 10253,
      region: "Commerce",
      country: "Brazil",
      city: "Sandra Johnson",
      amount: 5260,
      date: new Date("2019-01-17")
    },
    {
      id: 10254,
      region: "Commerce",
      country: "Argentina",
      city: "Kevin Carter",
      amount: 2790,
      date: new Date("2019-01-21")
    },
    {
      id: 10255,
      region: "Commerce",
      country: "Paraguay",
      city: "Cynthia Stanwick",
      amount: 3140,
      date: new Date("2019-01-01")
    },
    {
      id: 10256,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Kent Samuelson",
      amount: 6175,
      date: new Date("2019-01-24")
    },
    {
      id: 10257,
      region: "Fair Lakes",
      country: "Germany",
      city: "Taylor Riley",
      amount: 4575,
      date: new Date("2019-01-11")
    },
    {
      id: 10258,
      region: "Fair Lakes",
      country: "Spain",
      city: "Sam Hill",
      amount: 3680,
      date: new Date("2019-01-12")
    },
    {
      id: 10259,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Kelly Rodriquez",
      amount: 2260,
      date: new Date("2019-01-01")
    },
    {
      id: 10260,
      region: "Lee Hwy",
      country: "China",
      city: "Walter Hobbs",
      amount: 2910,
      date: new Date("2019-01-26")
    },
    {
      id: 10261,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 8400,
      date: new Date("2019-01-05")
    },
    {
      id: 10262,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 1325,
      date: new Date("2019-01-14")
    },
    {
      id: 10263,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 3920,
      date: new Date("2019-01-05")
    },
    {
      id: 10264,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2220,
      date: new Date("2019-01-15")
    },
    {
      id: 10265,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 940,
      date: new Date("2019-01-01")
    },
    {
      id: 10266,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1630,
      date: new Date("2019-01-10")
    },
    {
      id: 10267,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2910,
      date: new Date("2019-01-23")
    },
    {
      id: 10268,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 2600,
      date: new Date("2019-01-14")
    },
    {
      id: 10269,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 4340,
      date: new Date("2019-01-26")
    },
    {
      id: 10270,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 6650,
      date: new Date("2019-01-24")
    },
    {
      id: 10271,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 490,
      date: new Date("2019-01-22")
    },
    {
      id: 10272,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 3390,
      date: new Date("2019-01-25")
    },
    {
      id: 10273,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 5160,
      date: new Date("2019-02-20")
    },
    {
      id: 10274,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 5750,
      date: new Date("2019-02-12")
    },
    {
      id: 10275,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2805,
      date: new Date("2019-02-13")
    },
    {
      id: 10276,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2505,
      date: new Date("2019-02-09")
    },
    {
      id: 10277,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 930,
      date: new Date("2019-02-04")
    },
    {
      id: 10278,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 1240,
      date: new Date("2019-02-03")
    },
    {
      id: 10279,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 315,
      date: new Date("2019-02-04")
    },
    {
      id: 10280,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2870,
      date: new Date("2019-02-18")
    },
    {
      id: 10281,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 5150,
      date: new Date("2019-02-18")
    },
    {
      id: 10282,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 2725,
      date: new Date("2019-02-20")
    },
    {
      id: 10283,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 2840,
      date: new Date("2019-02-04")
    },
    {
      id: 10284,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "",
      amount: 5840,
      date: new Date("2019-02-13")
    },
    {
      id: 10285,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 6750,
      date: new Date("2019-02-11")
    },
    {
      id: 10286,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 1200,
      date: new Date("2019-02-03")
    },
    {
      id: 10287,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 4550,
      date: new Date("2019-02-08")
    },
    {
      id: 10288,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 6040,
      date: new Date("2019-02-17")
    },
    {
      id: 10289,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2205,
      date: new Date("2019-02-08")
    },
    {
      id: 10290,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 990,
      date: new Date("2019-02-20")
    },
    {
      id: 10291,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 700,
      date: new Date("2019-02-11")
    },
    {
      id: 10292,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2325,
      date: new Date("2019-02-15")
    },
    {
      id: 10293,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 930,
      date: new Date("2019-02-21")
    },
    {
      id: 10294,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1560,
      date: new Date("2019-02-04")
    },
    {
      id: 10295,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 1740,
      date: new Date("2019-03-04")
    },
    {
      id: 10296,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 3575,
      date: new Date("2019-03-20")
    },
    {
      id: 10297,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 4500,
      date: new Date("2019-03-04")
    },
    {
      id: 10298,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1605,
      date: new Date("2019-03-17")
    },
    {
      id: 10299,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 800,
      date: new Date("2019-03-21")
    },
    {
      id: 10300,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 640,
      date: new Date("2019-03-08")
    },
    {
      id: 10301,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 735,
      date: new Date("2019-03-19")
    },
    {
      id: 10302,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2520,
      date: new Date("2019-03-20")
    },
    {
      id: 10303,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 6675,
      date: new Date("2019-03-18")
    },
    {
      id: 10304,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 3625,
      date: new Date("2019-03-25")
    },
    {
      id: 10305,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 1200,
      date: new Date("2019-03-07")
    },
    {
      id: 10306,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 2000,
      date: new Date("2019-03-07")
    },
    {
      id: 10307,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 1410,
      date: new Date("2019-03-10")
    },
    {
      id: 10308,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 2700,
      date: new Date("2019-03-19")
    },
    {
      id: 10309,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 5950,
      date: new Date("2019-03-24")
    },
    {
      id: 10310,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 5120,
      date: new Date("2019-03-08")
    },
    {
      id: 10311,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 1980,
      date: new Date("2019-03-17")
    },
    {
      id: 10312,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1110,
      date: new Date("2019-03-08")
    },
    {
      id: 10313,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 980,
      date: new Date("2019-03-21")
    },
    {
      id: 10314,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 5460,
      date: new Date("2019-03-19")
    },
    {
      id: 10315,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 3800,
      date: new Date("2019-03-12")
    },
    {
      id: 10316,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2610,
      date: new Date("2019-03-04")
    },
    {
      id: 10317,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 3080,
      date: new Date("2019-03-22")
    },
    {
      id: 10318,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 2010,
      date: new Date("2019-03-23")
    },
    {
      id: 10319,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 1200,
      date: new Date("2019-03-04")
    },
    {
      id: 10320,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 7680,
      date: new Date("2019-04-15")
    },
    {
      id: 10321,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 1325,
      date: new Date("2019-04-07")
    },
    {
      id: 10322,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2835,
      date: new Date("2019-04-10")
    },
    {
      id: 10323,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 3660,
      date: new Date("2019-04-10")
    },
    {
      id: 10324,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 390,
      date: new Date("2019-04-12")
    },
    {
      id: 10325,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 4420,
      date: new Date("2019-04-08")
    },
    {
      id: 10326,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1755,
      date: new Date("2019-04-13")
    },
    {
      id: 10327,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2580,
      date: new Date("2019-04-15")
    },
    {
      id: 10328,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 850,
      date: new Date("2019-04-01")
    },
    {
      id: 10329,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 2825,
      date: new Date("2019-04-10")
    },
    {
      id: 10330,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 540,
      date: new Date("2019-04-06")
    },
    {
      id: 10331,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 1520,
      date: new Date("2019-04-08")
    },
    {
      id: 10332,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 8760,
      date: new Date("2019-04-26")
    },
    {
      id: 10333,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 1110,
      date: new Date("2019-04-16")
    },
    {
      id: 10334,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 6850,
      date: new Date("2019-04-19")
    },
    {
      id: 10335,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 1940,
      date: new Date("2019-04-23")
    },
    {
      id: 10336,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 1980,
      date: new Date("2019-04-21")
    },
    {
      id: 10337,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 3090,
      date: new Date("2019-04-03")
    },
    {
      id: 10338,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1640,
      date: new Date("2019-04-24")
    },
    {
      id: 10339,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 3585,
      date: new Date("2019-04-01")
    },
    {
      id: 10340,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1770,
      date: new Date("2019-04-01")
    },
    {
      id: 10341,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 4005,
      date: new Date("2019-04-04")
    },
    {
      id: 10342,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2870,
      date: new Date("2019-04-02")
    },
    {
      id: 10343,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 960,
      date: new Date("2019-04-20")
    },
    {
      id: 10344,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 8640,
      date: new Date("2019-05-14")
    },
    {
      id: 10345,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 5450,
      date: new Date("2019-05-24")
    },
    {
      id: 10346,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2535,
      date: new Date("2019-05-07")
    },
    {
      id: 10347,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1155,
      date: new Date("2019-05-20")
    },
    {
      id: 10348,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 3140,
      date: new Date("2019-05-18")
    },
    {
      id: 10349,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 2260,
      date: new Date("2019-05-19")
    },
    {
      id: 10350,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1215,
      date: new Date("2019-05-23")
    },
    {
      id: 10351,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1210,
      date: new Date("2019-05-08")
    },
    {
      id: 10352,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 875,
      date: new Date("2019-05-25")
    },
    {
      id: 10353,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5400,
      date: new Date("2019-05-03")
    },
    {
      id: 10354,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 5940,
      date: new Date("2019-05-25")
    },
    {
      id: 10355,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 4700,
      date: new Date("2019-05-03")
    },
    {
      id: 10356,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 5520,
      date: new Date("2019-05-12")
    },
    {
      id: 10357,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 9210,
      date: new Date("2019-05-22")
    },
    {
      id: 10358,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 7950,
      date: new Date("2019-05-12")
    },
    {
      id: 10359,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 3740,
      date: new Date("2019-05-24")
    },
    {
      id: 10360,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 990,
      date: new Date("2019-05-02")
    },
    {
      id: 10361,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 3190,
      date: new Date("2019-05-03")
    },
    {
      id: 10362,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 2430,
      date: new Date("2019-05-11")
    },
    {
      id: 10363,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 7380,
      date: new Date("2019-06-15")
    },
    {
      id: 10364,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 4475,
      date: new Date("2019-06-08")
    },
    {
      id: 10365,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 1290,
      date: new Date("2019-06-10")
    },
    {
      id: 10366,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2250,
      date: new Date("2019-06-10")
    },
    {
      id: 10367,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 350,
      date: new Date("2019-06-22")
    },
    {
      id: 10368,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 5480,
      date: new Date("2019-06-24")
    },
    {
      id: 10369,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 2355,
      date: new Date("2019-06-10")
    },
    {
      id: 10370,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1960,
      date: new Date("2019-06-23")
    },
    {
      id: 10371,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 4125,
      date: new Date("2019-06-06")
    },
    {
      id: 10372,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 7925,
      date: new Date("2019-06-12")
    },
    {
      id: 10373,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 1120,
      date: new Date("2019-06-22")
    },
    {
      id: 10374,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 5100,
      date: new Date("2019-06-01")
    },
    {
      id: 10375,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 1500,
      date: new Date("2019-06-25")
    },
    {
      id: 10376,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 5130,
      date: new Date("2019-06-10")
    },
    {
      id: 10377,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 2475,
      date: new Date("2019-06-10")
    },
    {
      id: 10378,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 2100,
      date: new Date("2019-06-06")
    },
    {
      id: 10379,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 3570,
      date: new Date("2019-06-10")
    },
    {
      id: 10380,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 550,
      date: new Date("2019-06-02")
    },
    {
      id: 10381,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 2850,
      date: new Date("2019-06-26")
    },
    {
      id: 10382,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 4280,
      date: new Date("2019-06-19")
    },
    {
      id: 10383,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 1460,
      date: new Date("2019-06-17")
    },
    {
      id: 10384,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 960,
      date: new Date("2019-06-17")
    },
    {
      id: 10385,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1520,
      date: new Date("2019-06-03")
    },
    {
      id: 10386,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 6750,
      date: new Date("2019-06-21")
    },
    {
      id: 10387,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 7260,
      date: new Date("2019-07-14")
    },
    {
      id: 10388,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 2450,
      date: new Date("2019-07-11")
    },
    {
      id: 10389,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 3540,
      date: new Date("2019-07-02")
    },
    {
      id: 10390,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1950,
      date: new Date("2019-07-03")
    },
    {
      id: 10391,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 360,
      date: new Date("2019-07-07")
    },
    {
      id: 10392,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 4500,
      date: new Date("2019-07-03")
    },
    {
      id: 10393,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4575,
      date: new Date("2019-07-21")
    },
    {
      id: 10394,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2310,
      date: new Date("2019-07-18")
    },
    {
      id: 10395,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 7500,
      date: new Date("2019-07-04")
    },
    {
      id: 10396,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 3575,
      date: new Date("2019-07-23")
    },
    {
      id: 10397,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 760,
      date: new Date("2019-07-01")
    },
    {
      id: 10398,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 2400,
      date: new Date("2019-07-11")
    },
    {
      id: 10399,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 3330,
      date: new Date("2019-07-04")
    },
    {
      id: 10400,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 3480,
      date: new Date("2019-07-23")
    },
    {
      id: 10401,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 4875,
      date: new Date("2019-07-11")
    },
    {
      id: 10402,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4980,
      date: new Date("2019-07-19")
    },
    {
      id: 10403,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2580,
      date: new Date("2019-07-04")
    },
    {
      id: 10404,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2650,
      date: new Date("2019-07-16")
    },
    {
      id: 10405,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1190,
      date: new Date("2019-07-02")
    },
    {
      id: 10406,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 960,
      date: new Date("2019-07-26")
    },
    {
      id: 10407,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 3600,
      date: new Date("2019-08-08")
    },
    {
      id: 10408,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 2250,
      date: new Date("2019-08-01")
    },
    {
      id: 10409,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 1275,
      date: new Date("2019-08-02")
    },
    {
      id: 10410,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 3885,
      date: new Date("2019-08-14")
    },
    {
      id: 10411,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1900,
      date: new Date("2019-08-05")
    },
    {
      id: 10412,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 2300,
      date: new Date("2019-08-09")
    },
    {
      id: 10413,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 2895,
      date: new Date("2019-08-15")
    },
    {
      id: 10414,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 350,
      date: new Date("2019-08-20")
    },
    {
      id: 10415,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 4200,
      date: new Date("2019-08-22")
    },
    {
      id: 10416,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 7175,
      date: new Date("2019-08-14")
    },
    {
      id: 10417,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 4420,
      date: new Date("2019-08-24")
    },
    {
      id: 10418,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 5200,
      date: new Date("2019-08-21")
    },
    {
      id: 10419,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 7920,
      date: new Date("2019-08-17")
    },
    {
      id: 10420,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 6990,
      date: new Date("2019-08-22")
    },
    {
      id: 10421,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 2125,
      date: new Date("2019-08-05")
    },
    {
      id: 10422,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 2220,
      date: new Date("2019-08-16")
    },
    {
      id: 10423,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 1575,
      date: new Date("2019-08-23")
    },
    {
      id: 10424,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1880,
      date: new Date("2019-08-12")
    },
    {
      id: 10425,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 710,
      date: new Date("2019-08-25")
    },
    {
      id: 10426,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 390,
      date: new Date("2019-08-20")
    },
    {
      id: 10427,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 4635,
      date: new Date("2019-08-04")
    },
    {
      id: 10428,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 4350,
      date: new Date("2019-08-19")
    },
    {
      id: 10429,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 6020,
      date: new Date("2019-08-02")
    },
    {
      id: 10430,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 3660,
      date: new Date("2019-08-19")
    },
    {
      id: 10431,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 4525,
      date: new Date("2019-08-24")
    },
    {
      id: 10432,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 4410,
      date: new Date("2019-09-12")
    },
    {
      id: 10433,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 1725,
      date: new Date("2019-09-07")
    },
    {
      id: 10434,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2715,
      date: new Date("2019-09-14")
    },
    {
      id: 10435,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2820,
      date: new Date("2019-09-08")
    },
    {
      id: 10436,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2310,
      date: new Date("2019-09-12")
    },
    {
      id: 10437,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 780,
      date: new Date("2019-09-08")
    },
    {
      id: 10438,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 2370,
      date: new Date("2019-09-19")
    },
    {
      id: 10439,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1410,
      date: new Date("2019-09-09")
    },
    {
      id: 10440,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 1825,
      date: new Date("2019-09-23")
    },
    {
      id: 10441,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 4075,
      date: new Date("2019-09-06")
    },
    {
      id: 10442,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 1020,
      date: new Date("2019-09-04")
    },
    {
      id: 10443,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 4320,
      date: new Date("2019-09-25")
    },
    {
      id: 10444,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 7530,
      date: new Date("2019-09-13")
    },
    {
      id: 10445,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 2820,
      date: new Date("2019-09-08")
    },
    {
      id: 10446,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 3050,
      date: new Date("2019-09-04")
    },
    {
      id: 10447,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 5080,
      date: new Date("2019-09-25")
    },
    {
      id: 10448,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 1125,
      date: new Date("2019-09-13")
    },
    {
      id: 10449,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 850,
      date: new Date("2019-09-24")
    },
    {
      id: 10450,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1440,
      date: new Date("2019-09-19")
    },
    {
      id: 10451,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1950,
      date: new Date("2019-09-02")
    },
    {
      id: 10452,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 6390,
      date: new Date("2019-10-11")
    },
    {
      id: 10453,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 4625,
      date: new Date("2019-10-02")
    },
    {
      id: 10454,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 3510,
      date: new Date("2019-10-24")
    },
    {
      id: 10455,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2730,
      date: new Date("2019-10-15")
    },
    {
      id: 10456,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2070,
      date: new Date("2019-10-15")
    },
    {
      id: 10457,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 2320,
      date: new Date("2019-10-18")
    },
    {
      id: 10458,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4260,
      date: new Date("2019-10-24")
    },
    {
      id: 10459,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 840,
      date: new Date("2019-10-18")
    },
    {
      id: 10460,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 7300,
      date: new Date("2019-10-24")
    },
    {
      id: 10461,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5950,
      date: new Date("2019-10-11")
    },
    {
      id: 10462,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 3220,
      date: new Date("2019-10-25")
    },
    {
      id: 10463,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 3480,
      date: new Date("2019-10-08")
    },
    {
      id: 10464,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 4830,
      date: new Date("2019-10-26")
    },
    {
      id: 10465,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 4470,
      date: new Date("2019-10-05")
    },
    {
      id: 10466,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 3675,
      date: new Date("2019-10-23")
    },
    {
      id: 10467,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4260,
      date: new Date("2019-10-01")
    },
    {
      id: 10468,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 4245,
      date: new Date("2019-10-26")
    },
    {
      id: 10469,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1470,
      date: new Date("2019-10-01")
    },
    {
      id: 10470,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1810,
      date: new Date("2019-10-02")
    },
    {
      id: 10471,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 600,
      date: new Date("2019-10-23")
    },
    {
      id: 10472,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 7500,
      date: new Date("2019-11-03")
    },
    {
      id: 10473,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 4625,
      date: new Date("2019-11-02")
    },
    {
      id: 10474,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2625,
      date: new Date("2019-11-09")
    },
    {
      id: 10475,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1440,
      date: new Date("2019-11-15")
    },
    {
      id: 10476,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2420,
      date: new Date("2019-11-15")
    },
    {
      id: 10477,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 4180,
      date: new Date("2019-11-15")
    },
    {
      id: 10478,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 3720,
      date: new Date("2019-11-25")
    },
    {
      id: 10479,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2730,
      date: new Date("2019-11-08")
    },
    {
      id: 10480,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 3775,
      date: new Date("2019-11-17")
    },
    {
      id: 10481,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 3525,
      date: new Date("2019-11-15")
    },
    {
      id: 10482,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 5320,
      date: new Date("2019-11-08")
    },
    {
      id: 10483,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 5340,
      date: new Date("2019-11-13")
    },
    {
      id: 10484,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 8850,
      date: new Date("2019-11-01")
    },
    {
      id: 10485,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 7050,
      date: new Date("2019-11-14")
    },
    {
      id: 10486,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 4200,
      date: new Date("2019-11-18")
    },
    {
      id: 10487,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4960,
      date: new Date("2019-11-04")
    },
    {
      id: 10488,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2280,
      date: new Date("2019-11-13")
    },
    {
      id: 10489,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 590,
      date: new Date("2019-11-11")
    },
    {
      id: 10490,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 810,
      date: new Date("2019-11-12")
    },
    {
      id: 10491,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 2625,
      date: new Date("2019-11-07")
    },
    {
      id: 10492,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 8280,
      date: new Date("2019-12-01")
    },
    {
      id: 10493,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 5650,
      date: new Date("2019-12-19")
    },
    {
      id: 10494,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2760,
      date: new Date("2019-12-14")
    },
    {
      id: 10495,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2670,
      date: new Date("2019-12-03")
    },
    {
      id: 10496,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2520,
      date: new Date("2019-12-20")
    },
    {
      id: 10497,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 4080,
      date: new Date("2019-12-21")
    },
    {
      id: 10498,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4140,
      date: new Date("2019-12-22")
    },
    {
      id: 10499,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 390,
      date: new Date("2019-12-04")
    },
    {
      id: 10500,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 1400,
      date: new Date("2019-12-19")
    },
    {
      id: 10501,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 7275,
      date: new Date("2019-12-22")
    },
    {
      id: 10502,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 4100,
      date: new Date("2019-12-20")
    },
    {
      id: 10503,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 5520,
      date: new Date("2019-12-25")
    },
    {
      id: 10504,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 9210,
      date: new Date("2019-12-24")
    },
    {
      id: 10505,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 7290,
      date: new Date("2019-12-05")
    },
    {
      id: 10506,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 625,
      date: new Date("2019-12-22")
    },
    {
      id: 10507,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4460,
      date: new Date("2019-12-12")
    },
    {
      id: 10508,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 3825,
      date: new Date("2019-12-13")
    },
    {
      id: 10509,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2850,
      date: new Date("2019-12-17")
    },
    {
      id: 10510,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 2780,
      date: new Date("2019-12-07")
    },
    {
      id: 10511,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 840,
      date: new Date("2019-12-18")
    },
    {
      id: 10512,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2970,
      date: new Date("2019-12-23")
    },
    {
      id: 10513,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 945,
      date: new Date("2019-12-06")
    },
    {
      id: 10514,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2625,
      date: new Date("2019-12-04")
    },
    {
      id: 10515,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 390,
      date: new Date("2019-12-01")
    },
    {
      id: 10516,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 2250,
      date: new Date("2019-12-02")
    },
    {
      id: 10517,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 7710,
      date: new Date("2020-01-18")
    },
    {
      id: 10518,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 7975,
      date: new Date("2020-01-10")
    },
    {
      id: 10519,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 3285,
      date: new Date("2020-01-13")
    },
    {
      id: 10520,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2580,
      date: new Date("2020-01-22")
    },
    {
      id: 10521,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2160,
      date: new Date("2020-01-26")
    },
    {
      id: 10522,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 1100,
      date: new Date("2020-01-25")
    },
    {
      id: 10523,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4425,
      date: new Date("2020-01-21")
    },
    {
      id: 10524,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1360,
      date: new Date("2020-01-22")
    },
    {
      id: 10525,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 3250,
      date: new Date("2020-01-14")
    },
    {
      id: 10526,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5550,
      date: new Date("2020-01-21")
    },
    {
      id: 10527,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 2860,
      date: new Date("2020-01-25")
    },
    {
      id: 10528,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 5320,
      date: new Date("2020-01-08")
    },
    {
      id: 10529,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 4050,
      date: new Date("2020-01-14")
    },
    {
      id: 10530,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 3450,
      date: new Date("2020-01-24")
    },
    {
      id: 10531,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 5425,
      date: new Date("2020-01-11")
    },
    {
      id: 10532,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4860,
      date: new Date("2020-01-12")
    },
    {
      id: 10533,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 4695,
      date: new Date("2020-01-16")
    },
    {
      id: 10534,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 610,
      date: new Date("2020-01-05")
    },
    {
      id: 10535,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1580,
      date: new Date("2020-01-15")
    },
    {
      id: 10536,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 3780,
      date: new Date("2020-02-18")
    },
    {
      id: 10537,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 5400,
      date: new Date("2020-02-21")
    },
    {
      id: 10538,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 630,
      date: new Date("2020-02-18")
    },
    {
      id: 10539,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 3960,
      date: new Date("2020-02-04")
    },
    {
      id: 10540,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2010,
      date: new Date("2020-02-25")
    },
    {
      id: 10541,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 5000,
      date: new Date("2020-02-01")
    },
    {
      id: 10542,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1995,
      date: new Date("2020-02-20")
    },
    {
      id: 10543,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 860,
      date: new Date("2020-02-12")
    },
    {
      id: 10544,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 2150,
      date: new Date("2020-02-10")
    },
    {
      id: 10545,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 4050,
      date: new Date("2020-02-06")
    },
    {
      id: 10546,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 2960,
      date: new Date("2020-02-18")
    },
    {
      id: 10547,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 1780,
      date: new Date("2020-02-26")
    },
    {
      id: 10548,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 8700,
      date: new Date("2020-02-03")
    },
    {
      id: 10549,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 3390,
      date: new Date("2020-02-03")
    },
    {
      id: 10550,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 4425,
      date: new Date("2020-02-15")
    },
    {
      id: 10551,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 1180,
      date: new Date("2020-02-23")
    },
    {
      id: 10552,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 360,
      date: new Date("2020-02-08")
    },
    {
      id: 10553,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2310,
      date: new Date("2020-02-13")
    },
    {
      id: 10554,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1770,
      date: new Date("2020-02-20")
    },
    {
      id: 10555,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 3060,
      date: new Date("2020-02-26")
    },
    {
      id: 10556,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1750,
      date: new Date("2020-02-12")
    },
    {
      id: 10557,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 2280,
      date: new Date("2020-03-09")
    },
    {
      id: 10558,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 7600,
      date: new Date("2020-03-25")
    },
    {
      id: 10559,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 1035,
      date: new Date("2020-03-23")
    },
    {
      id: 10560,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1245,
      date: new Date("2020-03-01")
    },
    {
      id: 10561,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2860,
      date: new Date("2020-03-19")
    },
    {
      id: 10562,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 440,
      date: new Date("2020-03-19")
    },
    {
      id: 10563,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4665,
      date: new Date("2020-03-02")
    },
    {
      id: 10564,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2270,
      date: new Date("2020-03-15")
    },
    {
      id: 10565,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 5000,
      date: new Date("2020-03-09")
    },
    {
      id: 10566,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5100,
      date: new Date("2020-03-23")
    },
    {
      id: 10567,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 2120,
      date: new Date("2020-03-11")
    },
    {
      id: 10568,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 5140,
      date: new Date("2020-03-05")
    },
    {
      id: 10569,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 6210,
      date: new Date("2020-03-19")
    },
    {
      id: 10570,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 9510,
      date: new Date("2020-03-19")
    },
    {
      id: 10571,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 7600,
      date: new Date("2020-03-21")
    },
    {
      id: 10572,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 5420,
      date: new Date("2020-03-15")
    },
    {
      id: 10573,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 1980,
      date: new Date("2020-03-05")
    },
    {
      id: 10574,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1820,
      date: new Date("2020-03-07")
    },
    {
      id: 10575,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1670,
      date: new Date("2020-03-21")
    },
    {
      id: 10576,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 4800,
      date: new Date("2020-03-08")
    },
    {
      id: 10577,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2925,
      date: new Date("2020-03-03")
    },
    {
      id: 10578,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 2940,
      date: new Date("2020-04-11")
    },
    {
      id: 10579,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 3525,
      date: new Date("2020-04-13")
    },
    {
      id: 10580,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2475,
      date: new Date("2020-04-22")
    },
    {
      id: 10581,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 3315,
      date: new Date("2020-04-08")
    },
    {
      id: 10582,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 3140,
      date: new Date("2020-04-07")
    },
    {
      id: 10583,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 2520,
      date: new Date("2020-04-01")
    },
    {
      id: 10584,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1200,
      date: new Date("2020-04-10")
    },
    {
      id: 10585,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2060,
      date: new Date("2020-04-21")
    },
    {
      id: 10586,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 7875,
      date: new Date("2020-04-02")
    },
    {
      id: 10587,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 1450,
      date: new Date("2020-04-07")
    },
    {
      id: 10588,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 2640,
      date: new Date("2020-04-22")
    },
    {
      id: 10589,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 1960,
      date: new Date("2020-04-16")
    },
    {
      id: 10590,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 2250,
      date: new Date("2020-04-23")
    },
    {
      id: 10591,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 4500,
      date: new Date("2020-04-05")
    },
    {
      id: 10592,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 5050,
      date: new Date("2020-04-11")
    },
    {
      id: 10593,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 2940,
      date: new Date("2020-04-02")
    },
    {
      id: 10594,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2880,
      date: new Date("2020-04-14")
    },
    {
      id: 10595,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1050,
      date: new Date("2020-04-19")
    },
    {
      id: 10596,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1850,
      date: new Date("2020-04-02")
    },
    {
      id: 10597,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 3160,
      date: new Date("2020-04-01")
    },
    {
      id: 10598,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 875,
      date: new Date("2020-04-04")
    },
    {
      id: 10599,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 3200,
      date: new Date("2020-04-08")
    },
    {
      id: 10600,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 1380,
      date: new Date("2020-04-21")
    },
    {
      id: 10601,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 3060,
      date: new Date("2020-04-06")
    },
    {
      id: 10602,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 6690,
      date: new Date("2020-05-19")
    },
    {
      id: 10603,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 4525,
      date: new Date("2020-05-15")
    },
    {
      id: 10604,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 4665,
      date: new Date("2020-05-10")
    },
    {
      id: 10605,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 4530,
      date: new Date("2020-05-18")
    },
    {
      id: 10606,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1330,
      date: new Date("2020-05-08")
    },
    {
      id: 10607,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 1720,
      date: new Date("2020-05-20")
    },
    {
      id: 10608,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 3750,
      date: new Date("2020-05-16")
    },
    {
      id: 10609,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1290,
      date: new Date("2020-05-10")
    },
    {
      id: 10610,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 4925,
      date: new Date("2020-05-14")
    },
    {
      id: 10611,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 4300,
      date: new Date("2020-05-22")
    },
    {
      id: 10612,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 5740,
      date: new Date("2020-05-08")
    },
    {
      id: 10613,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 3760,
      date: new Date("2020-05-18")
    },
    {
      id: 10614,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 7920,
      date: new Date("2020-05-22")
    },
    {
      id: 10615,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 1440,
      date: new Date("2020-05-21")
    },
    {
      id: 10616,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 5975,
      date: new Date("2020-05-25")
    },
    {
      id: 10617,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4440,
      date: new Date("2020-05-05")
    },
    {
      id: 10618,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2310,
      date: new Date("2020-05-24")
    },
    {
      id: 10619,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2250,
      date: new Date("2020-05-06")
    },
    {
      id: 10620,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 2320,
      date: new Date("2020-05-14")
    },
    {
      id: 10621,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 8370,
      date: new Date("2020-05-06")
    },
    {
      id: 10622,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 5190,
      date: new Date("2020-06-26")
    },
    {
      id: 10623,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 925,
      date: new Date("2020-06-04")
    },
    {
      id: 10624,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 3240,
      date: new Date("2020-06-20")
    },
    {
      id: 10625,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 3180,
      date: new Date("2020-06-23")
    },
    {
      id: 10626,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 780,
      date: new Date("2020-06-13")
    },
    {
      id: 10627,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 4680,
      date: new Date("2020-06-08")
    },
    {
      id: 10628,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 2475,
      date: new Date("2020-06-25")
    },
    {
      id: 10629,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1920,
      date: new Date("2020-06-20")
    },
    {
      id: 10630,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 7500,
      date: new Date("2020-06-25")
    },
    {
      id: 10631,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5025,
      date: new Date("2020-06-26")
    },
    {
      id: 10632,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 2400,
      date: new Date("2020-06-08")
    },
    {
      id: 10633,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 1720,
      date: new Date("2020-06-09")
    },
    {
      id: 10634,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 2880,
      date: new Date("2020-06-21")
    },
    {
      id: 10635,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 5430,
      date: new Date("2020-06-03")
    },
    {
      id: 10636,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 4475,
      date: new Date("2020-06-19")
    },
    {
      id: 10637,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 1420,
      date: new Date("2020-06-20")
    },
    {
      id: 10638,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2670,
      date: new Date("2020-06-25")
    },
    {
      id: 10639,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1930,
      date: new Date("2020-06-02")
    },
    {
      id: 10640,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 580,
      date: new Date("2020-06-25")
    },
    {
      id: 10641,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1620,
      date: new Date("2020-06-12")
    },
    {
      id: 10642,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 4530,
      date: new Date("2020-06-02")
    },
    {
      id: 10643,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 6025,
      date: new Date("2020-06-23")
    },
    {
      id: 10644,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 3540,
      date: new Date("2020-07-21")
    },
    {
      id: 10645,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 3000,
      date: new Date("2020-07-01")
    },
    {
      id: 10646,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 3240,
      date: new Date("2020-07-26")
    },
    {
      id: 10647,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2265,
      date: new Date("2020-07-22")
    },
    {
      id: 10648,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 400,
      date: new Date("2020-07-09")
    },
    {
      id: 10649,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 1460,
      date: new Date("2020-07-08")
    },
    {
      id: 10650,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1620,
      date: new Date("2020-07-18")
    },
    {
      id: 10651,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2400,
      date: new Date("2020-07-25")
    },
    {
      id: 10652,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 5275,
      date: new Date("2020-07-04")
    },
    {
      id: 10653,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 4475,
      date: new Date("2020-07-03")
    },
    {
      id: 10654,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 3980,
      date: new Date("2020-07-21")
    },
    {
      id: 10655,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 5240,
      date: new Date("2020-07-11")
    },
    {
      id: 10656,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 1200,
      date: new Date("2020-07-21")
    },
    {
      id: 10657,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 5700,
      date: new Date("2020-07-18")
    },
    {
      id: 10658,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 5575,
      date: new Date("2020-07-01")
    },
    {
      id: 10659,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 2160,
      date: new Date("2020-07-02")
    },
    {
      id: 10660,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 960,
      date: new Date("2020-07-09")
    },
    {
      id: 10661,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1280,
      date: new Date("2020-07-04")
    },
    {
      id: 10662,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1040,
      date: new Date("2020-07-02")
    },
    {
      id: 10663,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 5520,
      date: new Date("2020-07-21")
    },
    {
      id: 10664,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1760,
      date: new Date("2020-07-25")
    },
    {
      id: 10665,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 4080,
      date: new Date("2020-07-07")
    },
    {
      id: 10666,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1000,
      date: new Date("2020-07-21")
    },
    {
      id: 10667,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 3270,
      date: new Date("2020-07-12")
    },
    {
      id: 10668,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 1770,
      date: new Date("2020-08-23")
    },
    {
      id: 10669,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 2700,
      date: new Date("2020-08-09")
    },
    {
      id: 10670,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2175,
      date: new Date("2020-08-03")
    },
    {
      id: 10671,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 3375,
      date: new Date("2020-08-11")
    },
    {
      id: 10672,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2040,
      date: new Date("2020-08-01")
    },
    {
      id: 10673,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 3000,
      date: new Date("2020-08-21")
    },
    {
      id: 10674,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 3900,
      date: new Date("2020-08-16")
    },
    {
      id: 10675,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1370,
      date: new Date("2020-08-20")
    },
    {
      id: 10676,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 5700,
      date: new Date("2020-08-01")
    },
    {
      id: 10677,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 1275,
      date: new Date("2020-08-22")
    },
    {
      id: 10678,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 4060,
      date: new Date("2020-08-13")
    },
    {
      id: 10679,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 2320,
      date: new Date("2020-08-18")
    },
    {
      id: 10680,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 7590,
      date: new Date("2020-08-24")
    },
    {
      id: 10681,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 4560,
      date: new Date("2020-08-20")
    },
    {
      id: 10682,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 7575,
      date: new Date("2020-08-20")
    },
    {
      id: 10683,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 700,
      date: new Date("2020-08-25")
    },
    {
      id: 10684,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2400,
      date: new Date("2020-08-16")
    },
    {
      id: 10685,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1390,
      date: new Date("2020-08-15")
    },
    {
      id: 10686,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1320,
      date: new Date("2020-08-09")
    },
    {
      id: 10687,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 1680,
      date: new Date("2020-08-09")
    },
    {
      id: 10688,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1500,
      date: new Date("2020-08-11")
    },
    {
      id: 10689,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 6150,
      date: new Date("2020-09-21")
    },
    {
      id: 10690,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 3675,
      date: new Date("2020-09-02")
    },
    {
      id: 10691,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 2250,
      date: new Date("2020-09-05")
    },
    {
      id: 10692,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 3585,
      date: new Date("2020-09-10")
    },
    {
      id: 10693,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1470,
      date: new Date("2020-09-01")
    },
    {
      id: 10694,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 2260,
      date: new Date("2020-09-02")
    },
    {
      id: 10695,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 3765,
      date: new Date("2020-09-03")
    },
    {
      id: 10696,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1640,
      date: new Date("2020-09-04")
    },
    {
      id: 10697,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 4475,
      date: new Date("2020-09-09")
    },
    {
      id: 10698,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5975,
      date: new Date("2020-09-04")
    },
    {
      id: 10699,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 1100,
      date: new Date("2020-09-16")
    },
    {
      id: 10700,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 1320,
      date: new Date("2020-09-18")
    },
    {
      id: 10701,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 8610,
      date: new Date("2020-09-19")
    },
    {
      id: 10702,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 9210,
      date: new Date("2020-09-09")
    },
    {
      id: 10703,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 3700,
      date: new Date("2020-09-01")
    },
    {
      id: 10704,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 3620,
      date: new Date("2020-09-19")
    },
    {
      id: 10705,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 4275,
      date: new Date("2020-09-01")
    },
    {
      id: 10706,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2370,
      date: new Date("2020-09-03")
    },
    {
      id: 10707,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1870,
      date: new Date("2020-09-10")
    },
    {
      id: 10708,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2070,
      date: new Date("2020-09-25")
    },
    {
      id: 10709,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5025,
      date: new Date("2020-09-19")
    },
    {
      id: 10710,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 1080,
      date: new Date("2020-10-15")
    },
    {
      id: 10711,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 1400,
      date: new Date("2020-10-22")
    },
    {
      id: 10712,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 4260,
      date: new Date("2020-10-01")
    },
    {
      id: 10713,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 2745,
      date: new Date("2020-10-01")
    },
    {
      id: 10714,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2920,
      date: new Date("2020-10-23")
    },
    {
      id: 10715,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 3520,
      date: new Date("2020-10-11")
    },
    {
      id: 10716,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4035,
      date: new Date("2020-10-20")
    },
    {
      id: 10717,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1730,
      date: new Date("2020-10-05")
    },
    {
      id: 10718,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 975,
      date: new Date("2020-10-06")
    },
    {
      id: 10719,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5700,
      date: new Date("2020-10-06")
    },
    {
      id: 10720,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 5080,
      date: new Date("2020-10-18")
    },
    {
      id: 10721,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 2940,
      date: new Date("2020-10-24")
    },
    {
      id: 10722,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 2670,
      date: new Date("2020-10-04")
    },
    {
      id: 10723,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 1230,
      date: new Date("2020-10-11")
    },
    {
      id: 10724,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 600,
      date: new Date("2020-10-08")
    },
    {
      id: 10725,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 3700,
      date: new Date("2020-10-08")
    },
    {
      id: 10726,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 3375,
      date: new Date("2020-10-11")
    },
    {
      id: 10727,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1500,
      date: new Date("2020-10-17")
    },
    {
      id: 10728,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 370,
      date: new Date("2020-10-05")
    },
    {
      id: 10729,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2340,
      date: new Date("2020-10-16")
    },
    {
      id: 10730,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 1080,
      date: new Date("2020-10-08")
    },
    {
      id: 10731,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 2775,
      date: new Date("2020-10-21")
    },
    {
      id: 10732,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 4380,
      date: new Date("2020-11-09")
    },
    {
      id: 10733,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 5500,
      date: new Date("2020-11-21")
    },
    {
      id: 10734,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 1920,
      date: new Date("2020-11-24")
    },
    {
      id: 10735,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 765,
      date: new Date("2020-11-24")
    },
    {
      id: 10736,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 370,
      date: new Date("2020-11-18")
    },
    {
      id: 10737,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 3500,
      date: new Date("2020-11-25")
    },
    {
      id: 10738,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 825,
      date: new Date("2020-11-09")
    },
    {
      id: 10739,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 490,
      date: new Date("2020-11-23")
    },
    {
      id: 10740,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 7075,
      date: new Date("2020-11-20")
    },
    {
      id: 10741,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 1350,
      date: new Date("2020-11-25")
    },
    {
      id: 10742,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 1440,
      date: new Date("2020-11-15")
    },
    {
      id: 10743,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 2820,
      date: new Date("2020-11-13")
    },
    {
      id: 10744,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 2280,
      date: new Date("2020-11-12")
    },
    {
      id: 10745,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 1110,
      date: new Date("2020-11-03")
    },
    {
      id: 10746,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 1150,
      date: new Date("2020-11-23")
    },
    {
      id: 10747,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 2040,
      date: new Date("2020-11-20")
    },
    {
      id: 10748,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 3090,
      date: new Date("2020-11-24")
    },
    {
      id: 10749,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1940,
      date: new Date("2020-11-24")
    },
    {
      id: 10750,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 3090,
      date: new Date("2020-11-16")
    },
    {
      id: 10751,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 4900,
      date: new Date("2020-11-05")
    },
    {
      id: 10752,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 3465,
      date: new Date("2020-11-07")
    },
    {
      id: 10753,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1110,
      date: new Date("2020-11-20")
    },
    {
      id: 10754,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 1650,
      date: new Date("2020-11-02")
    },
    {
      id: 10755,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 5280,
      date: new Date("2020-12-04")
    },
    {
      id: 10756,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 3075,
      date: new Date("2020-12-02")
    },
    {
      id: 10757,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 690,
      date: new Date("2020-12-07")
    },
    {
      id: 10758,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1305,
      date: new Date("2020-12-15")
    },
    {
      id: 10759,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1970,
      date: new Date("2020-12-01")
    },
    {
      id: 10760,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 3760,
      date: new Date("2020-12-18")
    },
    {
      id: 10761,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1920,
      date: new Date("2020-12-22")
    },
    {
      id: 10762,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1360,
      date: new Date("2020-12-12")
    },
    {
      id: 10763,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 2525,
      date: new Date("2020-12-06")
    },
    {
      id: 10764,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 5575,
      date: new Date("2020-12-20")
    },
    {
      id: 10765,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 5560,
      date: new Date("2020-12-10")
    },
    {
      id: 10766,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 4880,
      date: new Date("2020-12-13")
    },
    {
      id: 10767,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 8850,
      date: new Date("2020-12-03")
    },
    {
      id: 10768,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 2820,
      date: new Date("2020-12-10")
    },
    {
      id: 10769,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 4000,
      date: new Date("2020-12-12")
    },
    {
      id: 10770,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 5820,
      date: new Date("2020-12-02")
    },
    {
      id: 10771,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 1275,
      date: new Date("2020-12-12")
    },
    {
      id: 10772,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1310,
      date: new Date("2020-12-01")
    },
    {
      id: 10773,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 2180,
      date: new Date("2020-12-26")
    },
    {
      id: 10774,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4470,
      date: new Date("2020-12-17")
    },
    {
      id: 10775,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2990,
      date: new Date("2020-12-15")
    },
    {
      id: 10776,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 7650,
      date: new Date("2020-12-18")
    },
    {
      id: 10777,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 780,
      date: new Date("2020-12-02")
    },
    {
      id: 10778,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2970,
      date: new Date("2020-12-13")
    },
    {
      id: 10779,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1155,
      date: new Date("2020-12-05")
    },
    {
      id: 10780,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 4470,
      date: new Date("2021-01-10")
    },
    {
      id: 10781,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 1125,
      date: new Date("2021-01-21")
    },
    {
      id: 10782,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 645,
      date: new Date("2021-01-17")
    },
    {
      id: 10783,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 675,
      date: new Date("2021-01-05")
    },
    {
      id: 10784,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2840,
      date: new Date("2021-01-05")
    },
    {
      id: 10785,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 2660,
      date: new Date("2021-01-04")
    },
    {
      id: 10786,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4560,
      date: new Date("2021-01-12")
    },
    {
      id: 10787,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2880,
      date: new Date("2021-01-20")
    },
    {
      id: 10788,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 500,
      date: new Date("2021-01-02")
    },
    {
      id: 10789,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 3925,
      date: new Date("2021-01-07")
    },
    {
      id: 10790,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 5660,
      date: new Date("2021-01-18")
    },
    {
      id: 10791,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 1460,
      date: new Date("2021-01-22")
    },
    {
      id: 10792,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 5040,
      date: new Date("2021-01-10")
    },
    {
      id: 10793,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 4830,
      date: new Date("2021-01-13")
    },
    {
      id: 10794,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 3075,
      date: new Date("2021-01-22")
    },
    {
      id: 10795,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 3120,
      date: new Date("2021-01-14")
    },
    {
      id: 10796,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 3525,
      date: new Date("2021-01-23")
    },
    {
      id: 10797,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1930,
      date: new Date("2021-01-09")
    },
    {
      id: 10798,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 2890,
      date: new Date("2021-01-02")
    },
    {
      id: 10799,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 1545,
      date: new Date("2021-01-17")
    },
    {
      id: 10800,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 3630,
      date: new Date("2021-01-20")
    },
    {
      id: 10801,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 4035,
      date: new Date("2021-01-14")
    },
    {
      id: 10802,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 345,
      date: new Date("2021-01-06")
    },
    {
      id: 10803,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 7000,
      date: new Date("2021-01-07")
    },
    {
      id: 10804,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 3060,
      date: new Date("2021-02-13")
    },
    {
      id: 10805,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 6425,
      date: new Date("2021-02-04")
    },
    {
      id: 10806,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 615,
      date: new Date("2021-02-22")
    },
    {
      id: 10807,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1755,
      date: new Date("2021-02-07")
    },
    {
      id: 10808,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1540,
      date: new Date("2021-02-21")
    },
    {
      id: 10809,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 2820,
      date: new Date("2021-02-24")
    },
    {
      id: 10810,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4305,
      date: new Date("2021-02-10")
    },
    {
      id: 10811,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 1520,
      date: new Date("2021-02-26")
    },
    {
      id: 10812,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 4725,
      date: new Date("2021-02-18")
    },
    {
      id: 10813,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 6750,
      date: new Date("2021-02-16")
    },
    {
      id: 10814,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 5540,
      date: new Date("2021-02-07")
    },
    {
      id: 10815,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 1880,
      date: new Date("2021-02-24")
    },
    {
      id: 10816,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 6180,
      date: new Date("2021-02-26")
    },
    {
      id: 10817,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 9300,
      date: new Date("2021-02-03")
    },
    {
      id: 10818,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 3700,
      date: new Date("2021-02-26")
    },
    {
      id: 10819,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 740,
      date: new Date("2021-02-01")
    },
    {
      id: 10820,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 4755,
      date: new Date("2021-02-23")
    },
    {
      id: 10821,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2570,
      date: new Date("2021-02-20")
    },
    {
      id: 10822,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 2860,
      date: new Date("2021-02-19")
    },
    {
      id: 10823,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 5430,
      date: new Date("2021-03-21")
    },
    {
      id: 10824,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 4525,
      date: new Date("2021-03-21")
    },
    {
      id: 10825,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 1515,
      date: new Date("2021-03-10")
    },
    {
      id: 10826,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 630,
      date: new Date("2021-03-15")
    },
    {
      id: 10827,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1310,
      date: new Date("2021-03-01")
    },
    {
      id: 10828,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 3200,
      date: new Date("2021-03-17")
    },
    {
      id: 10829,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 3945,
      date: new Date("2021-03-20")
    },
    {
      id: 10830,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2990,
      date: new Date("2021-03-18")
    },
    {
      id: 10831,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 1125,
      date: new Date("2021-03-22")
    },
    {
      id: 10832,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 7950,
      date: new Date("2021-03-17")
    },
    {
      id: 10833,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 2960,
      date: new Date("2021-03-25")
    },
    {
      id: 10834,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 6300,
      date: new Date("2021-03-20")
    },
    {
      id: 10835,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 8670,
      date: new Date("2021-03-07")
    },
    {
      id: 10836,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 3930,
      date: new Date("2021-03-23")
    },
    {
      id: 10837,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 6975,
      date: new Date("2021-03-02")
    },
    {
      id: 10838,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4220,
      date: new Date("2021-03-17")
    },
    {
      id: 10839,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 3090,
      date: new Date("2021-03-25")
    },
    {
      id: 10840,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2380,
      date: new Date("2021-03-01")
    },
    {
      id: 10841,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1510,
      date: new Date("2021-03-07")
    },
    {
      id: 10842,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 1020,
      date: new Date("2021-03-19")
    },
    {
      id: 10843,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 6700,
      date: new Date("2021-03-26")
    },
    {
      id: 10844,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 4890,
      date: new Date("2021-04-02")
    },
    {
      id: 10845,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 7225,
      date: new Date("2021-04-13")
    },
    {
      id: 10846,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 795,
      date: new Date("2021-04-07")
    },
    {
      id: 10847,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1785,
      date: new Date("2021-04-03")
    },
    {
      id: 10848,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1850,
      date: new Date("2021-04-03")
    },
    {
      id: 10849,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 5120,
      date: new Date("2021-04-12")
    },
    {
      id: 10850,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 615,
      date: new Date("2021-04-07")
    },
    {
      id: 10851,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2860,
      date: new Date("2021-04-05")
    },
    {
      id: 10852,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 1525,
      date: new Date("2021-04-24")
    },
    {
      id: 10853,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 7425,
      date: new Date("2021-04-15")
    },
    {
      id: 10854,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 6080,
      date: new Date("2021-04-13")
    },
    {
      id: 10855,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 2940,
      date: new Date("2021-04-04")
    },
    {
      id: 10856,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 5580,
      date: new Date("2021-04-16")
    },
    {
      id: 10857,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 9390,
      date: new Date("2021-04-19")
    },
    {
      id: 10858,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 3200,
      date: new Date("2021-04-26")
    },
    {
      id: 10859,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4380,
      date: new Date("2021-04-05")
    },
    {
      id: 10860,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 4725,
      date: new Date("2021-04-06")
    },
    {
      id: 10861,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 930,
      date: new Date("2021-04-25")
    },
    {
      id: 10862,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 1910,
      date: new Date("2021-04-05")
    },
    {
      id: 10863,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 2725,
      date: new Date("2021-04-16")
    },
    {
      id: 10864,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 4720,
      date: new Date("2021-04-02")
    },
    {
      id: 10865,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 5190,
      date: new Date("2021-04-10")
    },
    {
      id: 10866,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 2800,
      date: new Date("2021-04-26")
    },
    {
      id: 10867,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 3780,
      date: new Date("2021-04-24")
    },
    {
      id: 10868,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 2340,
      date: new Date("2021-04-17")
    },
    {
      id: 10869,
      region: "Wilson",
      country: "United States of America",
      city: "Kelly Rodriquez",
      amount: 4830,
      date: new Date("2021-05-12")
    },
    {
      id: 10870,
      region: "Wilson",
      country: "United States of America",
      city: "Walter Hobbs",
      amount: 2075,
      date: new Date("2021-05-23")
    },
    {
      id: 10871,
      region: "Wilson",
      country: "United States of America",
      city: "Robert Reagan",
      amount: 3420,
      date: new Date("2021-05-21")
    },
    {
      id: 10872,
      region: "Wilson",
      country: "Canada",
      city: "Robert Reagan",
      amount: 1440,
      date: new Date("2021-05-10")
    },
    {
      id: 10873,
      region: "Wilson",
      country: "Canada",
      city: "Greta Sims",
      amount: 1680,
      date: new Date("2021-05-15")
    },
    {
      id: 10874,
      region: "Commerce",
      country: "Brazil",
      city: "Olivia Peyton",
      amount: 3440,
      date: new Date("2021-05-16")
    },
    {
      id: 10875,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 4695,
      date: new Date("2021-05-10")
    },
    {
      id: 10876,
      region: "Commerce",
      country: "Paraguay",
      city: "Asuncion",
      amount: 2380,
      date: new Date("2021-05-06")
    },
    {
      id: 10877,
      region: "Fair Lakes",
      country: "United Kingdom",
      city: "Brett Wade",
      amount: 1875,
      date: new Date("2021-05-25")
    },
    {
      id: 10878,
      region: "Fair Lakes",
      country: "Germany",
      city: "Sam Hill",
      amount: 7550,
      date: new Date("2021-05-14")
    },
    {
      id: 10879,
      region: "Fair Lakes",
      country: "Spain",
      city: "Greta Sims",
      amount: 3340,
      date: new Date("2021-05-01")
    },
    {
      id: 10880,
      region: "Fair Lakes",
      country: "Russian Federation",
      city: "Brett Wade",
      amount: 1400,
      date: new Date("2021-05-22")
    },
    {
      id: 10881,
      region: "Lee Hwy",
      country: "China",
      city: "Sandra Johnson",
      amount: 6060,
      date: new Date("2021-05-22")
    },
    {
      id: 10882,
      region: "Lee Hwy",
      country: "Japan",
      city: "Olivia Peyton",
      amount: 8370,
      date: new Date("2021-05-13")
    },
    {
      id: 10883,
      region: "Lee Hwy",
      country: "Republic of Korea",
      city: "Kevin Carter",
      amount: 3550,
      date: new Date("2021-05-26")
    },
    {
      id: 10884,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Cyntia Stanwick",
      amount: 2620,
      date: new Date("2021-05-17")
    },
    {
      id: 10885,
      region: "Lee Hwy",
      country: "Lee Hwy",
      city: "Kent Samuelson",
      amount: 2400,
      date: new Date("2021-05-21")
    },
    {
      id: 10886,
      region: "Commerce",
      country: "South Commerce",
      city: "Cynthia Stanwick",
      amount: 1740,
      date: new Date("2021-05-21")
    },
    {
      id: 10887,
      region: "Commerce",
      country: "Egypt",
      city: "Taylor Riley",
      amount: 500,
      date: new Date("2021-05-26")
    },
    {
      id: 10888,
      region: "Commerce",
      country: "Argentina",
      city: "Walter Hobbs",
      amount: 780,
      date: new Date("2021-05-07")
    }
  ];
  
  export default {
    getSales() {
      return sales;
    }
  };