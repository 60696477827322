import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
  SimpleItem,
} from "devextreme-react/form";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import { createAccount, editAccount, disableAccount } from "../../api/auth";
import "./create-account-form.scss";
import { SingleCard } from "../../layouts";
import { useAuth } from "../../contexts/auth";
import { baseAxios } from "../../utils/config";

import JsBarcode from "jsbarcode";
import { Button, Popup } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/popup";

export default function CreateAccountForm(props) {
  const { user: userAuth } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const { id: userId } = useParams();

  const [loading, setLoading] = useState(false);
  const formData = useRef({ generate_barcode: false });
  const [formRenderKey, setFormRenderKey] = useState(0);
  const [locations, setLocations] = useState([]);
  const [generateBarcode, setGenerateBarcode] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const stylistRole = searchParams.get("role");

  const barcodeNodeRef = useRef(null);
  const barcodeRef = useCallback(node => {
    if (node !== null) {
      barcodeNodeRef.current = node;
      regenerateBarcode();
    }
  }, []);

  useEffect(async () => {
    const res = await baseAxios.get("/organization/-/locations");
    setLocations(res.data.locations);
  }, []);

  useEffect(() => {
    const fetchUserById = async () => {
      try {
        if (!locations) {
          return;
        }

        const response = await baseAxios.get(`/users/${userId}`);
        const user = response.data.user;

        if (user) {
          const generateBarcode = !!user.user_barcode;
          user.roles = user.roles.map(r => r.role);
          formData.current = {
            ...formData.current,
            ...user,
            generate_barcode: generateBarcode,
          };
          setGenerateBarcode(generateBarcode);
          setFormRenderKey((prevKey) => prevKey + 1);
        }
      } catch (error) {
        console.error("Failed to fetch service details", error);
      }
    };
    if (userId) {
      setLoading(true);
      fetchUserById();
      setLoading(false);
    }
  }, [userId, locations]);

  function newBarcode() {
    return Array.from(
      {length: 12},
      () => Math.floor(Math.random() * 10)
    ).join('');
  }

  function drawBarcode(container, randomCode) {
    if (!randomCode) {
      return;
    }

    container.innerHTML = '';

    const canvas = document.createElement("canvas");
    JsBarcode(canvas, randomCode);

    const img = document.createElement("img");
    img.className = '';
    img.src = canvas.toDataURL();
    container.appendChild(img);

    const link = document.createElement('a');
    link.className = 'dx-widget dx-button dx-button-mode-contained dx-button-default';
    link.style = 'text-decoration: none; margin: auto 0; display: block;';
    link.role = 'button';
    link.download = 'barcode.png';
    link.href = canvas.toDataURL();
    link.innerHTML = '<div class="dx-button-content">Download barcode</div>';
    container.appendChild(link);
  }

  function regenerateBarcode(clean = false) {
    if (!barcodeNodeRef.current) {
      return;
    }

    if (!clean && !formData.current.user_barcode) {
      return;
    }
    if (clean) {
      formData.current.user_barcode = newBarcode();
    }
    drawBarcode(barcodeNodeRef.current, formData.current.user_barcode);
  }

  useEffect(() => {
    if (generateBarcode) {
      if (!formData.current.user_barcode) {
        formData.current.user_barcode = newBarcode();
      }
      regenerateBarcode();
    } else {
      formData.current.user_barcode = null;
      if (barcodeNodeRef.current)
        barcodeNodeRef.current.innerHTML = '';
    }
  }, [generateBarcode, formRenderKey]);

  let roles;
  if (userAuth.role.toLowerCase() === "system admin") {
    roles = [
      {id: "stylist", value: "Stylist"},
      {id: "salon admin", value: "Salon Admin"},
      {id: "front desk", value: "Front Desk"},
      {id: "system admin", value: "System Admin"},
    ];
  } else if (stylistRole) {
    roles = [{id: "stylist", value: "Stylist"}];
  } else {
    roles = [
      {id: "stylist", value: "Stylist"},
      {id: "salon admin", value: "Salon Admin"},
      {id: "front desk", value: "Front Desk"},
    ];
  }

  const firstNameEditorOptions = {
    stylingMode: "filled",
  };
  const lastNameEditorOptions = {
    stylingMode: "filled",
  };
  const emailEditorOptions = {
    stylingMode: "filled",
    mode: "email",
  };
  const phoneEditorOptions = {
    stylingMode: "filled",
    mask: "+1 (000) 000-0000"
  };
  const roleEditorOptions = {
    items: roles,
    searchEnabled: false,
    valueExpr: "id",
    displayExpr: "value",
  };
  const locationEditorOptions = {
    dataSource: locations,
    searchEnabled: false,
    valueExpr: "location_id",
    displayExpr: "location_name",
    multiple: true,
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const {
        user_first_name,
        user_last_name,
        user_email,
        user_phone,
        location_id,
        roles,
        user_barcode,
        user_status,
      } = formData.current;

      setLoading(true);

      let result;
      if (userId) {
        result = await editAccount(
          userId,
          user_first_name,
          user_last_name,
          user_email,
          user_phone,
          roles,
          `${location_id}`,
          user_barcode,
          user_status,
        )
      } else {
        const organization = userAuth.org;
        result = await createAccount(
          user_first_name,
          user_last_name,
          user_email,
          user_phone,
          roles,
          `${location_id}`,
          user_barcode
        );
      }
      setLoading(false);

      if (result.isOk) {
        history.push("/manage-users");
        notify(result.message, "success", 3000);
      } else {
        notify(result.message, "error", 5000);
      }
    },
    [history]
  );

  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);

  const handleCancel = () => {
    setIsConfirmationPopupVisible(false);
    setFormRenderKey((prevKey) => prevKey + 1);
  };

  const handleConfirm = async () => {
    const {
      user_first_name,
      user_last_name,
      user_email,
      user_phone,
      location_id,
      roles,
      user_barcode,
      user_status,
    } = formData.current;

    setLoading(true);
    await editAccount(
      userId,
      user_first_name,
      user_last_name,
      user_email,
      user_phone,
      roles,
      `${location_id}`,
      user_barcode,
      'INACTIVE',
    );
    setLoading(false);

    history.push({
      pathname: "/manage-users",
    });
  };

  return (
    <>
      <Popup
        visible={isConfirmationPopupVisible}
        onHiding={handleCancel}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Deactivate Account"
        showCloseButton={true}
        width={300}
        height={200}
      >
        <p>
          Are you sure you want to deactivate this user?
        </p>
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          options={{
            text: 'Cancel',
            type: 'default',
            stylingMode: 'contained',
            onClick: () => handleCancel(),
          }}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          options={{
            text: 'Confirm',
            type: 'default',
            stylingMode: 'contained',
            onClick: () => handleConfirm(),
          }}
        />
      </Popup>
      <SingleCard title="Add New User">
        <form className={"create-account-form"} onSubmit={onSubmit}>
          <Form
            key={formRenderKey}
            formData={formData.current}
            disabled={loading}
          >
            <Item
              dataField={"user_first_name"}
              editorType={"dxTextBox"}
              editorOptions={firstNameEditorOptions}
            >
              <RequiredRule message="First name is required" />
            </Item>

            <Item
              dataField={"user_last_name"}
              editorType={"dxTextBox"}
              editorOptions={lastNameEditorOptions}
            >
              <RequiredRule message="Last name is required" />
            </Item>
            <Item
              dataField={"user_email"}
              editorType={"dxTextBox"}
              editorOptions={emailEditorOptions}
              disabled={userId ? true : false}
            >
              <RequiredRule message="Email is required" />
              <EmailRule message="Email is invalid" />
            </Item>
            <Item
              dataField={"user_phone"}
              editorType={"dxTextBox"}
              editorOptions={phoneEditorOptions}
            >
            </Item>
            <Item
              dataField={"location_id"}
              editorType={"dxSelectBox"}
              editorOptions={locationEditorOptions}
              valueExpr="location_id"
              displayExpr="location_name"
            >
              <RequiredRule message={"User must be assigned a location"} />
            </Item>
            <Item
              dataField={"roles"}
              editorType={"dxTagBox"}
              editorOptions={roleEditorOptions}
            >
              <RequiredRule message={"User must be assigned a role"} />
            </Item>

            <Item
              dataField={'generate_barcode'}
              editorType={"dxCheckBox"}
              editorOptions={{
                text: "Generate Barcode",
                onValueChanged: function (e) {
                  setGenerateBarcode(e.value);
                }
              }}
            >
              <Label visible={false} />
            </Item>

            <SimpleItem>
              <div ref={barcodeRef} style={{ textAlign: 'center' }}></div>
              {generateBarcode && (
                <div style={{ textAlign: 'center', marginTop: '1rem'  }}>
                  <Button onClick={() => regenerateBarcode(true)}>Regenerate barcode</Button>
                </div>
              )}
            </SimpleItem>

            <Item
              dataField={'user_barcode'}
              editorType={'dxTextBox'}
              editorOptions={{
                readOnly: true,
                visible: false
              }}
            >
              <Label visible={false} />
            </Item>

            <ButtonItem>
              <ButtonOptions
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    userId ? "Edit Account" : "Create a new account"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
            <Item>
              {userId && (
                <div style={{ textAlign: 'center' }}>
                  <Button type="danger" onClick={() => setIsConfirmationPopupVisible(true)}>Deactivate Account</Button>
                </div>
              )}
            </Item>

          </Form>
        </form>
      </SingleCard>
    </>
  );
}
