import React from 'react';
import './Recipes.scss';
import  TreeList, {
  Column, ColumnChooser, HeaderFilter, SearchPanel, SpeedDialAction, Lookup,
}  from 'devextreme-react/tree-list';
import Button from 'devextreme-react/button';
import recipes from './recipeData';
import GetRecipes from './GetRecipes';
import CustomStore from "devextreme/data/custom_store"
//import GetRecipes from './GetRecipes copy';

// let data = GetRecipes();
// let store = new CustomStore({
//   key: "Recipe_ID",
//   async load() {
//     const promise = new Promise((resolve, reject) => {
//       setTimeout(() => {
//         resolve(data);
//       }, 1000);
//       setTimeout(() => {
//         reject("Data loading error");
//       }, 5000);
//     });
//     promise.then((res) => {
//       return res;
//     });
//     return await promise;
//   },
//   async insert(values) {
//     let id = data.length + 1;
//     const promise = new Promise((resolve, reject) => {
//       setTimeout(() => {
//         resolve(data.push({...values, recipes: id}));
//       }, 1000);
//       setTimeout(() => {
//         reject("Data inserting error");
//       }, 3000);
//     });
//     promise.then((res) =>{
//       return res;
//     });
//     return await promise;
//   },
//   async update(key, values) {
//     const promise = new Promise((resolve, reject) => {
//       setTimeout(() => {
//         resolve(
//           (data[key - 1] = { ...data[key - 1], ...values, recipes: key })
//         );
//       }, 1000);
//       setTimeout(() => {
//         reject("Data updating error");
//       }, 3000);
//     });
//     promise.then((res) => {
//       return res;
//     });
//     return await promise;
//   },

//   async remove(key) {
//     const promise = new Promise((resolve, reject) => {
//       setTimeout(() => {
//         resolve(delete data[key - 1]);
//       }, 1000);
//       setTimeout(() => {
//         reject("Data removing error");
//       }, 3000);
//     });
//     promise.then((res) => {
//       return res;
//     });
//     return await promise;
//   }
// });




function RecipesTree() {
  const expandedKeys = [2];
  const selectedKeys = [1];
  const treeList = React.useRef(null);


  const refreshTreeList = React.useCallback(() => {
    treeList.current.instance.refresh(); //Not yet working, doesn't call TreeList's refresh method
  }, []);

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Recipes</h2>
      <Button onClick={refreshTreeList} icon = "refresh" text = "Refresh list" />

      <TreeList ref = {treeList}
        //dataSource = {recipes.getRecipes()}
        dataSource={GetRecipes()}
        showBorders = {true}
        columnAutoWidth = {true}
        wordWrapEnabled = {true}
        defaultExpandedRowKeys={expandedKeys}
        defaultSelectedRowKeys={selectedKeys}
        rootValue={-1}
        keyExpr={'Recipe_ID'}
        parentIdExpr={'Parent_Recipe_ID'}
        id = 'recipes'
      >
        
        <SearchPanel visible={true} width={250}/>
        <HeaderFilter visible={true}/>
        <ColumnChooser enabled={false}/>
        
        <Column dataField={'Manufacturer'} />
        <Column dataField={'Brand'} />
        <Column dataField={'Category'} />
        <Column dataField={'GreyWhiteCoverage'} caption={'Coverage'} />
        <Column dataField={'Lift'} />
        <Column dataField={'MinRatio'} />
        <Column dataField={'MaxRatio'} caption={'Mixing Ratio'} />
        <Column dataField={'Timing'} />
      </TreeList>

      {/* <h5 className={'content-block'}>Manually Entered Recipes</h5> */}

    </React.Fragment>
  );
}

export default RecipesTree;
// export default () => (


//   <React.Fragment>
//     <h2 className={'content-block'}>Recipes</h2>
//     <Button
//       onClick={treeList.instance.refresh()}
//       icon = "refresh"
//       text = "Refresh list."
//     />
//     <TreeList ref = {treeList}
//       //dataSource = {recipes.getRecipes()}
//       dataSource={GetRecipes()}
//       showBorders = {true}
//       columnAutoWidth = {true}
//       wordWrapEnabled = {true}
//       defaultExpandedRowKeys={expandedKeys}
//       defaultSelectedRowKeys={selectedKeys}
//       rootValue={-1}
//       keyExpr={'Recipe_ID'}
//       parentIdExpr={'Recipe_Parent_ID'}
//       id = 'recipes'
//     >
      
//       <SearchPanel visible={true} width={250}/>
//       <HeaderFilter visible={true}/>
//       <ColumnChooser enabled={false}/>
      
//       <Column
//         dataField={'Manufacturer'}
//         fixed={false}
//       />

//       <Column 
//         dataField={'Brand'}
//         fixed={false}
//       />

//       <Column
//         dataField={'Category'}
//         />
//       <Column
//         dataField={'Color_Code'}
//         caption={'Product'}
//       />

//       <Column
//         dataField={'Developers'}
//       />

//       <Column
//         dataField={'Ratio'}
//         caption={'Mixing Ratio'}
//       />
//     </TreeList>

//     {/* <h5 className={'content-block'}>Manually Entered Recipes</h5> */}

//   </React.Fragment>
// );
