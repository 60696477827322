import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import "./bar1.style.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  borderRadius: 2,
};

export default function Bar1({ value, onClick }) {
  const [data, setData] = useState({ datasets: [] });
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    const newData = {
      label: [],
      total: [],
      average: [],
      transactions: [],
    }

    value.map((item) => {
      newData.label.push(item.Stylist);
      newData.total.push(item.total_spent);
      newData.average.push(item.average_spent * 10);
      newData.transactions.push(item.total_number * 4);
    });

    setData(prev => ({
      ...prev,
      labels: newData.label,
      datasets: [
        {
          label: "Total",
          data: newData.total,
          backgroundColor: "#0dcae7",
        },
        {
          label: "Average",
          data: newData.average, // multiply by 10
          backgroundColor: "#6ee60b",
        },
        {
          label: "Transactions",
          data: newData.transactions, // multiply by 4
          backgroundColor: "#ed0f7e",
        },
      ],
    }));
  }, [value]);

  useEffect(() => {
    const newData = {
      labels: ["-", "-", "-", "-", "-"],
      datasets: [
        {
          label: "Total",
          data: [150, 150, 150, 150, 150],
          backgroundColor: "#0dcae7",
        },
        {
          label: "Average",
          data: [70, 70, 70, 70, 70], // multiply by 10
          backgroundColor: "#6ee60b",
        },
        {
          label: "Transactions",
          data: [40, 40, 40, 40, 40], // multiply by 4
          backgroundColor: "#ed0f7e",
        },
      ],
    };

    const newOptions = {
      ...defaultOptions,
      plugins: {
        datalabels: {
          display: false,
        },
        title: {
          display: true,
          text: 'Total Cost Per Transaction',
          position: 'left',
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (data) {
              const datasetIndex = data.datasetIndex;
              const label = data.dataset.label;
              const value = data.formattedValue;

              if (datasetIndex === 0) {
                return `${label}: $${value}`
              }

              if (datasetIndex === 1) {
                return `${label}: $${(value / 10).toFixed(2)}`
              }

              if (datasetIndex === 2) {
                return `${label}: ${(value / 4).toFixed(0)}`
              }
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            stepSize: 50,
          },
        },
        x: {
          grid: {
            display: false,
          },
          offset: true,
        },
      },
    };

    setData(newData);
    setOptions(newOptions);
  }, []);

  return (
    <div className="bar-body" onClick={onClick}>
      <h1 className="bar-title">Total Stylist Spending</h1>
      <div className="bar-container">
        <Bar data={data} options={options} onElementsClick={onClick} />
      </div>
    </div>
  );
}
