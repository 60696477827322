import { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "./bar4.style.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  borderRadius: 5,
};

export default function Bar4({ value, onClick }) {
  const [data, setData] = useState({ datasets: [] });
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    const newData = {
      labels: [],
      datasets: [
        {
          barPercentage: 1.15,
          categorySpacing: 0,
          data: [],
          backgroundColor: "#6ee60b",
        },
      ],
    };

    const newOptions = {
      ...defaultOptions,
      scales: {
        y: {
          ticks: {
            stepSize: 5,
            min: 0,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          color: 'black',
          offset: 0,
          font: {
            weight: 'bold'
          },
          formatter: function (value) {
            return value;
          }
        },
      },
    };

    setData(newData);
    setOptions(newOptions);
  }, []);

  useEffect(() => {
    const values = {
      label: [],
      bar: []
    }

    value.map((item) => {
      let truncatedString = item.product_name.substring(0, 6);

      if (item.product_name.length > 6) {
        truncatedString += "...";
      }
      values.label.push(truncatedString);
      values.bar.push(item?.inventory_quantity_needed?.toFixed(2));
    });

    const newData = {
      labels: values.label,
      datasets: [
        {
          barPercentage: 1.15,
          categorySpacing: 0,
          data: values.bar,
          backgroundColor: "#6ee60b",
        },
      ],
    };

    setData(newData);
  }, [value]);

  return (
    <div className="bar4-body" onClick={onClick}>
      <div className="bar4-container">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}
