export const cups = [
    [
        {
            client: "Jane",
            date: "2023-04-13",
            cups: [
                {
                    name: "Cup 1",
                    time: "2023-04-13T10:05:00.000Z",
                    resources: [
                        {
                            name: "Blondme",
                            amount: 45
                        },
                        {
                            name: "20 vol Blondme",
                            amount: 135
                        }
                    ]
                },
                {
                    name: "Cup 2",
                    time: "2023-04-13T11:00:00.000Z",
                    resources: [
                        {
                            name: "9t",
                            amount: 60
                        },
                        {
                            name: "Proc Sol",
                            amount: 60
                        }
                    ]
                }
            ]
        },
        {
            client: "Jane",
            date: "2023-04-13",
            cups: [
                {
                    name: "Cup 1",
                    time: "2023-04-13T10:05:00.000Z",
                    resources: [
                        {
                            name: "Blondme",
                            amount: 45
                        },
                        {
                            name: "20 vol Blondme",
                            amount: 135
                        }
                    ]
                },
                {
                    name: "Cup 2",
                    time: "2023-04-13T11:00:00.000Z",
                    resources: [
                        {
                            name: "9t",
                            amount: 60
                        },
                        {
                            name: "Proc Sol",
                            amount: 60
                        }
                    ]
                }
            ]
        },
        {
            client: "Jane",
            date: "2023-04-13",
            cups: [
                {
                    name: "Cup 1",
                    time: "2023-04-13T10:05:00.000Z",
                    resources: [
                        {
                            name: "Blondme",
                            amount: 45
                        },
                        {
                            name: "20 vol Blondme",
                            amount: 135
                        }
                    ]
                },
                {
                    name: "Cup 2",
                    time: "2023-04-13T11:00:00.000Z",
                    resources: [
                        {
                            name: "9t",
                            amount: 60
                        },
                        {
                            name: "Proc Sol",
                            amount: 60
                        }
                    ]
                }
            ]
        },],
    [{
        client: "Maria",
        date: "2023-04-14",
    }, {
        client: "Maria",
        date: "2023-04-14",
    }, {
        client: "Maria",
        date: "2023-04-14",
    },],
    [{
        client: "Susie",
        date: "2023-04-15",
    }, {
        client: "Susie",
        date: "2023-04-15",
    }, {
        client: "Susie",
        date: "2023-04-15",
    }]
]


export const data = [
    {
        client: "Jane",
        date: "2023-04-13",
        cups: [
            {
                name: "Cup 1",
                time: "2023-04-13T10:05:00.000Z",
                resources: [
                    {
                        name: "Blondme",
                        amount: 45
                    },
                    {
                        name: "20 vol Blondme",
                        amount: 135
                    }
                ]
            },
            {
                name: "Cup 2",
                time: "2023-04-13T11:00:00.000Z",
                resources: [
                    {
                        name: "9t",
                        amount: 60
                    },
                    {
                        name: "Proc Sol",
                        amount: 60
                    }
                ]
            }
        ]
    },
    {
        client: "Maria",
        date: "2023-04-14",
        cups: [
            {
                name: "Cup 1",
                time: "2023-04-15T14:15:00.000Z",
                resources: [
                    {
                        name: "7-00",
                        amount: 40
                    },
                    {
                        name: "6-4",
                        amount: 70
                    },
                    {
                        name: "20 vol",
                        amount: 60
                    }
                ]
            },
            {
                name: "Cup 2",
                time: "2023-04-15T15:00:00.000Z",
                resources: [
                    {
                        name: "Vario blond",
                        amount: 70
                    },
                    {
                        name: "20 vol",
                        amount: 140
                    }
                ]
            },
            {
                name: "Cup 3",
                time: "2023-04-15T15:45:00.000Z",
                resources: [
                    {
                        name: "9N",
                        amount: 60
                    },
                    {
                        name: "Proc Sol",
                        amount: 60
                    }
                ]
            }
        ]
    },
    {
        client: "Susie",
        date: "2023-04-15",
        cups: [
            {
                name: "Cup 1",
                time: "2023-04-15T11:15:00.000Z",
                resources: [
                    {
                        name: "Sch 7-00",
                        amount: 60
                    },
                    {
                        name: "20 vol",
                        amount: 60
                    }
                ]
            }
        ]
    }
]
