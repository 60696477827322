import React, { useEffect, useRef, useCallback, useMemo } from "react";
import TreeView from "devextreme-react/tree-view";
import { navigation } from "../../app-navigation";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import { useAuth } from "../../contexts/auth";
import "./side-navigation-menu.scss";
import routes from "../../routes/app-routes";

import * as events from "devextreme/events";

export default function SideNavigationMenu(props) {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;

  const { user } = useAuth();

  const { isLarge } = useScreenSize();
  function simpleCopy(item) {
    return JSON.parse(JSON.stringify(item));
  }

  function normalizePath() {
    let newNavigation = filterNavigation(simpleCopy(navigation));

    // if (user.role !== 'Salon Admin') {
    //   newNavigation = newNavigation.filter(item => item.text !== "Admin Panel");
    // }

    return newNavigation.map((item) => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }
      return { ...item, expanded: isLarge };
    });
  }
  function filterNavigation(navigation, parentNode) {
    // use for loop and visible false instead
    let hiddenNodeCount = 0;
    for (let i = 0; i < navigation.length; i++) {
      const nav = navigation[i];
      if (nav.path) {
        //check if path is allowed for user
        let matchingRoute = routes.find((route) => route.path === nav.path),
          routeUserTypes = matchingRoute.allowedUserTypes,
          currUserType = user.userRoles;

        if (
          !routeUserTypes.some((role) => {
            return currUserType.includes(role);
          })
        ) {
          nav.visible = false;

          hiddenNodeCount++;
          // if number of child nodes matches number of hidden child ndoes
          if (parentNode && navigation.length === hiddenNodeCount) {
            // hide the parent node as well
            parentNode.visible = false;
          }
        }
      } else if (nav.items) {
        // recursion
        nav.items = filterNavigation(nav.items, nav);
      }
    }

    return navigation;
  }

  const items = useMemo(normalizePath, []);

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <div className={"menu-container"}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={"100%"}
        />
      </div>
    </div>
  );
}
