import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Scheduler, useScheduler } from "@aldabil/react-scheduler";
import ViewMode from "./ViewMode";
import CustomEditor from "./CustomEditor";
import {
  weekConfig,
  dayConfig,
  resourceFields,
  groupedSchedules,
  SchedulerAPI,
} from "./config";
import { renderCell } from "./CellItem";
import { getFields } from "./Fields";
import { renderDetailedItem } from "./DetailedItem";
import stringToColor from "string-to-color";
import { LoadIndicator } from "devextreme-react";
import { baseAxios, Context } from "../../utils/config";
import dayjs from "dayjs";
import "./style.scss";
import { useAuth } from "../../contexts/auth";

function CustomScheduler() {
  const { setEvents, setResources, selectedDate, view, events, resources } =
    useScheduler();
  const { dataset, setDataset, location, setLocation } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  let userCredentials = {
    Barcode: user.barcode,
    Role: user.role,
    Organization: user.org,
    Location: user.location_id,
  };

  const fetchRemote = useCallback(async () => {
    setLoading(true);
    const res = await baseAxios.post(SchedulerAPI.get, userCredentials);

    const result = {
      ...res.data,
      stylists: res.data?.stylists.map((stylist) => ({
        ...stylist,
        color: stringToColor(stylist.user_name),
      })),
      schedules: groupedSchedules(res.data?.schedules),
      locations: [
        { location_id: -1, location_name: "All Locations" },
        ...res.data?.locations,
      ],
    };

    setDataset(result);
    setLoading(false);
  }, []);

  useEffect(async () => {
    fetchRemote();

    return () => {
      localStorage.removeItem("location");
    };
  }, []);

  const adjustUI = () => {
    const items = document.querySelectorAll(".rs__event__item");

    items.forEach((item) => {
      const scheduleItem = item.querySelector(".schedule-item");
      if (scheduleItem) {
        const startTime = scheduleItem.getAttribute("data-start");
        const endTime = scheduleItem.getAttribute("data-end");

        // Calculate top value
        const startMoment = dayjs(startTime, "HH:mm");
        const dayStart = dayjs().startOf("day").hour(9).minute(0);
        const top =
          Math.floor(startMoment.diff(dayStart, "minute") / 15) * 30 + "px";

        if (item.style.top === top) {
          return;
        }

        // Calculate height value
        const duration = dayjs(endTime, "HH:mm").diff(startMoment, "minute");
        const height = Math.floor(duration / 15) * 30 + "px";

        item.style.top = top;
        item.style.height = height;
      }
    });
  };

  useEffect(() => {
    if (!loading && (view === "week" || view === "day")) {
      adjustUI();
    }
  });

  const refreshData = async () => {
    const res = await baseAxios.post(SchedulerAPI.get, userCredentials);

    const result = {
      ...res.data,
      stylists: res.data?.stylists.map((stylist) => ({
        ...stylist,
        color: stringToColor(stylist.user_name),
      })),
      schedules: groupedSchedules(res.data?.schedules),
      locations: [
        { location_id: -1, location_name: "All Locations" },
        ...res.data?.locations,
      ],
    };

    setDataset(result);
  };

  const handleDelete = async (deletedId) => {
    const res = await baseAxios.delete(SchedulerAPI.delete, {
      data: {
        scheduleEntryId: deletedId,
      },
    });
    if (res.status === 200) {
      refreshData();

      return deletedId;
    }
  };

  const onMove = async (time, data) => {
    const res = await baseAxios.put(SchedulerAPI.modify, {
      start: dayjs(time).format("YYYY-MM-DD HH:mm:ss"),
      user_id: data.user_id,
      location_id: data.location.location_id,
      client_id: data.client.client_id,
      services: data.uniqueServices,
      schedule_entry_id: data.appointmentId,
    });

    if (res.status === 200) {
      refreshData();

      return {};
    }
  };

  const handleLocation = (value) => {
    if (!value) {
      setLocation({ location_id: -1, location_name: "All Locations" });
      localStorage.setItem(
        "location",
        JSON.stringify({ location_id: -1, location_name: "All Locations" })
      );
      setResources(dataset.stylists);
    } else if (value.location_id === -1) {
      setLocation(value);
      localStorage.setItem("location", JSON.stringify(value));
      setResources(dataset.stylists);
    } else {
      setLocation(value);
      localStorage.setItem("location", JSON.stringify(value));
      setResources(
        dataset.stylists.filter(
          (stylist) => stylist.location_id === value.location_id
        )
      );
    }
  };

  useEffect(() => {
    if (dataset.schedules) {
      const filteredSchedule = dataset.schedules.filter((schedule) => {
        let scheduleInLocation = true;
        if (location && location.location_id !== -1) {
          scheduleInLocation =
            schedule.location.location_id === location.location_id;
        }

        return (
          dayjs(schedule.start).isSame(selectedDate, view) && scheduleInLocation
        );
      });

      setEvents(filteredSchedule);

      if (dataset.stylists) {
        const filteredStylists = dataset.stylists.filter((stylist) => {
          let stylistInLocation = true;
          if (location && location.location_id !== -1) {
            stylistInLocation = stylist.location_id === location.location_id;
          }

          const hasSchedule = filteredSchedule.find(
            (schedule) =>
              schedule.user_id === stylist.user_id &&
              dayjs(schedule.start).isSame(selectedDate, view) &&
              stylistInLocation
          );

          return hasSchedule;
        });

        setResources(filteredStylists);
      }
    }
  }, [selectedDate, view, dataset, location]);

  return (
    <div className={`scheduler-wrapper viewmode-${view}`}>
      <h2 className={"content-block"} style={{ marginRight: "20px" }}>
        Service Scheduler
      </h2>
      <div className={"content-block"}>
        <ViewMode
          useScheduler={useScheduler}
          locations={dataset.locations || []}
          handleChange={handleLocation}
          fetchRemote={fetchRemote}
        />
        <div className={`scheduler-box ${loading ? "loading" : ""}`}>
          {loading && (
            <div className="loading">
              <LoadIndicator />
            </div>
          )}
          {!loading && events.length > 0 && view === "day" && (
            <SchedulerTimeline startTime={9} endTime={20} timeStep={15} />
          )}
          {dataset.stylists && !loading && (
            <Scheduler
              timeZone="Africa/Abidjan"
              events={events}
              resources={resources}
              week={weekConfig}
              day={dayConfig}
              view="day"
              resourceFields={resourceFields}
              eventRenderer={renderCell}
              viewerExtraComponent={renderDetailedItem}
              fields={getFields({ stylists: resources })}
              customEditor={(scheduler) => (
                <CustomEditor
                  scheduler={scheduler}
                  dataset={dataset}
                  setDataset={setDataset}
                />
              )}
              onDelete={handleDelete}
              onEventDrop={onMove}
              renderDeps={[dataset]}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const SchedulerTimeline = ({
  startTime,
  endTime,
  timeStep: timeStepInMinutes,
}) => {
  const numberOfSlots = Math.floor(
    ((endTime - startTime) * 60) / timeStepInMinutes
  );

  const timeSlots = [];
  for (let i = 0; i <= numberOfSlots; i++) {
    let hour = Math.floor((i * timeStepInMinutes) / 60) + startTime;
    const minute = (i * timeStepInMinutes) % 60;
    let formattedTime = "";
    let period = "";

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    } else {
      period = "AM";
      if (hour === 0) {
        hour = 12;
      }
    }

    formattedTime =
      i % 4 === 0
        ? `${hour}:${minute.toString().padStart(2, "0")} ${period}`
        : `${minute.toString().padStart(2, "0")} min`;

    const className = i % 4 === 0 ? "item hour" : "item";

    timeSlots.push(
      <div key={i} className={className}>
        {formattedTime}
      </div>
    );
  }

  return <div className="scheduler-timeline">{timeSlots}</div>;
};

export default CustomScheduler;
