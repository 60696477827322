const recipes = [

{
    Manufacturer: 'Redken',
    Recipe_Parent_ID: -1,
    Recipe_ID: 1,
},

{
    Manufacturer: 'Schwarzkopf',
    Recipe_Parent_ID: -1,
    Recipe_ID: 2,
},

{
    Brand: 'Igora',
    Recipe_ID: 21,
    Recipe_Parent_ID: 2,  
},

{
    Category: 'Royal Absolutes',
    Recipe_ID: 212,
    Recipe_Parent_ID: 21,
},

{
    Category: 'Royal',
    Recipe_ID: 211,
    Recipe_Parent_ID: 21,
},

{
    Category: 'Absolutes',
    Recipe_ID: 213,
    Recipe_Parent_ID: 21,
},

{
    Brand: 'Vibrance',
    Recipe_ID: 22,
    Recipe_Parent_ID: 2,
},

{
    Brand: 'BLONDME',
    Recipe_ID: 23,
    Recipe_Parent_ID: 2,
},

{
    Brand: 'BLND TNR',
    Recipe_ID: 24,
    Recipe_Parent_ID: 2,
},

{
    Brand: 'BLOND POWDER',
    Recipe_ID: 25,
    Recipe_Parent_ID: 2,
},

{
    Category: 'BlondMe',
    Recipe_ID: 241,
    Recipe_Parent_ID: 24,
},

// {
//     Brand: 'Developers',
//     Recipe_ID: 25,
//     Recipe_Parent_ID: 2,
// },

{
    Brand: 'Redken Shades',
    Recipe_ID: 11,
    Recipe_Parent_ID: 1,
},

{
    Brand: 'Koleston Perfect Me+',
    Recipe_ID: 31,
    Recipe_Parent_ID: 3,
},


{
    Color_Code: 'T-APRICOT',
    Developers: '',
    Ratio: '',
    Recipe_ID: 4045787242676,
    Recipe_Parent_ID: 241,
},

]

export default{
    getRecipes(){
        return recipes;
    },
    
};
