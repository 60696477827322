import React, {
  forwardRef,
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
  useReducer,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
  Lookup,
  Item,
  Toolbar,
} from "devextreme-react/data-grid";
import { Button, TagBox } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

import "./manage-users.scss";
import { baseAxios } from "../../utils/config";
import { useAuth } from "../../contexts/auth";
import JsBarcode from "jsbarcode";
import { EmailRule, RequiredRule } from "devextreme-react/form";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { editAccountObject } from "../../api/auth";
import reducer from "./reducer.js";
import notify from "devextreme/ui/notify";

const ROLES = [
  { id: "jdtech admin", access: ["QSolutions"] },
  { id: "system admin", access: ["QSolutions"] },
  { id: "salon admin", access: ["QSolutions", "MiCA"] },
  { id: "front desk", access: ["MiCA"] },
  { id: "stylist", access: ["MiCA"] },
];

const dataSource = new DataSource({
  store: new CustomStore({
    key: "user_id",
    load: function () {
      return baseAxios.get("/users").then((result) => {
        return result?.data?.users.map((user) => ({
          ...user,
          roles: user.roles.map(({ role }) => role),
        }));
      });
    },
  }),
});

const locationDataSource = new DataSource({
  store: new CustomStore({
    key: "location_id",
    load: function () {
      return baseAxios.get("/organization/-/locations").then((result) => {
        return result?.data?.locations;
      });
    },
    byKey: function (key) {
      return baseAxios
        .get(`/organization/-/locations/${key}`)
        .then((result) => {
          return result?.data?.location;
        });
    },
  }),
});

const initialState = {
  data: [],
  changes: [],
  editRowKey: null,
  isLoading: false,
};

export default function ManageUsers() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChangesChange = useCallback((changes) => {
    dispatch({
      type: "SET_CHANGES",
      payload: changes,
    });
  }, []);
  const onEditRowKeyChange = useCallback((editRowKey) => {
    dispatch({
      type: "SET_EDIT_ROW_KEY",
      payload: editRowKey,
    });
  }, []);

  const history = useHistory();
  const dataRef = useRef(null);
  const { user } = useAuth();

  const onCreateUser = useCallback(async (e) => {
    history.push("/create-account");
  }, []);

  const showOrganizationColumn = user.role.toLowerCase() === "system admin";

  const showLocationColumn =
    user.role.toLowerCase() === "system admin" ||
    user.role.toLowerCase() === "salon admin";

  const [mode, setMode] = useState("normal");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stylistRole = searchParams.get("role");
  let roles;
  if (stylistRole) {
    roles = ["Stylist"];
  } else {
    roles = ["Stylist", "Salon Admin", "Front Desk"];
  }

  const closeButtonOptions = {
    icon: "close",
    onClick() {
      onChangesChange([]);
      setMode("normal");
    },
  };
  const saveButtonOptions = {
    icon: "save",
    disabled: state.changes?.length < 1,
    onClick() {
      if (state.changes?.length > 0) {
        let promises = [];
        state.changes.forEach((item) => {
          promises.push(editAccountObject(item.key, item.data));
        });

        Promise.all(promises).then((responses) => {
          if (responses.some((x) => !x.isOk)) {
            notify("Some users were not updated.", "Error", 3000);
          } else {
            notify("Successfully updated users.", "Success", 3000);
          }
          onChangesChange([]);
          dataSource.reload();
          setMode("normal");
        });
      } else {
        setMode("normal");
      }
    },
  };
  const undoButtonOptions = {
    icon: "undo",
    disabled: state.changes?.length < 1,
    onClick() {
      if (state.changes?.length === 0) {
        setMode("normal");
        return;
      }

      if (state.changes?.length === 1) {
        setMode("normal");
        return;
      }

      onChangesChange([...state.changes.slice(0, -1)]);
    },
  };

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Manage Users</h2>
      <Button text={"Add New User"} onClick={onCreateUser} />
      <DataGrid
        ref={dataRef}
        dataSource={dataSource}
        keyExpr="ID"
        className={"dx-card wide-card"}
        showBorders={true}
        showColumnLines={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
      >
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        {mode === "batch" && (
          <Editing
            mode="batch"
            changes={state.changes}
            onChangesChange={onChangesChange}
            editRowKey={state.editRowKey}
            onEditRowKeyChange={onEditRowKeyChange}
            allowUpdating={true}
            allowAdding={false}
            allowDeleting={false}
          ></Editing>
        )}
        {mode === "batch" && (
          <Toolbar>
            <Item
              options={closeButtonOptions}
              widget="dxButton"
              location={"after"}
            ></Item>
            <Item
              options={saveButtonOptions}
              widget="dxButton"
              location={"after"}
            ></Item>
            <Item
              options={undoButtonOptions}
              widget="dxButton"
              location={"after"}
            ></Item>
          </Toolbar>
        )}
        {mode === "normal" && (
          <Toolbar>
            <Item location="after">
              <Button
                text="Batch Edit"
                stylingMode="text"
                onClick={() => setMode("batch")}
              />
            </Item>
          </Toolbar>
        )}

        {showOrganizationColumn && (
          <Column
            allowEditing={false}
            dataField={"org_name"}
            caption={"Organization"}
            hidingPriority={5}
          />
        )}
        {showLocationColumn && (
          <Column
            dataField={"location_id"}
            caption={"Salon"}
            hidingPriority={4}
            editorOptions={{
              dataSource: locationDataSource,
              displayExpr: "location_name",
              valueExpr: "location_id",
            }}
          >
            <Lookup
              dataSource={locationDataSource}
              displayExpr="location_name"
              valueExpr="location_id"
            />
          </Column>
        )}
        <Column
          dataField={"user_first_name"}
          caption={"First Name"}
          hidingPriority={9}
        />
        <Column
          dataField={"user_last_name"}
          caption={"Last Name"}
          hidingPriority={10}
        />
        <Column
          dataField={"user_status"}
          caption={"Status"}
          hidingPriority={10}
        >
          <Lookup
            dataSource={[
              { id: "PENDING", name: "Pending" },
              { id: "ACTIVE", name: "Active" },
              { id: "INACTIVE", name: "Inactive" },
              { id: "DELETED", name: "Deleted" },
            ]}
            displayExpr="name"
            valueExpr="id"
          />
          <RequiredRule />
        </Column>

        <Column
          dataField={"user_barcode"}
          caption={"Barcode"}
          hidingPriority={5}
          allowEditing={false}
          cellRender={(data) => {
            return (
              <div>
                {data.value}
                {data.value && (
                  <Button
                    icon="download"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      const canvas = document.createElement("canvas");
                      JsBarcode(canvas, data.value);

                      const link = document.createElement("a");
                      link.download = "barcode.png";
                      link.href = canvas.toDataURL();
                      link.click();
                    }}
                  />
                )}
              </div>
            );
          }}
        />

        <Column
          dataField={"roles"}
          caption={"Access Level"}
          hidingPriority={6}
          customizeText={(data) =>
            data.value
              .reduce(
                (acc, role) => [
                  ...new Set([
                    ...acc,
                    ...(ROLES.find((r) => r.id === role)?.access ?? []),
                  ]),
                ],
                []
              )
              .sort()
              .join(", ")
          }
          editCellRender={(cell) => {
            const { data } = cell;
            const onValueChanged = (e) => cell.setValue(e.value);
            return (
              <TagBox
                dataSource={ROLES}
                defaultValue={data.roles}
                valueExpr="id"
                displayExpr="id"
                onValueChanged={onValueChanged}
              />
            );
          }}
        ></Column>
        <Column
          dataField={"user_phone"}
          caption={"Phone #"}
          hidingPriority={7}
          customizeText={(data) => {
            if (!data.value) return "";
            return data.value.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
          }}
          editorOptions={{
            stylingMode: "filled",
            mask: "+1 (000) 000-0000",
          }}
        />
        <Column dataField={"user_email"} caption={"Email"} allowEditing={false}>
          <EmailRule message="Email is invalid" />
        </Column>
        <Column
          type="buttons"
          cellRender={(data) => {
            return (
              <Button
                icon="edit"
                onClick={() => {
                  history.push(`/users/${data.data.user_id}`);
                }}
              />
            );
          }}
        ></Column>
      </DataGrid>
    </React.Fragment>
  );
}
