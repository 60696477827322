import React from 'react';
import "./stick.style.scss"

export default function Stick({ color = '#ed0f7e', data = {}, disabled = false, onClick }) {
    const formatFullDate = (date) => {
        const day = date.getDay();
        const month = date.getMonth();
        const dayOfMonth = date.getDate();

        const monthNames = [
            'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
            'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
        ];
        const formattedMonth = monthNames[month];

        const dayNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const formattedDay = dayNames[day];

        const formattedDate = `${formattedDay} - ${formattedMonth} ${dayOfMonth}, ${date.getFullYear()}`;

        return formattedDate;
    }

    const formatTime = (date) => {
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedHours = hours % 12 === 0 ? 12 : hours % 12 < 10 ? `0${hours % 12}` : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedAmPm = hours < 12 ? 'AM' : 'PM';

        const formattedTime = `${formattedHours}:${formattedMinutes} ${formattedAmPm}`;

        return formattedTime;
    }

    return (
        <div className='stick-container' onClick={!disabled && onClick}>
            <div className='stick-wrapper'>
                <div className="heading">
                    <div className='client'>Client: {data.client}</div>
                    <div className='date'>{formatFullDate(new Date(data.date))}</div>
                </div>
                {
                    data.cups && data.cups.length > 0 &&
                    <ul>
                        {data.cups.map((cup, i) => (
                            <li key={i} className='resources'>
                                <div className='time'>{formatTime(new Date(cup.time))}</div>
                                <hr />
                                <div className='cup'>
                                    <div className='cup-name'>{cup.name}</div>
                                    <div className='resource'>
                                        {cup.resources.map((item, index) => (
                                            <div key={index} className='resource-item'>{item.name} - {item.amount}g</div>
                                        ))}
                                    </div>
                                </div>
                            </li>
                        ))}
                        {data.cups.length > 0 && <div className='line' style={{ backgroundColor: color }}></div>}
                    </ul>
                }
            </div>
            <div className='summary'>
                SUMMARY: ${data.total_cost?.toFixed(2) || 0}
            </div>
        </div >
    )
}
