import React from 'react';
import './Pivotgrid-Class.scss';
import PivotGrid, {
  FieldChooser,
} from 'devextreme-react/pivot-grid';
import { sales } from '../../components/pivotgrid-chart/sample-data';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Size,
  Tooltip
} from "devextreme-react/chart";


class PivotGridChart extends React.Component {

  componentDidMount() {
    this.pivotGrid.bindChart(this.chart, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    });
  }

  render(){
    return(
      <React.Fragment>

      <h2 className={'content-block'}>Pivotgrid Class</h2>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <Chart ref={(ref) => {this.chart = ref.instance;}}>
              <Size height={320} />
              <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
              <CommonSeriesSettings type="bar" />
              <AdaptiveLayout width={450} />
            </Chart>

            <PivotGrid
            id="pivotgrid"
            dataSource={dataSource}
            allowSortingBySummary={true}
            allowFiltering={true}
            showBorders={true}
            showColumnTotals={false}
            showColumnGrandTotals={false}
            showRowTotals={false}
            showRowGrandTotals={false}
            ref={(ref) => {this.pivotGrid = ref.instance;}}
            >
              <FieldChooser enabled={true} height={400} />
            </PivotGrid>
          </div>
        </div>
      </React.Fragment>
    );
  }

}

const dataSource = new PivotGridDataSource({
  fields: [
    {
      caption: "Salon",
      width: 120,
      dataField: "salon",
      area: "row",
      sortBySummaryField: "Total"
    },
    {
      caption: "Stylist",
      dataField: "stylist",
      width: 150,
      area: "row"
    },
    {
      dataField: "date",
      dataType: "date",
      area: "column"
    },
    {
      groupName: "date",
      groupInterval: "month",
      visible: false
    },
    {
      caption: "Total",
      dataField: "amount",
      dataType: "number",
      summaryType: "sum",
      format: "currency",
      area: "data"
    },

    {
      caption: "Transaction",
      dataField: "transaction",
      dataType: "number",
      area: "row"
    },
  ],
  store: sales
});
function customizeTooltip(args) {
  const valueText =
    args.seriesName.indexOf("Total") !== -1
      ? new Intl.NumberFormat("en-EN", {
          style: "currency",
          currency: "USD"
        }).format(args.originalValue)
      : args.originalValue;

  return {
    html: `${args.seriesName}<div class='currency'>${valueText}</div>`
  };
}


export default PivotGridChart;
